class Settings {
  constructor(data) {
    this.pk = data.pk;
    this.name = data.key || null;
    this.label = data.key
      .replaceAll('_', ' ')
      .split(' ')
      .map(i => i.capitalize())
      .join(' ');
    this.options = data.possible_values || [];
    this.type = data.value_type.toLowerCase() || null;
    this.value = data.default_value;
    this.entityName = data.entity_name;
    this.fieldGroupVisibility = data.field_group_visibility; //to control conditional fields visibility
    this.code = data.code;

    this.displayOptions = [];
    this.parentLabel = data?.extra_data?.parentName
      ?.replaceAll('_', ' ')
      ?.split(' ')
      ?.map(i => i.capitalize())
      ?.join(' ');
    this.extraData = data?.extra_data || null;
    const displayNames = data.extra_data?.display_names || {};
    Object.keys(displayNames).forEach(key => {
      this.displayOptions.push({ label: displayNames[key], value: key });
    });
  }
}

export default Settings;
