export const STRINGS = {
  ABSENCE_FAILURE_MSG:
    'You have not selected any available buses in your bus absence. Please go back and select a bus to remove your student off from',
  ABSENCE_SUCCESS_MSG: 'You still need to complete a School Absence for this student',
  ABSENCE_COMPLETE_MSG: 'A bus on one of these days has finished. Please check your dates.',
  ABSENCE_DEFAULT_MSG: 'Your child is already scanned on/off to a bus on one of these days. Please check your dates.',
  PLEASE_LET_US_KNOW_TEXT:
    'Please first let us know the reason for the bus absence and then the relevant date(s). If you are letting us know about your child arriving late or leaving early, please put the expected arrival / departure time in to the notes',
  YOUR_CHILD_IS_TEXT:
    'Your child is scheduled to go on the following buses during this bus absence. We will remove them from the buses below for the dates of this bus absence. If you wish to keep them on the bus please uncheck the checkboxes.',
  ADD_BUS_ABSNECE: 'Add Bus Absence',
  CSV_UPLOADING_IN_PROGRESS: 'CSV uploading in progress...',
  PLUGIN_DETAILS: 'plugin-details',
  CHOOSE_CSV_FILE_TO_UPLOAD: 'Choose CSV file to Upload',
};

export const REDIRECT_ROUTES = {
  BUSINESS_BOARD: '/business-board/',
  GET_STARTED: '/get-started',
  DRIVERS: '/drivers',
  ADMIN_TRANSPORT: '/admin/transport',
  DASHBOARD: '/dashboard',
  MY_BOARD: '/my-boards',
  GLOBAL_DASHBOARD: '/global-dashboard',
  BUSINESS_ADMINS: '/business-admins',
};

export const ERROR_MESSAGES = {
  STDUENT_ABSENCE_NOT_SAVED: 'Student absence not saved',
  EMAILS_ARE_CURRENTLY_TURNED_OFF:
    'Emails are currently turned off for your school, please contact support to turn them on.',
  PLUGIN_NOT_ACTIVATED: 'Plugin is not activated yet',
  UNABLE_TO_OPEN_PLUGIN: 'Unable to open plugin',
};

export const SUCEESS_MESSAGES = {
  STUDENT_ABSENCE_ADDED_SUCCESSFULLY: 'Student absence added successfully',
};

export const SOCKET_EVENTS = {
  ON_DELETE_PEI_FOR_ABSENCE: 'onDeletePEIForAbsence',
};

export const VALIDATION_MESSAGES = {
  PLEASE_ENTER_FROM_DATE: 'Please select From date',
  PLEASE_ENTER_TO_DATE: 'Please select To date',
};
