/**
 * Contains the API endpoint URLs used in the application.
 *.
 */
const ROUTE_URLS = {
  MESSAGE_LOGS: '/messaging-logs/',
  ORG_SETTINGS: '/settings/',
  SEND_CUSTOM_EMAIL: '/send-custom-email/',
  NOTIFICATIONS: '/notifications/',
  ROUTE_EVENTS: '/route-events/',
};

export default ROUTE_URLS;
