import { env } from 'granite-admin/env';
import * as Yup from 'yup';
import { STRINGS } from 'common/TopNavbar/strings';

/**
 * Constructs a login URL based on the selected organisation and the current domain environment.
 *
 * @param {Object} selectedOrganisation - The organisation object containing a domain property.
 * @param {string} selectedOrganisation.domain - The domain of the selected organisation.
 * @returns {string} - The constructed login URL.
 */
export const getLoginURL = selectedOrganisation => {
  const currentDomain = window.location;

  const curServer = currentDomain?.origin.includes('test')
    ? '.testing'
    : currentDomain?.origin.includes('staging')
    ? '.staging'
    : '';

  return `${currentDomain.protocol}//${selectedOrganisation?.domain}${curServer}.rollcall.com.au/admin#/force-login`;
};

export const termsLink = env.REACT_APP_TERMS_URL || process.env.REACT_APP_TERMS_URL;
export const privacyLink = env.REACT_APP_PRIVACY_URL || process.env.REACT_APP_PRIVACY_URL;
export const marketPlaceLink = 'https://www.rollcallmarketplace.com.au';
export const handlePrivacyLinkClick = () => {
  window.open(privacyLink);
};
export const handleTermsLinkClick = () => {
  window.open(termsLink);
};
/**
 * Checks if an element is within the viewport.
 *
 * @param {Element} el - The DOM element to check.
 * @param {number} offsetHeight - The height offset from the bottom of the viewport.
 * @returns {boolean} - True if the element is in the viewport, false otherwise.
 */
export const isInViewport = (el, offsetHeight) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight - offsetHeight || document.documentElement.clientHeight - offsetHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const validationSchema = Yup.object().shape({
  message: Yup.string().required(STRINGS.MESSAGE_REQUIRED_TEXT),
});

/**
 * Calculates the byte size of a given message based on GSM 7-bit and extended character sets.
 *
 * @param {string} message - The message whose byte size needs to be calculated.
 * @returns {number} - The total byte size of the message.
 */
export const calculateMessageByteSize = message => {
  const gsm7bitChars =
    '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
  const gsm7bitExtendedChars = '^{}\\[~]|€';
  let byteSize = 0;

  for (let char of message) {
    if (gsm7bitChars.includes(char)) {
      byteSize += 1;
    } else if (gsm7bitExtendedChars.includes(char)) {
      byteSize += 2;
    } else {
      const blobSize = new Blob([char]).size;
      byteSize += blobSize;
    }
  }
  return byteSize;
};

/**
 * Determines the item count based on dimension width.
 *
 * @param {Object} dimension - The container dimensions.
 * @param {number} dimension.width - The container width.
 * @returns {number} - The number of items to display.
 */
export const getItemCount = dimension => {
  if (dimension.width < 900) return 4;
  else if (dimension.width < 1000) return 5;
  else if (dimension.width < 1200) return 6;
  else if (dimension.width < 1400) return 7;
  else if (dimension.width < 1600) return 8;
  else return 9;
};
export const getMenuItemsList = navigate => [
  { label: 'Profile', onClick: () => navigate('/profile') },
  { label: 'Logout', onClick: () => navigate('/logout') },
];
