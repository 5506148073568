import React, { useState, useMemo, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, matchPath } from 'react-router-dom';
import { Box, Image, Heading, Menu, Text, Button } from 'grommet';
import { FormClose } from 'grommet-icons';
import PropTypes from 'prop-types';

import * as SidebarDucks from 'granite-admin/accounts/ducks/sidebar';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import Loader from 'granite-admin/core/components/Loader';
import useWindowDimensions from 'granite-admin/utils/useWindowDimensions';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';

import logo from 'assets/logo.png';
import UserDropMenu from 'common/TopNavbar/components/UserDropMenu';
import Notification from 'common/TopNavbar/components/Notification';
import { getOrganisationAvatar } from 'common/TopNavbar/components/UserDropMenu';
import LeftItems from 'common/TopNavbar/components/LeftItems';
import RightItems from 'common/TopNavbar/components/RightItems';
import MoreLeftItems from 'common/TopNavbar/components/MoreLeftItems';
import { StyledLayer, StyledIframe, StyledMenuButton, HeaderBox } from 'common/TopNavbar/styledComponents/styles';
import useCase from 'common/TopNavbar/hooks/useCase';
import { MARKETPLACE_MESSAGING_DATA } from 'common/TopNavbar/helpers/messageRoutes';
import { getItemCount, getMenuItemsList } from 'common/TopNavbar/helpers/support';
import { STRINGS } from 'common/TopNavbar/strings';
import { formCloseColor } from 'common/TopNavbar/styledComponents/common';

const TopNavbar = ({
  sidebarItems,
  navigate,
  pathname,
  title,
  userProfile,
  dispatch,
  theme,
  showUserDropMenu,
  selectedOrganisation,
  getItemCount,
}) => {
  const config = useContext(ConfigContext);
  const { TopNavCustomLeftComp, TopNavCustomRightComp } = config?.sidebarSettings;
  const [openSupport, setOpenSupport] = useState(false);
  const [routeMessage, setRouteMessage] = useState(false);
  const [loader, setLoader] = useState(true);

  const windowDimensions = useWindowDimensions();

  const userPreferenceData = useSelector(({ accounts }) => accounts?.user?.profile);
  const { signOut, navItems, eventEmitter } = useCase({
    navigate,
    dispatch,
    sidebarItems,
    userPreferenceData,
    selectedOrganisation,
  });

  const menuItems = useMemo(() => {
    const leftObj = navItems.find(i => i.group === 'left');
    return {
      visibleItems: [...leftObj.children].splice(0, getItemCount(windowDimensions)),
      moreItems: [...leftObj.children].splice(getItemCount(windowDimensions)),
      rightItems: { children: selectedOrganisation?.pk ? MARKETPLACE_MESSAGING_DATA : [] },
    };
  }, [navItems, getItemCount, windowDimensions]);

  return (
    <>
      <HeaderBox
        background={theme.global.colors['sidebar-background']}
        fill="horizontal"
        direction="row"
        gap="small"
        wrap
      >
        {sidebarItems.length === 0 ? (
          <Box align="center" justify="center" fill>
            <Loader size="30px" />
          </Box>
        ) : (
          <>
            <Box direction="row" flex={{ shrink: 0 }} margin={{ right: 'auto' }} gap="small">
              <Box justify="center" flex={{ shrink: 0 }}>
                {selectedOrganisation?.logo?.thumbnails ? (
                  <Image src={selectedOrganisation.logo.thumbnails} alt="Company Logo" />
                ) : typeof title === 'string' ? (
                  <Heading margin="none" level={3}>
                    {title}
                  </Heading>
                ) : (
                  title
                )}
              </Box>
              <Box direction="row" alignSelf="center" gap="xsmall" align="center">
                {menuItems.visibleItems.map(child => renderLeftItem(child, theme, pathname))}
                {menuItems.moreItems.length > 0 && <MoreLeftItems subItems={menuItems.moreItems} theme={theme} />}
                {TopNavCustomLeftComp && selectedOrganisation?.pk && <TopNavCustomLeftComp />}
              </Box>
            </Box>
            <Box direction="row" flex={{ shrink: 0 }} margin={{ left: 'auto' }}>
              <Box direction="row" alignSelf="center" gap="xsmall">
                {TopNavCustomRightComp && <TopNavCustomRightComp />}
                {menuItems.rightItems.children.map(child => (
                  <RightItems
                    key={child.pk}
                    item={child}
                    theme={theme}
                    setOpenSupport={setOpenSupport}
                    setRouteMessage={setRouteMessage}
                    routeMessage={routeMessage}
                  />
                ))}
              </Box>

              <Box direction="row" flex={{ shrink: 0 }} justify="center" align="center" gap="small">
                <Notification navigate={navigate} />
                {showUserDropMenu ? (
                  <UserDropMenu
                    userProfile={userProfile}
                    selectedOrganisation={selectedOrganisation}
                    navigate={navigate}
                    signOut={signOut}
                    dispatch={dispatch}
                    eventEmitter={eventEmitter}
                  />
                ) : (
                  <Menu
                    plain
                    items={() => {
                      return getMenuItemsList(navigate);
                    }}
                  >
                    <Box direction="row" align="center" gap="small" pad={{ horizontal: 'small' }}>
                      <Text>{userProfile.firstName}</Text>
                      {getOrganisationAvatar(userProfile)}
                    </Box>
                  </Menu>
                )}
              </Box>
            </Box>
          </>
        )}
        {openSupport && (
          <StyledLayer
            position="center"
            onClickOutside={() => {
              setOpenSupport(false);
              setLoader(true);
            }}
          >
            <Box direction="row" align="start" width="medium">
              <Button
                alignSelf="end"
                icon={<FormClose size="medium" color={formCloseColor} />}
                onClick={() => setOpenSupport(false)}
                plain
              />
            </Box>
            {loader ? (
              <Box align="center" justify="center">
                <Loader />
              </Box>
            ) : null}
            <StyledIframe
              title="Help & Support"
              src={STRINGS.FEEDBACK_WIDGET_URL}
              width="850"
              height="500"
              style={{ background: 'transparent', border: 'none' }}
              onLoad={() => setLoader(false)}
            />
          </StyledLayer>
        )}
      </HeaderBox>
    </>
  );
};

const renderLeftItem = (item, theme, pathname) => {
  const itemId = item.pk || item.getlabel();
  const isActive = Boolean(matchPath({ path: '/' + item.path }, pathname));
  const content = item?.children?.length ? (
    <LeftItems
      label={item.label}
      theme={theme}
      subItems={item.children}
      isActive={isActive}
      activeColor={theme.global.colors['sidebar-svg-active']}
      pathname={item.path}
    />
  ) : (
    <StyledMenuButton
      label={item.label}
      id={itemId}
      isActive={isActive}
      activeColor={theme.global.colors['sidebar-svg-active']}
      pathname={item.path}
      menuItemHPad="small"
      item={item}
    />
  );
  return (
    <Box key={itemId} flex={{ shrink: 0 }}>
      {item?.children?.length ? (
        <>{content}</>
      ) : (
        <Link to={'/' + item.path} state={{ url: item.url }}>
          {content}
        </Link>
      )}
    </Box>
  );
};

TopNavbar.propTypes = {
  navigate: PropTypes.object,
  pathname: PropTypes.string,
  title: PropTypes.any,
  userProfile: PropTypes.object,
  sidebarItems: PropTypes.array,
  dispatch: PropTypes.func,
  theme: PropTypes.object,
  showUserDropMenu: PropTypes.bool,
  selectedOrganisation: PropTypes.object,
  getItemCount: PropTypes.func,
};

TopNavbar.defaultProps = {
  title: <Image src={logo} alt="Logo" height="30px" />,
  getItemCount: getItemCount,
};

const mapStateToProps = state => ({
  sidebarItems: SidebarDucks.sidebarItems(state),
  userProfile: UserDucks.profile(state),
  userPermissions: UserDucks.permissions(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
