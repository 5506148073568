import * as Yup from 'yup';
import { setAuthToken, setSsoLogin, setOrganisation, setTenantAccess } from 'granite-admin/utils/auth-singleton';
import { checkUserAgent } from 'granite-admin/utils/functions';

const appleStore = process.env.REACT_APP_APPLE_STORE_URL || '';
const sso_android = process.env.REACT_APP_SSO_ANDROID_URL || '';
const android_package = process.env.REACT_APP_ANDROID_PACKAGE || '';
const sso_ios = process.env.REACT_APP_SSO_IOS_URL || '';

// Function to deep link the app based on the user's device type.
// It checks the user agent (IOS, ANDROID, or other), handles authentication,
// and redirects the user accordingly. For iOS, if the app isn't opened within 5 seconds,
// it redirects to the App Store.
//
// Params:
// token (string): The authentication token used for the user session.
// orgId (string): The organization ID associated with the user.
// goToParent (function): A callback function that handles navigating to the parent page.

export const deepLinkApp = (token, orgId, goToParent) => {
  const agent = checkUserAgent();

  if (agent === 'IOS') {
    window.location.href = `${sso_ios}${token}`;

    setTimeout(function () {
      if (!document.hidden) window.location.href = appleStore;
    }, 5000);
  } else if (agent === 'ANDROID') {
    window.location.href = `${sso_android}${token}${android_package}`;
  } else {
    setAuthToken(token);
    setSsoLogin();
    setTenantAccess(true);
    setOrganisation(orgId);
    goToParent();
  }
};

// Function to get the email query parameter for an input field.
// It checks the email's validity using Yup and returns a query string if valid.
//
// Params:
// inputRef (object): A reference object pointing to the input field.

export const getEmailQuery = async inputRef => {
  let value = inputRef?.current?.value;
  if (!value) return '';
  let emailValidity = Yup.string().email();
  let isEmailValid = await emailValidity.isValid(value);
  if (isEmailValid) return `email=${value}`;
  return '';
};

export const getSsoRedirectUrl = (konectSsoUrl, currentDomain, subdomain) => {
  return `${konectSsoUrl}?current_url=${currentDomain.origin}/parent/login/&school=${subdomain}`;
};
