import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import { deleteCookie } from 'granite-admin/utils/storage';
import useQuery from 'granite-admin/utils/useQuery';

import LinearLayout from 'common/LinearLayout';
import ParentLayout from './common/ParentLayout';
import { mainDomain } from 'utils/miscellaneous';

const PublicOnlyRoute = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = getAuthToken();
  const { query } = useQuery();

  if (
    authToken &&
    !(
      query.backToBoard === 'yes' ||
      query.handlePta === 'yes' ||
      query.redirectDomain === 'yes' ||
      query.tokenLogin === 'yes'
    )
  ) {
    deleteCookie('organisationData', mainDomain);
    deleteCookie('BAorigin', mainDomain);
    deleteCookie('redirectDomain', mainDomain);
    // deleteAllCookies(mainDomain);
    // if parentLayout, then load parent dashboard
    if (rest.layout === 'ParentLayout') {
      return <Navigate to={{ pathname: '/parent' }} replace />;
    }
    // ONLY non-logged in users are allowed to access this route.
    return <Navigate to={{ pathname: '/dashboard' }} replace />;
  }
  // authorised so return component
  return rest.layout == 'ParentLayout' ? (
    <ParentLayout>
      <Component {...rest} navigate={navigate} location={location} />
    </ParentLayout>
  ) : (
    <LinearLayout>
      <Component {...rest} navigate={navigate} location={location} />
    </LinearLayout>
  );
};

export default PublicOnlyRoute;
