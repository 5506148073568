import moment from 'moment-timezone';
import { format } from 'date-fns';

/**
 * Generates an array of all dates within a given year.
 *
 * @param {string[]} formatDataYear - An array of year strings (e.g., ['2024', '2023']).
 * @returns {string[]} - An array of date strings in 'yyyy-MM-dd' format for each day of the given years.
 */
export const heatMapDate = formatDataYear => {
  let allDates = [];
  formatDataYear.map(e => {
    let from = new Date(e + '-' + '01' + '-' + '01');
    let to = new Date(e + '-' + '12' + '-31');
    for (let j = from; j <= to; j.setDate(j.getDate() + 1)) {
      allDates.push(j.toISOString().slice(0, 10));
    }
  });
  return allDates;
};

/**
 * Gets all weekdays or selected days within a given month, excluding or including specific days.
 *
 * @param {boolean} [daySelect=false] - Whether to select specific days (true) or exclude specific days (false).
 * @param {string[]} [selectedDate=[]] - An array of selected date strings in 'yyyy-MM-dd' format.
 * @param {number[]} [days=[]] - An array of day numbers to include or exclude (0 for Sunday, 1 for Monday, etc.).
 * @param {boolean} [excluded=true] - Whether to exclude the specified days (true) or include them (false).
 * @returns {string[]} - An array of date strings in 'yyyy-MM-dd 00:00:00' format for the selected days.
 */
export const getWeekDays = (daySelect = false, selectedDate = [], days = [], excluded = true) => {
  let daysArr = [];
  selectedDate.forEach(date => {
    let d = date ? new Date(`${date} 00:00:00`) : new Date();
    let month = d.getMonth();
    if (!daySelect) {
      while (d.getMonth() === month) {
        let condition = excluded
          ? days.length
            ? d.getDay() !== days[0] && d.getDay() !== days[1]
            : d.getDay() !== 1
          : days.length
          ? d.getDay() === days[0] || d.getDay() === days[1]
          : d.getDay() === 1;
        if (condition) {
          daysArr.push(`${format(new Date(d), 'yyyy-MM-dd')} 00:00:00`);
        }
        d.setDate(d.getDate() + 1);
      }
    } else {
      while (d.getMonth() === month) {
        let condition = excluded
          ? days.length
            ? d.getDate() !== days[0] && d.getDate() !== days[1]
            : d.getDate() !== 1
          : days.length
          ? d.getDate() === days[0] || d.getDate() === days[1]
          : d.getDate() === 1;
        if (condition) {
          daysArr.push(`${format(new Date(d), 'yyyy-MM-dd')} 00:00:00`);
        }
        d.setDate(d.getDate() + 1);
      }
    }
  });
  return daysArr;
};

/**
 * Gets the date of the Monday of the week for a given date.
 *
 * @param {string} d - The input date string in 'yyyy-MM-dd' format (optional).
 * @returns {string} - The date string of the Monday in 'yyyy-MM-dd' format.
 */
export const getMonday = d => {
  const today = d ? new Date(`${d} 00:00:00`) : new Date();
  const first = today.getDate() - today.getDay() + 1;
  const monday = format(new Date(today.setDate(first)), 'yyyy-MM-dd');
  return monday;
};

/**
 * Generates an array of dates within a specified range with either daily or monthly steps.
 *
 * @param {string} startDate - The start date string in 'yyyy-MM-dd' format.
 * @param {string} endDate - The end date string in 'yyyy-MM-dd' format.
 * @param {number} steps - The number of steps to take between each date. Use 1 for daily steps.
 * @returns {string[]} - An array of date strings in 'yyyy-MM-dd' format for each date in the range.
 */
export const dateRange = (startDate, endDate, steps) => {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];

  if (steps === 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate < new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().slice(0, 10));
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  } else {
    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  }
};

/**
 * Formats a time string to the specified time zone.
 *
 * @param {string} time - The input time string.
 * @param {string} [timeZone] - The time zone to format the time to (default is 'Australia/Melbourne').
 * @returns {string} - The formatted time string in 'HH:mm' format.
 */
export const getFormatedTime = (time, timeZone) => {
  let formatedTime = new Date(time).toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone || 'Australia/Melbourne',
  });
  return formatedTime;
};

/**
 * Formats a date string to 'yyyy-MM-dd' format in the specified time zone.
 *
 * @param {string} time - The input date string.
 * @param {string} [timeZone] - The time zone to format the date to (default is 'Australia/Melbourne').
 * @returns {string} - The formatted date string in 'yyyy-MM-dd' format.
 */
export const getFormatedDate = (time, timeZone) => {
  let formatedDate = new Date(time)
    .toLocaleDateString('en-GB', { timeZone: timeZone || 'Australia/Melbourne' })
    .split('/')
    .reverse()
    .join('-');
  return formatedDate;
};

/**
 * Converts a time string to minutes.
 *
 * @param {string} time - The input time string in 'HH:mm' format.
 * @returns {number} - The time converted to total minutes.
 */
const timeToMinutes = time => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

/**
 * Checks if a given time falls between a start time and an end time.
 *
 * @param {string} startTime - The start time string in 'HH:mm' format.
 * @param {string} endTime - The end time string in 'HH:mm' format.
 * @param {string} checkTime - The time string to check in 'HH:mm' format.
 * @returns {boolean} - `true` if the check time is between the start and end time, `false` otherwise.
 */
const checkTimeBetween = (startTime, endTime, checkTime) => {
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);
  const checkMinutes = timeToMinutes(checkTime);

  return startMinutes <= checkMinutes && checkMinutes <= endMinutes;
};

/**
 * Checks if the current time falls within two specified time ranges.
 *
 * @param {string} currentTime - The current time string in 'HH:mm' format.
 * @returns {boolean} - `true` if the current time is within either of the specified time ranges, `false` otherwise.
 */
export const isTimeBetween = currentTime => {
  const startTime1 = '07:00';
  const endTime1 = '09:00';
  const startTime2 = '15:00';
  const endTime2 = '17:00';
  return checkTimeBetween(startTime1, endTime1, currentTime) || checkTimeBetween(startTime2, endTime2, currentTime);
};

/**
 * Converts a date string from 'dd-MM-yyyy' format to 'MM-dd-yyyy' format.
 *
 * @param {string} [date=''] - The input date string in 'dd-MM-yyyy' or 'dd/MM/yyyy' format.
 * @returns {string} - The date string in 'MM-dd-yyyy' or 'MM/dd/yyyy' format.
 */
export const converToMMDDYYYY = (date = '') => {
  try {
    let separator = date?.includes('-') ? '-' : '/';
    let dateArray = date.split(separator);
    const newDate = dateArray[1] + separator + dateArray[0] + separator + dateArray[2];
    return newDate || date;
  } catch (e) {
    console.log('err ', e);
  }
};

/**
 * Converts a date string to UTC format based on the specified time zone.
 *
 * @param {string} [dateString=''] - The input date string.
 * @param {string} [timeZone='Australia/Melbourne'] - The time zone of the input date string.
 * @returns {string} - The UTC date string in ISO format.
 */
export const convertDateToUTC = (dateString = '', timeZone = 'Australia/Melbourne') => {
  const inputDate = moment.tz(dateString, timeZone);
  const utcDate = inputDate.utc();
  return utcDate?.format();
};

export const viewDateFormat = 'dd/MM/yyyy';
export const customDateFormat = 'yyyy-MM-dd';
export const dateStyles = {
  hideAllFieldTooltip: true,
};

export const isSameYear = (date1, date2) => {
  return date1 && date2 && new Date(date1).getFullYear() === new Date(date2).getFullYear();
};

export const fetchDateTime = date => {
  try {
    const newDate = new Date(date).getTime();
    return isNaN(newDate) ? '' : newDate;
  } catch (e) {
    return '';
  }
};
