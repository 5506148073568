import React from 'react';
import { Box, Text, Avatar, DropButton } from 'grommet';
import PropTypes from 'prop-types';

import CompanyDrop from 'common/TopNavbar/components/CompanyDrop';
import ImageView from 'granite-admin/core/components/ImageView';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import { deleteCookie, setCookie } from 'granite-admin/utils/storage';
import { avatarBackGroundColor, avatarTextColor, companyDropStyle } from 'common/TopNavbar/styledComponents/common';

const getOrganisationAvatar = userProfile => {
  if (Boolean(userProfile) === false) return;
  const { profileImage } = userProfile;
  return profileImage ? (
    <ImageView src={profileImage} size="30px" rounded />
  ) : (
    <Avatar background={avatarBackGroundColor} size="30px" round="full">
      <Text color={avatarTextColor} size="small">
        {userProfile.firstName.charAt(0).toUpperCase()}
      </Text>
    </Avatar>
  );
};
export const backToBusiness = () => {
  const authToken = getAuthToken();
  const host = window.location.host;
  const domain = host.substring(
    host.indexOf('.') + 1,
    host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  );
  deleteCookie('organisationData');
  deleteCookie('BAorigin');
  deleteCookie('redirectDomain');
  let URL = `${window.location.protocol}//${domain}${
    window.location.port ? `:${window.location.port}` : ''
  }/login?access_token=${authToken}&backToBoard=yes`;
  setCookie('nodirectURL', true, 1, domain);
  setCookie('organisationData', undefined, 1, domain);
  setCookie('BAorigin', window.origin, 1, domain);
  setCookie('redirectDomain', 'yes', 1, domain);
  window.location = URL;
};

const UserDropMenu = ({ userProfile, signOut, navigate, eventEmitter }) => {
  return (
    <DropButton
      dropAlign={{ top: 'bottom', right: 'right' }}
      dropContent={
        <CompanyDrop
          eventEmitter={eventEmitter}
          navigate={navigate}
          signOut={signOut}
          showMyBoardBtn
          backToBusiness={backToBusiness}
        />
      }
      dropProps={{
        style: companyDropStyle,
      }}
    >
      <Box direction="row" align="center" gap="small" pad={{ right: 'small' }}>
        <Text>{userProfile.firstName}</Text>
      </Box>
    </DropButton>
  );
};

UserDropMenu.propTypes = {
  userProfile: PropTypes.object,
  signOut: PropTypes.func,
  navigate: PropTypes.object,
  dispatch: PropTypes.func,
};

export default UserDropMenu;
export { getOrganisationAvatar };
