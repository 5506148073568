import { useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import GraniteError from 'granite-admin/utils/granite-error';
import { getAuthToken, getOrganisation } from 'granite-admin/utils/auth-singleton';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import { removeAuthToken } from 'granite-admin/accounts/controllers/sidebar';
// import useUtility from 'granite-admin/utils/useUtility';
import queryString from 'query-string';

import { env } from 'granite-admin/env';
// import { ROOM_NAMES } from './utils';
import { getBearerToken } from 'granite-admin/utils/auth-singleton';
import { isTokenValid } from 'granite-admin/utils/functions';

var websocketInstance = {};
// const SOCKET_SCREEN_ENDPOINTS = [
//   '/route-mapping',
//   '/operational-dashboard',
//   '/global-dashboard',
//   '/parent/mapbus',
//   '/admin/transport',
// ];

function useWebSocket(props) {
  const { onConnectionOpen, onConnectionClose, onGetMessage, onGetError } = props || {};

  const config = useContext(ConfigContext);
  const { navigate } = useNavigate();
  // const [connectionId, setConnectionId] = useState(null);
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const { websocketMappings = null } = config || {};
  // const organisation = getOrganisation();

  const loadWebsocketInstance = useCallback((url, headers = {}, protocols = [], forceCloseConnection = false) => {
    const authToken =
      isTokenValid() && window.location.pathname !== '/routes'
        ? getAuthToken(true)
        : getBearerToken()
        ? `Bearer ${getBearerToken()}`
        : null;
    // if (!websocketMappings || !Object.keys(websocketMappings).length) {
    //   return;
    // } else
    if (websocketInstance.readyState === WebSocket.OPEN) {
      if (forceCloseConnection) {
        websocketInstance.close();
      } else return;
    }

    try {
      let params = {};
      const webSocketUrl = url || env.REACT_APP_WEBSOCKET_API_URL || process.env.REACT_APP_WEBSOCKET_API_URL || '';
      if (authToken) {
        params = { ...params, auth_token: `${authToken}` };
      } else {
        removeAuthToken();
        grandEmitter.emit('AUTHENTICATION_ERROR');
        return;
      }
      if (Object.keys(headers).length > 0) {
        params = { ...params, ...headers };
      }
      if (webSocketUrl) {
        websocketInstance = new WebSocket(`${webSocketUrl}?${queryString.stringify(params)}`, protocols);
        if (websocketInstance) {
          websocketInstance.onopen = function (event) {
            sendData({ action: 'ping', data: {} });
            if (typeof onConnectionOpen === 'function') {
              onConnectionOpen(event);
            } else {
              console.log('Websocket connection is established', event);
            }
          };

          websocketInstance.onmessage = function (event) {
            const eventData = JSON.parse(event.data);
            if (eventData?.event === 'pong') {
              sessionStorage.setItem('WSConnectionId', eventData.connection_id);
              grandEmitter.emit('PONG_RECEIVED');
              // sendData({
              //   action: 'join',
              //   data: {
              //     room_id: ROOM_NAMES.WEB_ADMIN,
              //     organisation_id: organisation,
              //     // connection_id:connection_id
              //   },
              // });
              // sendData({
              //   action: 'join',
              //   data: {
              //     room_id: ROOM_NAMES.WEB_PARENT,
              //     organisation_id: organisation,
              //     // connection_id:connection_id
              //   },
              // });
            }
            if (websocketMappings && websocketMappings[eventData.action]?.showToast) {
              let redirectUrl = '';
              if (
                websocketMappings[eventData.action]?.autoRedirect &&
                websocketMappings[eventData.action]?.redirectUrl
              ) {
                redirectUrl = getPath(eventData.data, websocketMappings[eventData.action].redirectUrl);
              }
              grandEmitter.emit('SHOW_TOAST', {
                message: websocketMappings[eventData.action]?.toastMsg,
                status: 'info',
                ...(websocketMappings[eventData.action]?.autoRedirect &&
                  websocketMappings[eventData.action]?.redirectUrl && { redirect: () => navigate(redirectUrl) }),
              });
            }
            if (websocketMappings && websocketMappings[eventData.action]?.volatileStorage) {
              localStorage.setItem(eventData.action, eventData.data);
            }
            if (typeof onGetMessage === 'function') {
              onGetMessage(eventData);
            } else {
              console.log('Getting data over Websocket connection: ', event);
            }
          };

          websocketInstance.onclose = function (event) {
            if (event.code !== 1000) loadWebsocketInstance(); //as we need always on connection(except closed forcefully), load connection if gets close due to timeout or something.
            if (typeof onConnectionClose === 'function') {
              onConnectionClose(event);
            } else {
              console.log('Websocket connection is closed', event);
            }
          };
        }
        // setConnectionId(websocketInstance);
      }
    } catch (e) {
      throw new GraniteError(e);
    }
  }, []);

  const sendData = useCallback(data => {
    try {
      //data should be of format {"action": "test", "data": {}}
      if (websocketInstance.readyState === WebSocket.OPEN) {
        //if the connection is open
        websocketInstance.send(JSON.stringify(data));
      } else {
        grandEmitter.emit('WEBSOCKET_CONNECTION_FAIL');
        console.log('websocket connection is not established');
      }
    } catch (e) {
      grandEmitter.emit('WEBSOCKET_CONNECTION_FAIL');
      throw new GraniteError(e);
    }
  }, []);

  const closeConnection = useCallback(forceClose => {
    try {
      if (websocketInstance.readyState === WebSocket.OPEN && !websocketInstance.bufferedAmount && !forceClose) {
        //if the connection is open and no data is queued for sending
        websocketInstance.close();
      } else if (forceClose) {
        //to close the connection forcefully, pass code and reason for closing. Although both parameters are optional.
        websocketInstance.close(1000, 'The connection successfully completed the purpose.');
      }
    } catch (e) {
      throw new GraniteError(e);
    }
  }, []);

  function getPath(data, route) {
    const arr = route.split('/');
    const vars = arr.filter(a => a.includes(':')).map(a => a.replace(':', ''));
    vars.forEach(v => (route = route.replace(':' + v, data[v])));
    return route; //in case generatePath not works
  }

  websocketInstance.onerror = function (event) {
    if (typeof onGetError === 'function') {
      console.log('Getting error over Websocket connection: ', event);
      onGetError(event);
    } else {
      console.log('Getting error over Websocket connection: ', event);
    }
  };

  return { websocketInstance, loadWebsocketInstance, sendData, closeConnection };
}

export default useWebSocket;
