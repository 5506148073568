//Core imports
import organisationAPI from 'granite-admin/organisations/gateways/organisation-api';

// Application imports
import { ONBOARDING_EVENTS } from 'onBoarding/controllers/events';
import { handleApiCall } from 'utils/handleApiCallController';

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implementation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 */

export async function createOrganizationOnListing(params) {
  const { eventEmitter, values } = params;
  return await handleApiCall(
    async () => {
      const data = await organisationAPI.createOrganizationOnListing(values);
      eventEmitter.emit(ONBOARDING_EVENTS.ONBOARDING_SUCCESS, data);
    },
    eventEmitter,
    ONBOARDING_EVENTS.ONBOARDING_FAILURE,
  );
}

export async function createOrganisation(params) {
  const { eventEmitter, values } = params;
  return await handleApiCall(
    async () => {
      const data = await organisationAPI.createOrganisation(values);
      eventEmitter.emit(ONBOARDING_EVENTS.ONBOARDING_SUCCESS, data);
    },
    eventEmitter,
    ONBOARDING_EVENTS.ONBOARDING_FAILURE,
  );
}
