import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text, DropButton, Button } from 'grommet';
import { FormDown } from 'grommet-icons';
import {
  StyledBoxMoreLeft,
  StyledDropButtonMoreLeft,
  StyledSpanMoreLeft,
} from 'common/TopNavbar/styledComponents/styles';
import { sanitizationDangerousHTML } from 'utils/sanitization';
import { STRINGS } from 'common/TopNavbar/strings';

const MoreLeftItems = ({ subItems, theme }) => {
  return (
    <StyledDropButtonMoreLeft
      plain
      alignSelf="center"
      theme={theme}
      margin={{ vertical: 'small' }}
      dropContent={renderItems(subItems)}
      dropProps={{ align: { top: 'bottom', left: 'left' } }}
    >
      <Button
        plain
        as="div"
        style={{ display: 'flex' }}
        reverse
        icon={<FormDown size="small" />}
        label={<Text>{STRINGS.MORE_TEXT} </Text>}
      />
    </StyledDropButtonMoreLeft>
  );
};

const itemBox = (i, index) => {
  return (
    <Link key={index} to={'/' + i.path} state={{ url: i.url }}>
      <StyledBoxMoreLeft focusIndicator={false} direction="row" gap="small" align="end">
        {i.icon && typeof i.icon === 'string' ? (
          <StyledSpanMoreLeft
            style={{ display: 'flex' }}
            dangerouslySetInnerHTML={{
              __html: sanitizationDangerousHTML(i.icon),
            }}
          />
        ) : (
          i.icon
        )}
        <Text>{i.label}</Text>
      </StyledBoxMoreLeft>
    </Link>
  );
};

const renderSubItems = data => <>{data.map((i, index) => itemBox(i, index))}</>;

const renderItems = subItems => (
  <>
    {subItems.map((i, index) =>
      i.children ? (
        <DropButton
          focusIndicator={false}
          key={index}
          dropContent={renderSubItems(i.children)}
          dropProps={{ align: { top: 'bottom', left: 'right' } }}
        >
          <StyledBoxMoreLeft direction="row" align="end" gap="small" focusIndicator={false}>
            {i.icon && typeof i.icon === 'string' ? (
              <StyledSpanMoreLeft
                style={{ display: 'flex' }}
                dangerouslySetInnerHTML={{
                  __html: sanitizationDangerousHTML(i.icon),
                }}
              />
            ) : (
              i.icon
            )}
            <Text>{i.label}</Text>
            <FormDown size="small" style={{ marginLeft: 'auto' }} />
          </StyledBoxMoreLeft>
        </DropButton>
      ) : (
        itemBox(i, index)
      ),
    )}
  </>
);

MoreLeftItems.propTypes = {
  subItems: PropTypes.any,
  theme: PropTypes.any,
};

export default MoreLeftItems;
