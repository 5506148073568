import React, { Suspense } from 'react';
import { Box } from 'grommet';
import Loader from 'granite-admin/core/components/Loader';
import ErrorBoundary from 'granite-admin/core/components/DashboardLayout/components/ErrorBoundary';


const ParentLayout = ({ childProps, children, permission, heading, component: Component }) => {
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Box fill align="center" justify="center">
            <Loader />
          </Box>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default ParentLayout;
