// Lib Imports
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Stack, Text, ThemeContext } from 'grommet';
import { Bell } from '@styled-icons/fa-solid/Bell';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Core Imports
import useWebSocket from 'common/useWebSocket';
import useNotificationsCount from 'granite-admin/utils/useNotificationsCount';

import { fetchNotifications } from 'common/TopNavbar/controllers/routeMessage';
import NotificationStack from 'common/TopNavbar/components/NotificationStack';
import useUtility from 'granite-admin/utils/useUtility';
import { StyledMenuNotification, AnimationBox } from 'common/TopNavbar/styledComponents/styles';
import { TOPNAVBAR_EVENTS } from 'common/TopNavbar/controllers/events';
import { STRING_EVENTS } from 'common/TopNavbar/strings';
import { StyledBox, dropPropsStyles } from 'common/TopNavbar/styledComponents/notification';
import EventEmitter from 'granite-admin/utils/event-emitter';

const eventEmitter = new EventEmitter();
const Notification = ({ navigate, hoverIndicatorOptions }) => {
  const { handleFetchNotificationCount, handleCountOnNotificationClick, handleMarkAllReadClick, notificationsCount } =
    useNotificationsCount();
  const { websocketInstance: nativeWebsocketInstance } = useWebSocket();
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState();
  const [animatedIcon, setAnimatedIcon] = useState(false);
  const [isRead, setIsRead] = useState(null);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case TOPNAVBAR_EVENTS.FETCH_NOTIFICATIONS_SUCCESS:
          if (page === 1) setNotifications(event.data.list);
          else setNotifications(notifications => _.uniqBy([...notifications, ...event.data.list], 'pk'));
          setPage(event.data.nextPage);
          setLoader(false);
          break;
        case TOPNAVBAR_EVENTS.FETCH_NOTIFICATIONS_FAILURE:
          setLoader(false);
          break;

        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
    function messageListener(eventData) {
      if (Object.keys(eventData).length) {
        const data = JSON.parse(eventData.data);
        if (data.event === STRING_EVENTS.NOTIFICATION) {
          handleFetchNotificationCount(notificationsCount + 1);
          setAnimatedIcon(true);
        }
      }
    }
    if (nativeWebsocketInstance) {
      nativeWebsocketInstance.addEventListener('message', messageListener);
    }
    return () => nativeWebsocketInstance.removeEventListener('message', messageListener);
  }, [handleFetchNotificationCount, nativeWebsocketInstance, notificationsCount]);
  const grandListner = useCallback(
    event => {
      switch (event.type) {
        case TOPNAVBAR_EVENTS.FETCH_NOTIFICATION_COUNT:
          handleFetchNotificationCount();
          break;
        case TOPNAVBAR_EVENTS.ANIMATE_BELL_ICON:
          setAnimatedIcon(true);
          break;
        default:
          break;
      }
    },
    [handleFetchNotificationCount],
  );
  useUtility({ grandListner });
  const handleFetchNotifications = isReadParam => {
    setLoader(true);
    let mypage;
    if (isReadParam === STRING_EVENTS.FETCH_UNREAD_ONSCROLL) {
      mypage = page;
      isReadParam = false;
    } else if (isReadParam === STRING_EVENTS.FETCH_UNREAD_ONCE) {
      setPage(1);
      mypage = 1;
      setNotifications([]);
      isReadParam = false;
    } else if (isReadParam === STRING_EVENTS.FETCH_ALL_NOTIFICATION) {
      mypage = 1;
      isReadParam = null;
    } else {
      mypage = page;
      isReadParam = null;
    }
    if (mypage == null) mypage = 1;

    fetchNotifications(eventEmitter, { page: mypage, is_read: isReadParam });
  };
  const handleNotificationClick = useCallback(
    notification => {
      if (!notification.status) {
        handleCountOnNotificationClick(notification.pk);
        setNotifications(prev => prev.map(i => ({ ...i, status: i.pk === notification.pk ? true : i.status })));
      }
      if (notification.url !== '') navigate(notification.url);
    },
    [handleCountOnNotificationClick, setNotifications, navigate],
  );

  const markAllRead = useCallback(() => {
    handleMarkAllReadClick();
    setNotifications(prev => prev.map(i => ({ ...i, status: true })));
    setOpen(false);
  }, [handleMarkAllReadClick, setNotifications, setOpen]);

  const showUnread = useCallback(
    showUnread => {
      if (showUnread !== STRING_EVENTS.FETCH_ALL) {
        setIsRead(STRING_EVENTS.FETCH_UNREAD_ONCE);
        handleFetchNotifications(STRING_EVENTS.FETCH_UNREAD_ONCE);
      } else {
        setIsRead(null);
        handleFetchNotifications(STRING_EVENTS.FETCH_ALL_NOTIFICATION);
      }
      setOpen(false);
    },
    [handleFetchNotifications, setIsRead, setOpen],
  );

  return (
    <>
      <StyledMenuNotification
        dropProps={{
          style: dropPropsStyles,
        }}
        dropAlign={{ top: 'bottom', right: 'right' }}
        onClose={() => {
          setPage(1);
          setNotifications([]);
          setIsRead(null);
          setOpen(false);
        }}
        onOpen={handleFetchNotifications}
        dropContent={
          <NotificationStack
            notifications={notifications}
            loader={loader}
            handleNotificationClick={handleNotificationClick}
            setPage={setPage}
            page={page}
            handleFetchNotifications={handleFetchNotifications}
            hoverIndicatorOptions={hoverIndicatorOptions}
            markAllRead={markAllRead}
            showUnread={showUnread}
            isRead={isRead}
            setOpen={setOpen}
            open={open}
          />
        }
      >
        <AnimationBox animatedIcon={animatedIcon} onAnimationEnd={() => setAnimatedIcon(false)}>
          <Stack anchor="top-right" className="stack">
            <Bell size="20px" />
            {notificationsCount && (
              <StyledBox className="bell-count" flex="grow">
                <Text size="9px" color={theme.global.colors['sidebar-label-hover']} weight="bold">
                  {notificationsCount > 9 ? '9+' : notificationsCount}
                </Text>
              </StyledBox>
            )}
          </Stack>
        </AnimationBox>
      </StyledMenuNotification>
    </>
  );
};

Notification.propTypes = {
  navigate: PropTypes.object,
  hoverIndicatorOptions: PropTypes.object,
};

Notification.defaultProps = {
  hoverIndicatorOptions: { color: 'hover' },
};

export default Notification;
