// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';

/* eslint-disable */
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;

async function getRoutesStopsList(params) {
  try {
    params = { params: { ...params } };
    const { data } = await GraniteAPIClient(url).get('/route-stop-directions/', params);
    return data;
  } catch (err) {
    throw new GraniteError(err);
  }
}

export default { getRoutesStopsList };
