class DashletsEntity {
  constructor(data) {
    this.organisation_id = data?.organisation?.pk ?? false;
    this.school = data?.organisation?.name ?? false;
    this.schoolDomain = data?.organisation?.domain ?? false;
    this.schools = data?.dashlet_settings?.school ?? false;
    this.wrong_stop_exception = data?.dashlet_settings?.wrong_stop_exception ?? false;
    this.off_stop_exception = data?.dashlet_settings?.off_stop_exception ?? false;
    this.in_progress_route = data?.dashlet_settings?.in_progress_route ?? false;
    this.completed_route = data?.dashlet_settings?.completed_route ?? false;
    this.available_seats = data?.dashlet_settings?.available_seats ?? false;
    this.behaviour = data?.dashlet_settings?.behaviour ?? false;
    this.not_allocated_pickup = data?.dashlet_settings?.not_allocated_pickup ?? false;
    this.not_travelled_student = data?.dashlet_settings?.not_travelled_student ?? false;
    this.capacity = data?.dashlet_settings?.capacity ?? false;
    this.loadings = data?.dashlet_settings?.loadings ?? false;
    this.casual_travellers = data?.dashlet_settings?.casual_travellers ?? false;
    this.logged_on_route = data?.dashlet_settings?.logged_on_route ?? false;
  }
}

export default DashletsEntity;