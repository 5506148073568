import * as Yup from 'yup';

export const getValidationSchema = organisation =>
  Yup.object().shape(
    {
      ...(organisation?.length && {
        organisation_id: Yup.number().required('Organization is required'),
      }),
      phone: Yup.object().when('email', {
        is: email => !email || email.length === 0,
        then: Yup.object().test(value => {
          if (value?.error) {
            return new Yup.ValidationError('Invalid phone number', undefined, 'phone');
          } else {
            return true;
          }
        }),
      }),
      email: Yup.string().when('phone', {
        is: phone => !phone?.number || phone?.number.length === 0,
        then: Yup.string()
          .required('Email is required')
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .email('Invalid email'),
      }),
    },
    [['email', 'phone']],
  );

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .max(50, 'Password is too Long')
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, 'Atleast 6 char, One capital, One number is required')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
    .required('Password is required'),
});

export const submitValidationSchema = Yup.object().shape({
  token: Yup.string().min(6, 'Too Short!').max(6, 'Too Long!').required('Token is required'),
});

const primaryContact = process.env.REACT_APP_PRIMARY_CONTACT || 'EMAIL';
const allowAltRecovery = process.env.REACT_APP_ALLOW_ALTERNATE_RECOVERY || 'false';
export const headings = ['Forgot Password?', '', 'Login or Password reset', 'Set New Password'];
const emailOrPhone =
  primaryContact === 'EMAIL' && allowAltRecovery === 'false'
    ? 'Email'
    : (primaryContact === 'EMAIL' || primaryContact === 'PHONE') && allowAltRecovery === 'true'
    ? 'Email or Phone'
    : primaryContact === 'PHONE' && allowAltRecovery === 'false'
    ? 'Phone'
    : '';
export const subHeadings = [`Enter your registered ${emailOrPhone} to reset the password`];
export const captions = ['We will send you reset password link.'];
