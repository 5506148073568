export const TOPNAVBAR_EVENTS = {
  FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
  FETCH_LOGS_FAILURE: 'FETCH_LOGS_FAILURE',
  SEND_CUSTOM_EMAIL_SUCCESS: 'SEND_CUSTOM_EMAIL_SUCCESS',
  SEND_CUSTOM_EMAIL_FAILURE: 'SEND_CUSTOM_EMAIL_FAILURE',
  SEND_MESSAGE_ALL_ROUTES_SUCCESS: 'SEND_MESSAGE_ALL_ROUTES_SUCCESS',
  SEND_MESSAGE_ALL_ROUTES_FAILURE: 'SEND_MESSAGE_ALL_ROUTES_FAILURE',
  SEND_ROUTES_VERIFY_ROUTES: 'Please check you have selected the right routes for your message.',
  FETCH_ROUTE: 'FETCH_ROUTE',
  FETCH_ROUTE_FAILURE: 'FETCH_ROUTE_FAILURE',
  FETCH_NOTIFICATION_COUNT: 'FETCH_NOTIFICATION_COUNT',
  ANIMATE_BELL_ICON: 'ANIMATE_BELL_ICON',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'FETCH_NOTIFICATIONS_FAILURE',
};
