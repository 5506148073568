export const URLS = {
  ROUTE_GROUP: '/route-group/',
  USER_PHONE_VALIDATION: '/user-phone-validation/',
  LIST_STUDENT: '/list/student/',
  BUSINESS_ADMIN_ORGANISATION: '/business/admin/access/organisations/',
};

export const ROUTE_MAPPING_URLS = {
  LIST_ROUTE_STOP_MAPPING: '/list/route-stop-mapping/',
  ROUTE_STOP_MAPPING: '/route-stop-mapping/',
};

export const STOPS_URLS = {
  LIST_STOPS: '/list/stop/',
  STOP: '/stop/',
};

export const CAMPUS_URLS = {
  CAMPUS: '/campus/',
};

export const ROUTE_URLS = {
  ROUTE: '/route/',
  LIST_ROUTE: '/list/route/',
};

export const PLUGIN_URLS = {
  PLUGIN: '/plugin/',
  ACTIVATE: '/activate/',
};

export const TRANSPORT_URLS = {
  TRANSPORT: '/transport/',
};
