import React, { useEffect, useState, useCallback } from 'react';
import { Box, Text } from 'grommet';

import { useToast } from 'granite-admin/core/components/Toast';
import Loader from 'granite-admin/core/components/Loader';
import EmptyBoxWithoutText from 'assets/EmptyBoxWithoutText.png';

import driverDirectionsAPI from 'driverDirections/gateways/driverDirections-api';

const subDomain = window.location.host.split('.')[0];
const mainSubDomain = process.env.REACT_APP_MAIN_URL.split('.')[0];
const isSchoolDomain = subDomain !== mainSubDomain;

const textStyling = { display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' };

const DriverDirections = () => {
  const { errorToast } = useToast();
  const [routes, setRoutes] = useState(null);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const res = await driverDirectionsAPI.getRoutesStopsList({ school: subDomain });
        setRoutes(res?.routes || {});
      } catch (e) {
        errorToast(e?.errors?.title || 'Unable to load routes');
      }
    };
    if (!isSchoolDomain) errorToast('Please try with a valid school');
    else fetchRoutes();
  }, [errorToast]);

  const handleRedirection = useCallback(
    itm => {
      let url = `https://www.google.com/maps/dir/`;
      for (let idx = 0; idx < routes[itm]?.stops?.length; idx++) {
        const [lat, lng] = routes[itm]?.stops[idx]?.stop_lat_long?.coordinates || [];
        url = `${url}${lat},${lng}/`;
      }
      window.open(url);
    },
    [routes],
  );

  return (
    <Box fill overflow={{ vertical: 'auto' }}>
      {routes && Object.keys(routes).length ? (
        Object.keys(routes).map((itm, i) => (
          <Box
            key={itm + i}
            height="110px"
            fill="horizontal"
            align="center"
            justify="center"
            background={routes[itm]?.route_color}
            border="white"
            role="link"
            pad="small"
            flex={false}
            onClick={() => handleRedirection(itm)}
          >
            <Text
              size="large"
              textAlign="center"
              wordBreak="break-word"
              style={textStyling}
            >
              {itm}
            </Text>
          </Box>
        ))
      ) : routes ? (
        <Box
          fill
          justify="center"
          align="center"
          background={{
            image: `url(${EmptyBoxWithoutText})`,
            opacity: 'strong',
            size: 'contain',
          }}
        >
          <Text size="xlarge">No routes exist for today</Text>
        </Box>
      ) : (
        <Box fill justify="center" align="center">
          <Loader />
        </Box>
      )}
    </Box>
  );
};

export default DriverDirections;
