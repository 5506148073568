import { env } from 'granite-admin/env';

export const projectName = env.REACT_APP_PROJECT_NAME || process.env.REACT_APP_PROJECT_NAME || 'Granite';
export const projectAccountMsg = `Sign in to your ${projectName} Account`;
export const groupOptions = [
  {
    label: 'Business Admin',
    value: 'BA',
  },
  {
    label: "Staff's Member",
    value: 'TENANT',
  },
];

export const groupOptions2 = [
  {
    label: 'RollCall Admin',
    value: 'RA',
  },
  {
    label: "Staff's Member",
    value: 'SM',
  },
];
