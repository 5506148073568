// Lib Imports
import React, { useEffect, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Heading, ThemeContext } from 'grommet';

// CORE Imports
import EventEmitter from 'granite-admin/utils/event-emitter';
import { useToast } from 'granite-admin/core/components/Toast';
import SplitLayout from 'granite-admin/core/components/SplitLayout';

// Application Imports

import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import { otpSubmit } from 'accounts/controllers/user';
import OtpVerificationForm from './components/OtpVerificationForm';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';

import LoginAsLayer from 'pages/adminLogin/components/LoginLayer';

import { env } from 'granite-admin/env';
import { LOGIN_EVENTS } from 'accounts/controllers/events';
import { redirectToDashboard } from './helpers/otpSubmitHelpers';
import { STRINGS } from './strings';

const type = env.REACT_APP_REGISTER_TYPE || 'email';

const eventEmitter = new EventEmitter();

const OtpSubmit = ({ location, sideContent, customLogo, brandColor, formPadding, bannerPadding }) => {
  const { successToast, errorToast } = useToast();
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  const { SideContent } = config?.sideContentSettings || '';
  const theme = useContext(ThemeContext);
  const { sideProps, sideWidth } = theme?.sideContentSettings || '';
  const [loginAsLayer, setLoginAsLayer] = useState(false);

  const goToDashboard = useCallback(() => redirectToDashboard(navigate), [navigate]);

  useEffect(
    function init() {
      const observable = eventEmitter.getObservable();
      listenObservable();

      function listenObservable() {
        observable.subscribe(event => {
          switch (event.type) {
            // case 'OTP_SUCCESS':
            case LOGIN_EVENTS.LOGIN_SUCCESS:
              successToast(STRINGS.VERIFICATION_SUCCESSFUL);
              goToDashboard();
              break;
            case LOGIN_EVENTS.OTP_FAILURE:
              errorToast(event.data?.title || STRINGS.INVALID_OTP);
              break;
            case LOGIN_EVENTS.ONLY_PARENT_LOGIN:
              errorToast(STRINGS.NON_ADMIN_CREDENTIALS);
              break;
            case LOGIN_EVENTS.ONLY_BA_ACCESSING_WRONG_ORG:
              errorToast(STRINGS.WRONG_ORG_USER);
              break;
            case LOGIN_EVENTS.BA_CAN_BE_EMPLOYEE: {
              const auth = getAuthToken();
              setLoginAsLayer({ authToken: auth, URL: event.data?.url, mappedOrgId: event.data?.orgId });
              break;
            }
          }
        });
      }
    },
    [eventEmitter, navigate, successToast, errorToast],
  );

  const closeLayer = () => {
    setLoginAsLayer(false);
    removeAuthToken(); //in case user doesn't select any option from the loginAsLayer, delete jwtToken
  };

  return (
    <>
      <SplitLayout
        sideContent={SideContent ? <SideContent /> : sideContent}
        mainContent={
          <Box width="large" flex="grow">
            <Heading level={3}>{STRINGS.TWO_FACTOR_LOGIN_TEXT}</Heading>
            <Heading level={5} size="large" color="dark-2">
              {' '}
              {type === 'email' ? 'email address' : 'phone number'}.
            </Heading>
            <OtpVerificationForm
              otpSubmit={otpSubmit}
              eventEmitter={eventEmitter}
              type={type}
              location={location}
              brandColor={brandColor}
            />
          </Box>
        }
        customLogo={customLogo}
        sideWidth={sideWidth}
        formPadding={formPadding}
        sideProps={sideProps}
        bannerPadding={bannerPadding}
      />
      {loginAsLayer && (
        <LoginAsLayer closeLayer={closeLayer} goToDashboard={goToDashboard} loginAsLayer={loginAsLayer} />
      )}
    </>
  );
};

OtpSubmit.propTypes = {
  location: PropTypes.object,
  sideContent: PropTypes.object,
  sideWidth: PropTypes.number,
  brandColor: PropTypes.string,
  customLogo: PropTypes.string,
  formPadding: PropTypes.any,
  bannerPadding: PropTypes.string,
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpSubmit);
