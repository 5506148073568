// Lib Imports
import React from 'react';
import { Box, Button, Layer, Text, Heading } from 'grommet';
import PropType from 'prop-types';
import { withTheme } from 'styled-components';

import Loader from 'granite-admin/core/components/Loader';

const ConfirmPopupLayer = ({
  heading,
  isVisible,
  labelCancel,
  labelOK,
  onOK,
  onCancel,
  position,
  text,
  theme,
  togglePopup,
  width,
  minWidth,
  okBtnColor,
  showCancelBtn,
  showLoader,
  isDisabled,
  ...rest
}) => {
  const cancelBtnColor = theme.global.colors['status-critical'];

  if (isVisible) {
    return (
      <Layer
        modal
        onClickOutside={() => togglePopup(false)}
        onEsc={() => togglePopup(false)}
        position={position}
        {...rest}
      >
        <Box pad="medium" gap="small" width={width} style={{ minWidth: minWidth }}>
          {heading && (
            <Heading level={3} margin="none">
              {heading}
            </Heading>
          )}
          {typeof text === 'string' ? (
            <Text size="14px" textAlign="center">
              {text}
            </Text>
          ) : (
            text
          )}
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="center"
            pad={{ top: 'medium', bottom: 'small' }}
          >
            {showCancelBtn && (
              <Button
                label={
                  <Text color="#f05050">
                    <strong>{labelCancel}</strong>
                  </Text>
                }
                pad={{ top: '4px', left: '1rem' }}
                onClick={onCancel ? onCancel : (() => togglePopup(false))}
                primary
                color='cancel'
              />
            )}
            <Button primary label={labelOK} onClick={onOK} color={okBtnColor} disabled={isDisabled} />
          </Box>
        </Box>
        {isDisabled && showLoader &&
          <Box
            fill
            align="center"
            justify="center"
            style={{
              position: 'absolute',
              left: '0px',
              top: '0px',
            }}
          >
            <Loader size="30px" />
          </Box>
        }
      </Layer>
    );
  }

  return null;
};

ConfirmPopupLayer.defaultProps = {
  heading: '',
  isVisible: false,
  labelCancel: 'NO',
  labelOK: 'YES',
  onOK: () => { }, //onOK function
  position: 'center',
  text: 'Are you sure ?',
  togglePopup: () => { }, //it will be a setState function which will change the value of isVisible
  minWidth: 'medium',
  okBtnColor: 'accent-1',
  showCancelBtn: true,
};

ConfirmPopupLayer.propTypes = {
  heading: PropType.string,
  isVisible: PropType.bool,
  labelCancel: PropType.string,
  labelOK: PropType.string,
  onOK: PropType.func,
  onCancel: PropType.func,
  position: PropType.string,
  text: PropType.string,
  togglePopup: PropType.func,
  width: PropType.string,
  minWidth: PropType.string,
  theme: PropType.any,
  okBtnColor: PropType.any,
  showCancelBtn: PropType.bool,
  showLoader: PropType.any,
  isDisabled: PropType.any,
};

export default withTheme(ConfirmPopupLayer);
