import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const driverAccessibleScreens = ['/drivers', '/logout', '/profile', '/profile/basic-info', '/profile/settings'];

const useRouteBasedNavigate = (userProfile, location, localOrganisation) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userProfile?.permissionsName?.includes('driver_menu_read') &&
      !userProfile.isSystemAdmin &&
      !driverAccessibleScreens.includes(location.pathname) &&
      (location.pathname === '/parents' || !location.pathname.includes('/parent'))
    ) {
      navigate('/drivers');
    } else if (
      (location.pathname === '/dashlets' || location.pathname.includes('/admin/rollover')) &&
      !localOrganisation
    ) {
      navigate('/business-board');
    } else if (location.pathname === '/dashlets' && localOrganisation) {
      navigate('/admin/transport');
    }
  }, [location.pathname, navigate, userProfile.permissionsName, userProfile.isSystemAdmin, localOrganisation]);
};

export default useRouteBasedNavigate;
