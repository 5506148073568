import { getOrganisation } from 'granite-admin/utils/auth-singleton.js';
const orgId = getOrganisation();

const LANDSCAPE_TABLET_WIDTH = 1024;

const adminRoom = `web_admin`;
const parentRoom = `web_parent`;

export const ROOM_NAMES = { WEB_ADMIN: adminRoom, WEB_PARENT: parentRoom };

export const addressDummyArr = [1, 2, 3, 4, 5, 6];

// School Admin is equivalent to 'AdminDriverParent', 'AdminParent','AdminDriver'
export const ROLES_LIST = ['AdminDriverParent', 'AdminParent', 'AdminDriver', 'School Admin'];

export const mainDomain = process.env.REACT_APP_MAIN_URL.replace('https://', '')?.replace('http://', '');

export const socketHeaders = {
  organisation: orgId,
};
/**
 * Deletes all cookies for a specified domain, except those listed to be preserved.
 *
 * @param {string} domain - The domain for which cookies should be deleted.
 * @param {Array<string>} [preserveCookieArr=[]] - List of cookie names to retain.
 */
export function deleteAllCookies(domain, preserveCookieArr = []) {
  // console.log('deleting..', mainDomain, domain);
  var allCookies = document.cookie.split(';');
  for (let i = 0; i < allCookies.length; i++) {
    let cookie = allCookies[i].trim();
    let cookieName = cookie.split('=')[0];
    if (!preserveCookieArr.includes(cookieName)) {
      document.cookie = cookie + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;${domain ? `domain=${domain}` : ''};path=/`;
    }
  }
}

/**
 * Adds one day to the given date and returns the new date in the specified format.
 *
 * @param {string} [date=''] - The input date string.
 * @param {boolean} [ddMMyyyy=true] - Whether to return the date in 'yyyy-MM-dd' format or as a Date object.
 * @returns {string|Date} - The new date either as a formatted string ('yyyy-MM-dd') or as a Date object.
 */
export const addOneDay = (date = '', ddMMyyyy = true) => {
  const newDate = date ? new Date(date) : new Date();
  newDate.setDate(newDate.getDate() + 2);
  return ddMMyyyy ? newDate.toISOString().slice(0, 10) : newDate;
};
export const onConnectionOpen = event => {
  // sessionStorage.setItem("WSConnectionId",event.connection_id);
};

export const onConnectionClose = event => {
  sessionStorage.removeItem('WSConnectionId');
};

export const getConnectionId = () => {
  return sessionStorage.getItem('WSConnectionId');
};

const checkUserAgent = () => {
  const IS_IPAD = navigator.userAgent.match(/iPad/i) !== null;
  const IS_IPHONE =
    !IS_IPAD && (navigator.userAgent.match(/iPhone/i) !== null || navigator.userAgent.match(/iPod/i) !== null);
  if (IS_IPAD || IS_IPHONE) return 'IOS';
  if (navigator.userAgent.match(/android/i) !== null) return 'ANDROID';
  return 'WEB';
};
// calculate distance between students[A,B] & stops[C,D] array - {A to C A to D B to C B to D}

// distance & time response

/**
 * Checks if the current device is a mobile device based on the user agent and screen width.
 *
 * @returns {boolean} - `true` if the device is identified as a mobile device, `false` otherwise.
 */
export const checkMobileDevice = () => {
  const agent = checkUserAgent();
  if ((agent === 'IOS' || agent === 'ANDROID') && window.innerWidth < LANDSCAPE_TABLET_WIDTH) {
    return true;
  }
  return false;
};

export const defaultZonePinColor = '#808080';
