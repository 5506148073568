// Lib Imports
import React, { useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, Text } from 'grommet';

import EventEmitter from 'granite-admin/utils/event-emitter';
import Steps from 'granite-admin/core/components/Steps';
import { useToast } from 'granite-admin/core/components/Toast';
import SplitLayout from 'granite-admin/core/components/SplitLayout';
import useQuery from 'granite-admin/utils/useQuery';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';

// Application Imports
import RequestTokenForm from './components/RequestTokenForm';
import CheckUserStatus from './components/CheckUserStatus';
import ResetPasswordForm from './components/ResetPasswordForm';
import RequestSuccess from './components/RequestSuccess';
import { resetPasswordClicked, requestTokenClicked, checkUserStatus } from 'accounts/controllers/user';
import { RESET_EVENTS } from 'accounts/controllers/events';
import customLogo from 'assets/logo.png';
import { deepLinkApp } from './helpers/forgetPasswordHelper';
import { captions, headings, subHeadings } from './helpers/constants';
import { STRINGS } from './strings';

const ForgotPassword = ({ bannerPadding, brandColor, sideWidth, formPadding }) => {
  const navigate = useNavigate();
  const { query } = useQuery();
  const config = useContext(ConfigContext);
  const { successToast, errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const defaultActiveStep = query?.welcome_email === 'true' && query.token ? 2 : query?.token ? 3 : 0;
  const [organisation, setOrganisation] = useState([]);
  const [activeStep, setActiveStep] = useState(defaultActiveStep);
  const [checkStatus, setCheckStatus] = useState({});
  const [loader, setLoader] = useState(true);
  const { SideContent } = config?.sideContentSettings || '';

  function listenEvents({ eventEmitter, successToast, errorToast, setActiveStep, navigate }) {
    const observable = eventEmitter.getObservable();
    const subsciption = observable.subscribe(event => {
      switch (event.type) {
        case RESET_EVENTS.RESET_PASSWORD_SUCCESS:
          successToast(STRINGS.RESET_PASSWORD_SUCCESS);
          deepLinkApp(navigate);

          break;
        case RESET_EVENTS.RESET_PASSWORD_FAILURE:
          errorToast(event.data?.title || STRINGS.RESET_PASSWORD_FAILURE);
          break;
        case RESET_EVENTS.RESET_TOKEN_SUCCESS:
          successToast(STRINGS.RESET_TOKEN_SUCCESS);
          break;
        case RESET_EVENTS.RESET_TOKEN_FAILURE:
          errorToast(STRINGS.RESET_TOKEN_FAILURE);
          break;
        case RESET_EVENTS.RESET_SUCCESS:
          successToast(STRINGS.RESET_SUCCESS);
          setActiveStep(1);
          break;
        case RESET_EVENTS.RESET_FAILURE:
          errorToast(event.data?.errors?.title || STRINGS.RESET_FAILURE);
          setOrganisation(event.data?.errors.organisations);
          break;
        case RESET_EVENTS.FETCH_USER_STATUS:
          if (!event?.data?.user_profile_active) setActiveStep(3);
          else setCheckStatus({ ...event?.data, isValid: true });
          setLoader(false);
          break;
        case RESET_EVENTS.FAILURE_USER_STATUS:
          errorToast(event?.data?.title || STRINGS.FAILED_USER_STATUS);
          setCheckStatus({ isValid: false });
          setLoader(false);
          break;
        default:
          break;
      }
    });
    return subsciption;
  }

  const getSteps = () => {
    const reqTokenFormOnSubmit = async (values, { setSubmitting, setErrors }) => {
      let data = {
        email: values.email || null,
        phone: values?.phone?.number ? `+${values.phone.number}` : null,
        organisation_id: +values.organisation_id || null,
      };
      setErrors({});
      setSubmitting(true);
      await requestTokenClicked(eventEmitter, data);
      setSubmitting(false);
    };

    const reqUserStatus = token => {
      checkUserStatus(eventEmitter, token);
    };

    const resetPasswordFormOnSubmit = async (values, { setSubmitting, setErrors }) => {
      setErrors({});
      setSubmitting(true);
      await resetPasswordClicked(eventEmitter, {
        ...values,
        jwt_token: query.token,
      });

      setSubmitting(false);
    };
    return [
      {
        content: (
          <RequestTokenForm
            onSubmit={reqTokenFormOnSubmit}
            query={query}
            organisation={organisation}
            setOrganisation={setOrganisation}
            brandColor={brandColor}
          />
        ),
        icon: <></>,
      },
      {
        content: <RequestSuccess />,
        icon: <></>,
      },
      {
        content: (
          <CheckUserStatus
            query={query}
            setActiveStep={setActiveStep}
            reqUserStatus={reqUserStatus}
            checkStatus={checkStatus}
            setLoader={setLoader}
            loader={loader}
          />
        ),
        icon: <></>,
      },
      {
        content: <ResetPasswordForm onSubmit={resetPasswordFormOnSubmit} />,
        icon: <></>,
      },
    ];
  };

  useEffect(
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        successToast,
        errorToast,
        // setToastData,
        setActiveStep,
        navigate,
      });
      return () => subscription.unsubscribe();
    },
    [eventEmitter, navigate, successToast, errorToast],
  );

  return (
    <>
      <SplitLayout
        mainContent={
          <Box width="large">
            <Heading level={3}>{headings[activeStep]}</Heading>
            {subHeadings[activeStep] && (
              <Heading color="dark-2" level={5} size="large">
                {subHeadings[activeStep]}
              </Heading>
            )}
            <Text size="medium">{captions[activeStep]}</Text>

            <Steps
              margin="xsmall"
              steps={getSteps()}
              activeStep={activeStep}
              onChange={() => false}
              navigationVisible={false}
            />
          </Box>
        }
        customLogo={customLogo}
        sideContent={<SideContent />}
        bannerPadding={bannerPadding}
        mainProps={{ style: { marginTop: '3%' } }}
        sideWidth={sideWidth}
        formPadding={formPadding}
      />
    </>
  );
};

ForgotPassword.defaultProps = {
  bannerPadding: 'large',
};

ForgotPassword.propTypes = {
  bannerPadding: PropTypes.string,
  navigate: PropTypes.object,
  brandColor: PropTypes.string,
  sideWidth: PropTypes.number,
  formPadding: PropTypes.object,
};

export default ForgotPassword;
