import React, { useContext, useCallback } from 'react';
import { Anchor, Box, Button, Text, ThemeContext } from 'grommet';
import { connect } from 'react-redux';
import { Edit } from 'grommet-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from 'granite-admin/core/components/Avatar';
import { getTenantAccess } from 'granite-admin/utils/auth-singleton'; //getAuthToken, resetTenantAccess,
import Divider from 'granite-admin/core/components/Divider';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import {
  getLoginURL,
  termsLink,
  privacyLink,
  handleTermsLinkClick,
  handlePrivacyLinkClick,
} from 'common/TopNavbar/helpers/support';
import { sendCustomEmail } from 'common/TopNavbar/controllers/routeMessage';
import { STRINGS, PERMISSIONS } from 'common/TopNavbar/strings';

const getUserAvatar = (name, image, size) => {
  if (Boolean(name) === false) return;

  return (
    <Box flex="grow">
      <Avatar src={image ? image : null} width={size} height={size} round="full">
        <Text color="white" size="small">
          {!image && name.charAt(0).toUpperCase()}
        </Text>
      </Avatar>
    </Box>
  );
};

const CompanyDrop = ({
  navigate,
  selectedOrganisation,
  userProfile,
  signOut,
  showMyBoardBtn,
  backToBusiness,
  eventEmitter,
}) => {
  const theme = useContext(ThemeContext);
  const config = useContext(ConfigContext);
  const { showCreateOrganisation } = config ?? {};
  const tenantAccess = getTenantAccess();
  const url = getLoginURL(selectedOrganisation);

  const switchToOldPlatform = useCallback(() => {
    const alertEmailsList = userProfile?.redirectionAlertsEmails
      ?.replaceAll(/\s/g, '')
      ?.replaceAll('|', ' ')
      ?.replaceAll(',', ' ')
      ?.split(' ');
    if (alertEmailsList.length)
      alertEmailsList.forEach(email =>
        sendCustomEmail(eventEmitter, {
          email: email,
          message: `${userProfile?.firstName + ' ' + userProfile?.lastName} is trying to access the old platform for ${
            selectedOrganisation?.name
          } school.`,
          url,
        }),
      );
  }, [url, userProfile, selectedOrganisation, eventEmitter]);
  const handleOrgIdChange = useCallback(() => {
    if (
      userProfile.permissionsName.indexOf(PERMISSIONS.ORGANISATION_WRITE_PERMISSION) > -1 &&
      userProfile.permissionsName.indexOf(PERMISSIONS.SCHOOL_ADMIN_READ_PERMISSION) === -1
    ) {
      navigate('/company-settings');
    }
  }, [userProfile.permissionsName, navigate]);

  return (
    <Box background="sidebar-drops" pad={{ top: 'xsmall', bottom: 'medium' }} width="medium" flex={{ shrink: 0 }}>
      <Box justify="between" direction="row" margin={{ horizontal: 'small' }}>
        <Button
          primary
          size="small"
          label={<Text size="small">{STRINGS.SWITCH_LABEL}</Text>}
          tip={STRINGS.REDIRECT_OLD_PLATFORM}
          onClick={switchToOldPlatform}
        />
        <Link to="/profile">
          <Button tip="Edit Profile" icon={<Edit color={theme.global.colors['brand']} size="small" />} plain />
        </Link>
      </Box>
      <Box align="center" gap="small">
        {getUserAvatar(userProfile.firstName, userProfile.profileImage, 'xxsmall')}
        <Text>{userProfile.firstName + ' ' + userProfile.lastName}</Text>
        {selectedOrganisation?.pk && (
          <Box gap="small" align="center" margin={{ horizontal: 'small' }}>
            <Text>Org Id: {selectedOrganisation.pk}</Text>
            <Button
              label={selectedOrganisation.name}
              onClick={handleOrgIdChange}
              primary
              color={theme?.userProfileDrop?.button ?? 'text'}
            />
          </Box>
        )}
      </Box>

      <Box margin={{ vertical: 'medium' }}>
        <Divider
          style={{ width: 'auto', minWidth: 'auto' }}
          margin={{ horizontal: 'small' }}
          color="none"
          background={{ color: 'border', opacity: 'medium' }}
        />
      </Box>
      <Box align="center" gap="small">
        <Box direction="row" gap="small">
          {showMyBoardBtn && userProfile.isSystemAdmin && selectedOrganisation?.pk && !tenantAccess && (
            <Button
              label="My Board"
              onClick={backToBusiness}
              primary
              color={theme?.userProfileDrop?.button ?? 'text'}
            />
          )}
          <Button label="Logout" onClick={signOut} primary color={theme?.userProfileDrop?.button ?? 'text'} />
        </Box>
        {userProfile?.isSystemAdmin && showCreateOrganisation && !selectedOrganisation?.pk && (
          <Link to="/get-started">
            <Anchor as="span" size="small" color={theme.global.colors['info']} label="Create Organization" />
          </Link>
        )}
        <Box direction="row" gap="medium">
          {termsLink && (
            <Link onClick={handleTermsLinkClick} to="#">
              <Anchor as="span" size="small" color={theme.global.colors['info']} label={STRINGS.TERMS_AND_CONDITIONS} />
            </Link>
          )}
          {privacyLink && (
            <Link onClick={handlePrivacyLinkClick} to="#">
              <Anchor as="span" size="small" color={theme.global.colors['info']} label={STRINGS.PRIVACY_POLICY} />
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CompanyDrop.propTypes = {
  navigate: PropTypes.object,
  signOut: PropTypes.func,
  dispatch: PropTypes.func,
  userProfile: PropTypes.object,
  organisations: PropTypes.array,
  userOrganisations: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  showMyBoardBtn: PropTypes.bool,
  backToBusiness: PropTypes.func,
};

CompanyDrop.defaultProps = {
  backToBusiness: () => {},
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  organisations: OrganisationDucks.organisations(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
});
const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDrop);
