import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box, ResponsiveContext } from 'grommet';

import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import {
  getAuthToken,
  setAuthToken,
  getOrganisation,
  setOrganisation,
  getMyOrganisations,
} from 'granite-admin/utils/auth-singleton';
import DashboardLayout from 'granite-admin/core/components/DashboardLayout/index';
import Loader from 'granite-admin/core/components/Loader';
import useQuery from 'granite-admin/utils/useQuery';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { getCookie, setCookie } from 'granite-admin/utils/storage';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';
import { resetTenantAccess } from 'granite-admin/utils/auth-singleton';

import LinearLayout from 'common/LinearLayout';
import ParentLayout from 'common/ParentLayout';
import UnauthorizedAccess from 'common/UnauthorizedAccess';
import useWebSocket from 'common/useWebSocket';
import { onConnectionOpen, onConnectionClose, checkMobileDevice, deleteAllCookies } from 'utils/miscellaneous';
import Home from 'pages/home';
import {
  useFreshDeskWidget,
  useRouteBasedNavigate,
  TwoFABanner,
  useLoadWebSocket,
  useGrandListener,
  fetchUserProfile,
} from 'common/PrivateRoute';

const serverURL = process.env.REACT_APP_MAIN_URL;
const FooterLabel = process.env.REACT_APP_FOOTER_LABEL;
const footerDisabled = process.env.REACT_APP_FOOTER_DISABLED || true;
const host = window.location.host;

const businessDomain = host.substring(
  0,
  host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
);
const domain = host.substring(
  host.indexOf('.') + 1,
  host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
);
const mainDomain =
  `${businessDomain}` === serverURL.replace('https://', '')?.replace('http://', '') ? businessDomain : domain;

const PrivateRoute = ({
  component: Component,
  layout,
  roles,
  userProfile,
  selectedOrganisation,
  dispatch,
  breadcrumbPath,
  organisations,
  path,
  heading,
  actions,
  tabs,
  addTabBtn,
  noDivider,
  permissions,
  noHeader,
  noSidebar,
  fields,
  entityName,
  ...rest
}) => {
  let authToken = getAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  let localOrganisation = getOrganisation();
  const size = React.useContext(ResponsiveContext);
  useFreshDeskWidget(authToken, location);
  useRouteBasedNavigate(userProfile, location, localOrganisation);
  const {
    loadWebsocketInstance: loadNativeWebsocket,
    websocketInstance,
    closeConnection,
  } = useWebSocket({
    onConnectionOpen,
    onConnectionClose,
    // onGetMessage,
  });
  useLoadWebSocket(userProfile, loadNativeWebsocket);
  useGrandListener(closeConnection, loadNativeWebsocket, websocketInstance);

  const localMyOrganisations = getMyOrganisations();
  const memoChildProps = useMemo(() => ({ navigate, location }), [navigate, location]);

  const { getSavedThemeConfigs, defaultThemeConfigs } = useThemeConfigs();
  const { query: { embed, loadIframe, oAuthToken, orgId, dontMatch } = {}, pathname } = useQuery() ?? {};
  const isRollcallParentPortal = userProfile?.permissionsName?.includes('rollcall_parent_portal');
  const isRollcallAdminPortal = userProfile?.permissionsName?.includes('rollcall_admin_portal');
  const isParentInnerPath = path !== '/parents' && path.includes('/parent');
  //TODO: add two more checks so that spinner is shown while organisation and selectedOrganisation are being set

  if (oAuthToken && oAuthToken !== authToken) {
    authToken = oAuthToken;
    setAuthToken(authToken);
  }
  if (orgId && orgId !== localOrganisation) {
    localOrganisation = orgId;
    setOrganisation(localOrganisation);
  }

  const helperDeleteAllCookies = useCallback(() => {
    deleteAllCookies(mainDomain);
    removeAuthToken();
    resetTenantAccess();
    dispatch({ type: 'accounts/user/RESET_STATE' });
    if (closeConnection && websocketInstance.readyState === WebSocket.OPEN) {
      closeConnection(true);
    }
    navigate('/login');
  }, [dispatch, navigate, mainDomain]);

  if (authToken && !userProfile.username) {
    if (!getCookie('preURL')) {
      setCookie('preURL', JSON.stringify([window.location.hostname]), 1, mainDomain);
    }
    let isValidJSON = false;
    try {
      JSON.parse(getCookie('nodirectURL'));
      JSON.parse(getCookie('preURL'));
      isValidJSON = true;
    } catch {
      isValidJSON = false;
      helperDeleteAllCookies();
    }

    if (
      isValidJSON &&
      dontMatch !== 'yes' &&
      !JSON.parse(getCookie('preURL'))?.includes(window.location.hostname) &&
      !JSON.parse(getCookie('nodirectURL'))
    ) {
      helperDeleteAllCookies();
    } else if (dontMatch !== 'yes') {
      try {
        let preURLList = JSON.parse(getCookie('preURL'));
        if (!preURLList?.includes(window.location.hostname)) {
          preURLList = [...preURLList, window.location.hostname];
          setCookie('preURL', JSON.stringify(preURLList), 1, mainDomain);
        }
      } catch (e) {
        console.log('error occured while parsing cookie');
        helperDeleteAllCookies();
      }
      setCookie('nodirectURL', false, 1, mainDomain);
    }
    setTimeout(() => {
      if (pathname === '/operational-dashboard' && dontMatch === 'yes') navigate('/operational-dashboard');
    }, 1000);

    if (
      !(
        dontMatch !== 'yes' &&
        !JSON.parse(getCookie('preURL'))?.includes(window.location.hostname) &&
        !JSON.parse(getCookie('nodirectURL'))
      )
    )
      return fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
  }

  if (!organisations && localMyOrganisations && localMyOrganisations !== 'undefined') {
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: localMyOrganisations,
    });
  }

  if (!userProfile.username) {
    return <Navigate to={{ pathname: '/login', state: { from: location } }} />;
  }

  const dashboardLayout = () => {
    const { isSystemAdmin, userTwoFA, permissionsName, twoFAOption } = userProfile;

    return (
      <Box
        fill
        direction={size === 'small' ? 'column' : undefined}
        pad={{ top: size === 'small' ? 'medium' : undefined }}
      >
        {!isSystemAdmin &&
          userTwoFA === 'true' &&
          twoFAOption.toLowerCase() === 'both' &&
          permissionsName.includes('user_preference_write') &&
          !embed &&
          !loadIframe &&
          !noSidebar && (
            <TwoFABanner
              userProfile={userProfile}
              fetchUserProfile={fetchUserProfile}
              dispatch={dispatch}
              getSavedThemeConfigs={getSavedThemeConfigs}
              defaultThemeConfigs={defaultThemeConfigs}
            />
          )}

        <DashboardLayout
          pathname={location.pathname}
          navigate={navigate}
          breadcrumbPath={breadcrumbPath}
          heading={heading}
          actions={actions}
          noDivider={noDivider}
          addTabBtn={addTabBtn}
          permissions={permissions}
          noHeader={embed || loadIframe ? true : noHeader}
          tabs={tabs}
          childProps={memoChildProps}
          component={Component}
          userEmail={userProfile.email}
          navigationLayout={userProfile.navigationLayout}
          noSidebar={embed || loadIframe ? true : noSidebar}
          fields={fields} //list of fields in the form
          entityName={entityName}
          footerLabel={FooterLabel}
          noFooter={embed || loadIframe ? true : footerDisabled}
          {...rest}
        />
      </Box>
    );
  };

  const currentDomain = window.location.host.substring(
    window.location.host.indexOf('.') + 1,
    window.location.host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  );
  if (isRollcallParentPortal && isRollcallAdminPortal) {
    if (isParentInnerPath)
      return checkMobileDevice() ? (
        <Home />
      ) : (
        <ParentLayout>
          <Component pathname={location.pathname} navigate={navigate} location={location} />
        </ParentLayout>
      );
    else return dashboardLayout();
  } else if (isRollcallParentPortal && !isParentInnerPath) {
    return checkMobileDevice() ? <Home /> : <Navigate to={{ pathname: '/parent', state: { from: location } }} />;
  } else if (isRollcallAdminPortal && isParentInnerPath) {
    return <Navigate to={{ pathname: '/dashboard', state: { from: location } }} />;
  }
  if (
    currentDomain === userProfile.currentOrganisation?.domain ||
    (!isRollcallParentPortal && !isRollcallAdminPortal)
  ) {
    return <UnauthorizedAccess />;
  } else if (!userProfile.isSystemAdmin && layout !== 'ParentLayout') {
    if (userProfile.organisations !== undefined && userProfile.organisations.length === 0 && path !== '/get-started') {
      return <Navigate to={{ pathname: '/get-started', state: { from: location } }} />;
    } else if (!selectedOrganisation?.pk && path !== '/get-started') {
      return (
        <Box align="center" height="100%" justify="center">
          <Loader />
        </Box>
      );
    }
  }
  if (layout === 'LinearLayout') {
    return (
      <LinearLayout>
        <Component pathname={location.pathname} navigate={navigate} location={location} />
      </LinearLayout>
    );
  }
  if (layout === 'ParentLayout') {
    return (
      <ParentLayout>
        <Component pathname={location.pathname} navigate={navigate} location={location} />
      </ParentLayout>
    );
  }
  return dashboardLayout();
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.string,
  roles: PropTypes.array,
  userProfile: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  dispatch: PropTypes.func,
  breadcrumbPath: PropTypes.array,
  organisations: PropTypes.array,
  path: PropTypes.string,
  heading: PropTypes.object,
  actions: PropTypes.object,
  tabs: PropTypes.array,
  addTabBtn: PropTypes.bool,
  noDivider: PropTypes.bool,
  permissions: PropTypes.array,
  noHeader: PropTypes.bool,
  noSidebar: PropTypes.bool,
  fields: PropTypes.array,
  entityName: PropTypes.any,
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  organisations: OrganisationDucks.organisations(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
