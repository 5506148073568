import * as Yup from 'yup';
import { STRINGS } from 'pages/adminForget/strings';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, STRINGS.EMAIL_TOO_SHORT)
    .max(50, STRINGS.EMAIL_TOO_LONG)
    .email(STRINGS.EMAIL_INVALID)
    .required(STRINGS.EMAIL_REQUIRED),
});
