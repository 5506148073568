import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { uniqBy } from 'lodash';
import * as Yup from 'yup';

import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
import {
  getUserPreferences,
  getMasterPreferences,
  changePhone,
  verifyPhoneChange,
  // getMyProfile,
  // verifyEmailChange,
} from 'granite-admin/accounts/controllers/user.js';

import { changeUserPreference } from 'accounts/controllers/user';
import { RESET_EVENTS, USER_PREFERENCES_EVENTS } from 'accounts/controllers/events';
import { TWO_FA_LAYER_EVENTS } from 'common/TwoFALayer/controller/events';
import { submitToken, resendOtp } from 'common/TwoFALayer/controller/user';
import { STRINGS } from 'common/TwoFALayer/strings';
import { getValidationSchema } from 'common/TwoFALayer/helpers/validation';

const useCase = ({ dispatch, getSavedThemeConfigs, defaultThemeConfigs, fetchUserProfile, setShow2FALayer }) => {
  const formikRef = useRef();
  const { successToast, errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);

  const [configs, setConfigs] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [steps, setSteps] = useState(0);
  const [changePhoneLayer, setChangePhoneLayer] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await Promise.all([getMasterPreferences(), getUserPreferences(eventEmitter)]);
        setConfigs(result[0]);
        setPreferences(result[1]);
      } catch (err) {
        setConfigs([]);
        setPreferences([]);
      }
    }
    fetchData();
  }, [eventEmitter, getMasterPreferences, getUserPreferences]);

  const updateFAOptionType = useCallback(
    async values => {
      let updatedValues = [
        {
          pk: preferences?.find(i => i.name === STRINGS.TWO_FA_OPTIONS)?.pk,
          value: values['type'] === 'sms' ? 'SMS' : values['type'] === 'email' ? 'EMAIL' : 'BOTH',
          name: STRINGS.TWO_FA_OPTIONS,
          masterpk: configs?.find(i => i.name === STRINGS.TWO_FA_OPTIONS)?.pk,
        },
      ];
      await changeUserPreference(eventEmitter, updatedValues);
    },
    [setPreferences, eventEmitter, preferences, configs],
  );

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case RESET_EVENTS.FETCH_DUPLICATE_PHONE_STATUS:
          if (!event?.data?.duplicate_phone) updateFAOptionType(formikRef?.current?.values);
          else setSteps(1);
          setDuplicatePhone(event?.data?.duplicate_phone);
          break;
        case RESET_EVENTS.FAILURE_DUPLICATE_PHONE_STATUS:
          setDuplicatePhone(true);
          errorToast(event?.data?.title || STRINGS.MFA_UPDATE_FAILED);
          break;
        case TWO_FA_LAYER_EVENTS.SUBMIT_TOKEN_SUCCESS:
          updateTwoFA('true');
          setSteps(2);
          break;
        case TWO_FA_LAYER_EVENTS.SUBMIT_TOKEN_FAILURE:
          errorToast(event.data?.title || STRINGS.MFA_ENABLE_FAILED);
          break;
        case TWO_FA_LAYER_EVENTS.RESEND_OTP_SUCCESS:
          setOtpSent(true);
          break;
        case TWO_FA_LAYER_EVENTS.RESEND_OTP_FAILURE:
          if (event.data?.errors) {
            event.data.setErrors(event.data.errors);
            errorToast(event.data?.errors?.title || STRINGS.OTP_SEND_FAILED);
          }
          break;
        case USER_PREFERENCES_EVENTS.CHANGE_USER_PREFERENCES_SUCCESS:
          setPreferences(uniqBy([...event.data, ...preferences], 'pk'));
          setSteps(1);
          break;
        case USER_PREFERENCES_EVENTS.CHANGE_USER_PREFERENCES_FAILURE:
          errorToast(event.data?.title || STRINGS.MFA_UPDATE_FAILED);
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, updateFAOptionType, preferences]);

  const updatePhoneChange = async values => {
    //handle trhow evenet remove try catch not possible as granite throws error
    if (formikRef?.current) formikRef.current.setSubmitting(true);
    try {
      await changePhone(eventEmitter, {
        password: values?.password,
        new_phone: { ...(values?.phone ?? {}) },
      });
      setChangePhoneLayer('OTP');
      if (formikRef?.current) formikRef.current.validateForm();
      successToast(STRINGS.OTP_SENT_SUCCESS);
      if (formikRef?.current?.setFieldValue) formikRef.current.setFieldValue('verifyingUpdatedPhone', true);
    } catch (e) {
      if (e?.errors) console.log('CHANGE PHONE ERR', e.errors);
      errorToast(e?.errors?.title || STRINGS.OTP_SEND_FAILED);
    } finally {
      if (formikRef?.current) formikRef.current.setSubmitting(false);
    }
  };

  const verifyPhoneOTPChange = async values => {
    if (formikRef?.current) formikRef.current.setSubmitting(true);
    //handle using events remove try catch not possible as granite thwoes error
    try {
      await verifyPhoneChange(eventEmitter, { otp: values?.otp });

      fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
      setChangePhoneLayer(false);
      if (formikRef?.current) {
        formikRef.current.setFieldValue('isPhoneVerified', true);
        formikRef.current.submitForm();
      }
    } catch (e) {
      if (e.errors) console.log('ERR VERIFY PHONE', e.errors);
      errorToast(e?.errors?.title || STRINGS.OTP_VERIFY_FAILED);
      if (formikRef?.current) formikRef.current.setSubmitting(false);
    }
  };

  const updateTwoFA = useCallback(
    async (val = 'true') => {
      let updatedValues = [
        {
          pk: preferences.find(i => i.name === 'is_2fa_enabled')?.pk,
          value: val,
          name: 'is_2fa_enabled',
          masterpk: configs?.find(i => i.name === 'is_2fa_enabled')?.pk,
        },
      ];

      await changeUserPreference(eventEmitter, updatedValues);
      //move in event success remove await
      // setPreferences(uniqBy([...updatedValues, ...preferences], 'pk'));

      fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
    },
    [changeUserPreference, setPreferences, eventEmitter, preferences, configs],
  );

  const handleCancel = useCallback(() => {
    setChangePhoneLayer(false);
    if (formikRef?.current) formikRef.current.resetForm();
    if (steps === 1) setSteps(0);
    else setShow2FALayer(false);
  }, [setShow2FALayer, steps, formikRef]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    if (values?.type === 'email' && !values?.isEmailVerified) {
      if (otpSent) {
        await submitToken(eventEmitter, values?.token, values?.email);
      } else {
        await resendOtp(eventEmitter, { email: values?.email }, setErrors);
      }
    } else if (values?.type === 'sms' && !values?.isPhoneVerified) {
      if (otpSent) {
        await submitToken(eventEmitter, values?.token, values?.email, `+${values?.phone?.number}`);
      } else {
        await resendOtp(eventEmitter, { phone: `${values?.phone?.phone}` }, setErrors);
      }
    } else {
      if (values?.twoFA === 'false') {
        updateTwoFA('true');
        setSteps(2);
      }
    }

    setSubmitting(false);
  };

  const validationSchema = getValidationSchema({ changePhoneLayer, otpSent });

  //remove bvalidation in helpers

  return {
    formikRef,
    preferences,
    steps,
    changePhoneLayer,
    otpSent,
    duplicatePhone,
    setChangePhoneLayer,
    setOtpSent,
    updateFAOptionType,
    updatePhoneChange,
    verifyPhoneOTPChange,
    handleCancel,
    onSubmit,
    validationSchema,
    eventEmitter,
  };
};

export default useCase;
