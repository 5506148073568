import DOMPurify from 'dompurify';

/**
 * Sanitizes a string containing potentially dangerous HTML to prevent XSS attacks.
 *
 * @param {string} html - The HTML string to be sanitized.
 * @returns {string} - The sanitized HTML string.
 */
export const sanitizationDangerousHTML = html => {
  let sanitizedHTML = DOMPurify.sanitize(html);
  return sanitizedHTML;
};

/**
 * Recursively sanitizes a JSON object or string to ensure it's free of potentially dangerous content.
 * If the input is a string, it sanitizes the string.
 * If the input is an array, it sanitizes each element.
 * If the input is an object, it sanitizes each property value.
 *
 * @param {any} data - The data to be sanitized (string, array, or object).
 * @returns {any} - The sanitized data.
 */
export const sanitizeJSON = data => {
  if (typeof data === 'string') {
    return DOMPurify.sanitize(data);
  } else if (Array.isArray(data)) {
    return data.map(sanitizeJSON);
  } else if (typeof data === 'object' && data !== null) {
    const sanitizedObject = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        sanitizedObject[key] = sanitizeJSON(data[key]);
      }
    }
    return sanitizedObject;
  }
  return data;
};
