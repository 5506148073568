import styled from 'styled-components';
import { Box, DropButton } from 'grommet';
import { Close } from 'grommet-icons';

const StyledClose = styled(Close)`
  &:hover {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const StyledBox = styled(Box)`
  box-shadow: ${({ showBoxShadow }) =>
    showBoxShadow ? `0 6px 6px rgba(0,0,0,0.20), 0 6px 6px rgba(0,0,0,0.1)` : null};
`;

const StyledBoxSchool = styled(Box)`
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDropBoxSchool = styled(Box)`
  padding: 0.5rem;
  flex-shrink: 0;
`;

const StyledDropButtonSchool = styled(DropButton)`
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

export { StyledClose, Form, StyledBox, StyledBoxSchool, StyledDropBoxSchool, StyledDropButtonSchool };
