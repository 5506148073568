/**
 * Contains the API endpoint URLs used in the application.
 *
 * @constant {Object}
 * @property {string} ROLLOVER - The endpoint for rollover-related operations (fetch, create, update).
 * @property {string} ROUTE - The endpoint for route-related operations (list of route groups).
 * @property {string} ROUTE_EVENT - The endpoint for fetching route event data.
 * @property {string} STUDENT_DATA - The endpoint for fetching rollover student data.
 * @property {string} PLUGIN_ACTIVE - The endpoint for checking the active CSV upload plugin status.
 * @property {string} PREVIEW_URL - The endpoint for previewing student data before bulk upload.
 * @property {string} CSV_STATS - The endpoint for fetching CSV upload statistics.
 */
const URLS = {
  ROLLOVER: '/rollover/',
  ROUTE: '/route/',
  ROUTE_EVENT: '/route-events/',
  STUDENT_DATA: '/get-rollover-student-data/',
  PLUGIN_ACTIVE: '/active-plugin/upload-csv/',
  PREVIEW_URL: '/rollover-preview/',
  CSV_STATS: '/get-csv-upload-stats/',
};

export default URLS;
