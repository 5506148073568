//Lib Imports
import * as Yup from 'yup';

//Application Imports
import { VALIDATION_MESSAGES } from 'common/TopNavbar/strings';

/**
 * Calculates the byte size of a message based on the GSM 7-bit encoding,
 * extended GSM 7-bit encoding, and Unicode characters (handled as a fallback).
 *
 * GSM 7-bit encoding is a character set used in SMS (Short Message Service)
 * for efficient message size handling. Each character in this set consumes 1 byte.
 * Extended GSM 7-bit characters consume 2 bytes.
 * Characters not in either of these sets are encoded as Unicode and may consume
 * multiple bytes depending on their representation.
 *
 * @param {string} message - The message text for which the byte size is to be calculated.
 * @returns {number} - The total byte size of the message.
 *
 * GSM 7-bit characters (1 byte):
 * - Standard characters used in SMS (e.g. uppercase, lowercase letters, digits, punctuation)
 * - @£$¥èéùìòÇ, etc.
 *
 * Extended GSM 7-bit characters (2 bytes):
 * - Characters like ^{}\[~]|€
 *
 * Unicode (variable byte size):
 * - Any character not in the above sets is treated as Unicode and its byte size is calculated
 *   using the `Blob` API, which accounts for characters outside the GSM 7-bit range.
 */
export const calculatMessageByteSize = message => {
  const gsm7bitChars =
    '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
  const gsm7bitExtendedChars = '^{}\\[~]|€';
  let byteSize = 0;

  for (let char of message) {
    if (gsm7bitChars.includes(char)) {
      byteSize += 1;
    } else if (gsm7bitExtendedChars.includes(char)) {
      byteSize += 2;
    } else {
      const blobSize = new Blob([char]).size;
      byteSize += blobSize;
    }
  }
  return byteSize;
};

/**
 * This function takes a boolean and an array of route events and group name.
 * If the boolean is true, it loops through the array and creates an object with
 * the group name as the key and an array of route pks as the value.
 * If the boolean is false, it creates an object with the group name as the key
 * and an empty array as the value.
 * It then calls the setRouteEvent function with the created object.
 * @param {boolean} event
 * @param {array} events
 * @param {function} setRouteEvent
 */
export const onRoutesCheckAll = (event, events, setRouteEvent) => {
  let sortedList = {};
  if (event) {
    for (let i = 0; i < events?.length; i++) {
      let route = events[i];
      let groupName = route.groupName;
      if (sortedList[groupName]) sortedList[groupName].push(route.pk);
      else sortedList[groupName] = [route.pk];
    }
  } else {
    for (let i = 0; i < events?.length; i++) {
      let route = events[i];
      let groupName = route.groupName;
      if (sortedList[groupName]) sortedList[groupName].push();
      else sortedList[groupName] = [];
    }
  }
  setRouteEvent(sortedList);
};

/**
 * This function takes a boolean, an array of route events, a group name and a state update function.
 * If the boolean is true, it sets the state to an object with the group name as key and an array of route pks as the value.
 * If the boolean is false, it sets the state to an object with the group name as the key and an empty array as the value.
 * @param {boolean} event - boolean value
 * @param {array} data - array of route events
 * @param {string} group - group name
 * @param {function} setRouteEvent - state update function
 */
export const onCheckAll = (event, data, group, setRouteEvent) => {
  if (event) {
    setRouteEvent(prev => ({
      ...prev,
      [group]: data.map(i => i.pk),
    }));
  } else {
    setRouteEvent(prev => ({
      ...prev,
      [group]: [],
    }));
  }
};

/**
 * This function takes two parameters, an object of route events and an array of all route events.
 * It then checks if the length of the selected events is equal to the length of all events.
 * If they are equal, it returns true else it returns false
 * @param {object} routeEvent - object of route events
 * @param {array} events - array of all route events
 * @returns {boolean} boolean value
 */
export const checkedState = (routeEvent, events) => {
  let selectedEvents = [];
  Object.values(routeEvent).map(item => {
    for (let x of item) selectedEvents.push(x);
  });
  if (selectedEvents.length === events.length) return true;
  else return false;
};

/**
 * This function takes a boolean, a value, a group name and a state update function.
 * If the boolean is true, it adds the value to the array of route pks in the state with the group name as key.
 * If the boolean is false, it removes the value from the array of route pks in the state with the group name as key.
 * @param {boolean} event - boolean value
 * @param {any} value - value to be added or removed
 * @param {string} group - group name
 * @param {function} setRouteEvent - state update function
 */
export const onCheck = (event, value, group, setRouteEvent) => {
  if (event) {
    setRouteEvent(prev => {
      if (prev[group]) return { ...prev, [group]: [...prev[group], value] };
      else return { ...prev, [group]: [value] };
    });
  } else {
    setRouteEvent(prev => ({
      ...prev,
      [group]: [...prev[group].filter(item => item !== value)],
    }));
  }
};

/**
 * Parses a list of route data and groups them by `groupName`.
 * Each route is categorized under its respective group, and the grouped data
 * is returned as a list of objects where each object contains the group name
 * as the key and an array of routes as the value.
 *
 * Each route object includes:
 * - `pk`: The primary key (identifier) for the route.
 * - `route`: The event name of the route.
 * - `eventId`: Alias for `pk`, representing the event ID.
 * - `groupName`: The name of the group to which the route belongs.
 * - `groupId`: The identifier of the group.
 * - `checkedItem`: A boolean indicating if the item is checked (always true in this implementation).
 */
export const parseRouteList = data => {
  let sortedList = {};
  for (let i = 0; i < data.length; i++) {
    let route = data[i];
    let groupName = route.groupName;
    if (sortedList[groupName]) {
      sortedList[groupName].push({
        pk: route.pk,
        route: route.eventName,
        eventId: route.pk,
        groupName: route.groupName,
        groupId: route.groupId,
        checkedItem: true,
      });
    } else {
      sortedList[groupName] = [
        {
          pk: route.pk,
          route: route.eventName,
          eventId: route.pk,
          groupName: route.groupName,
          groupId: route.groupId,
          checkedItem: true,
        },
      ];
    }
  }
  sortedList = Object.entries(sortedList).map(e => ({ [e[0]]: e[1] }));
  return sortedList;
};

export const validationSchema = Yup.object().shape({
  message: Yup.string().required(VALIDATION_MESSAGES.PLEASE_ENTER_MESSAGE),
});
