// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

import { COMPANY_SETTINGS_URLS } from 'companySettings/gateways/endpoints';
import { ORGANISATION_API_URL } from 'common/completeUrls';

async function getMasterConfigs(payload) {
  const { data } = await GraniteAPIClient().get(COMPANY_SETTINGS_URLS.CONFIG_MASTER, payload);
  return data;
}

async function getSettings(payload) {
  const { data } = await GraniteAPIClient(ORGANISATION_API_URL).get(COMPANY_SETTINGS_URLS.SETTINGS, payload);
  return data;
}

export default { getMasterConfigs, getSettings };
