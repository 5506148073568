import React, { useMemo, useEffect, useCallback, useContext } from 'react';
import { ThemeContext } from 'grommet';

import { LOGIN_EVENTS } from 'granite-admin/accounts/controllers/constants';
import EventEmitter from 'granite-admin/utils/event-emitter';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import { useToast } from 'granite-admin/core/components/Toast';
// Application imports
// import { loginClicked } from 'granite-admin/accounts/controllers/user';
import useQuery from 'granite-admin/utils/useQuery';
import SplitLayout from 'granite-admin/core/components/SplitLayout';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';

import { loginClicked } from 'accounts/controllers/user';
import { LoaderBox } from 'common/styledComponents/LoaderBox';

import LoginForm from './components/LoginForm';
import customLogo from 'assets/logo.png';
import { deepLinkApp } from './helpers/parentLoginHelpers';
import { STRING_URLS } from './strings';
// import { checkMobileDevice } from 'utils/miscellaneous';

const eventEmitter = new EventEmitter();

const ParentLogin = () => {
  const { navigate, query } = useQuery();
  const loader = query.redirectDomain || query.tokenLogin || (query.access_token && query.access_token !== 'none');
  const { errorToast } = useToast();
  const goToParent = useCallback(() => navigate('/parent'), [navigate]);
  const goToOtp = useCallback(
    eventData =>
      navigate(STRING_URLS.PARENT_OTP_SUBMIT, {
        state: eventData,
      }),
    [navigate],
  );

  const config = useContext(ConfigContext);
  const theme = useContext(ThemeContext);
  const { SideContent } = config?.sideContentSettings || '';
  const { sideProps, logoWidth, sideWidth, configMainPropsLogin } = theme?.sideContentSettings || '';

  useEffect(() => {
    if (query && query.access_token && query.organisation)
      deepLinkApp(query.access_token, query.organisation, goToParent);
    // else if (checkMobileDevice()) navigate('/');
  }, [query, navigate, goToParent]);

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          if (event.data) {
            goToOtp(event.data);
          } else {
            goToParent();
          }
          break;
        case LOGIN_EVENTS.LOGIN_FAILURE:
          //   setOrganisation(event.data.organisations);
          errorToast(event?.data?.title || LOGIN_MESSAGES.LOGIN_FAILURE);
          break;
        case LOGIN_EVENTS.USER_NOT_EXIST:
          errorToast(LOGIN_MESSAGES.USER_NOT_EXIST);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, goToParent]);

  return (
    <>
      {loader ? (
        <LoaderBox />
      ) : (
        <SplitLayout
          mainContent={<LoginForm loginClicked={loginClicked} eventEmitter={eventEmitter} config={config} />}
          sideContent={<SideContent />}
          bannerPadding={'large'}
          sideProps={sideProps}
          logoWidth={logoWidth}
          // formPad={formPad}
          sideWidth={sideWidth}
          mainProps={configMainPropsLogin ?? { style: { marginTop: '3%' } }}
          customLogo={customLogo}
        />
      )}
    </>
  );
};
export default ParentLogin;
