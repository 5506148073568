import React, { useMemo } from 'react';
import { Box, Text, TextInput, Button } from 'grommet';
import PropTypes from 'prop-types';

import PhoneInput from 'granite-admin/core/components/PhoneInput/v2';
import FormField from 'granite-admin/core/components/FormField';
import OtpInput from 'granite-admin/core/components/OtpInput';

// import NumberInput from 'granite-admin/core/components/NumberInput';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import CustomFormField from 'granite-admin/core/components/CustomFormField';
import { STRINGS } from 'common/TwoFALayer/strings';
import { LINK_BLUE_COLOR } from 'common/PrivateRoute/styledComponents/styles';
import { verificationEmailColor } from 'common/TwoFALayer/styledComponents/common';

const SelectFADetails = ({
  values,
  setFieldValue,
  touched,
  handleChange,
  handleBlur,
  errors,
  otpSent,
  initialValues,
  changePhoneLayer,
  setChangePhoneLayer,
  updatePhoneChange,
  steps,
  validateForm,
  submitForm,
  isSubmitting,
  duplicatePhone,
  textWarningColor,
}) => {
  const duplicatePhoneExist = useMemo(() => values?.type === 'sms' && duplicatePhone, [values, duplicatePhone]);

  return (
    <Box fill={true} gap="small" pad={{ horizontal: 'large', vertical: 'medium' }}>
      <Box fill="horizontal" align="center" gap="small">
        <Text size="18px" weight={600} style={{ maxWidth: '100%' }} textAlign="center">
          {!values.isEmailVerified && values?.type === 'email'
            ? STRINGS.EMAIL_SETUP_PROMPT
            : values.isEmailVerified && values?.type === 'email'
            ? STRINGS.EMAIL_ALREADY_SETUP
            : !values.isPhoneVerified && values?.type === 'sms'
            ? STRINGS.PHONE_SETUP_PROMPT
            : values.isPhoneVerified && values?.type === 'sms'
            ? STRINGS.PHONE_ALREADY_SETUP
            : ``}
        </Text>
        {((!values.isEmailVerified && values?.type === 'email') ||
          (!values.isPhoneVerified && values?.type === 'sms')) && (
          <Text textAlign="center" color={verificationEmailColor} weight={600}>
            {`${STRINGS.VERIFICATION_CODE_PROMPT} ${values?.type === 'email' ? 'email' : 'phone number'}`}.
          </Text>
        )}
      </Box>

      <Box fill={true} pad={{ horizontal: 'xlarge' }}>
        {values?.type === 'sms' && (
          <FormField
            name="phone"
            label={<Box pad={{ bottom: 'small' }}>Phone Number *</Box>}
            error={touched.phone && errors.phone}
          >
            <PhoneInput
              inputProps={{
                name: 'phone',
              }}
              onChange={phone => {
                setFieldValue('phone', { ...(phone ?? {}) });
                if (initialValues?.phone?.number !== phone?.number) setChangePhoneLayer('SMS');
                else setChangePhoneLayer(false);

                validateForm();
              }}
              onBlur={handleBlur}
              value={values.phone}
              alwaysDefaultMask
              disabled
            />
          </FormField>
        )}

        {values?.type === 'email' && (
          <FormField name="email" label="Email" error={touched.email && errors.email} required>
            <TextInput
              type="email"
              name="email"
              disabled
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
        )}

        {changePhoneLayer && (
          <FormField name="password" label={STRINGS.CURRENT_PASSWORD_LABEL} error={touched.password && errors.password}>
            <PasswordInput
              name="password"
              autocomplete="new-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              disabled={changePhoneLayer !== 'SMS'}
            />
          </FormField>
        )}

        {((changePhoneLayer && steps === 1 && values?.type === 'sms') ||
          (steps === 1 && !otpSent && values?.type === 'sms' && !values?.isPhoneVerified) ||
          (steps === 1 && !otpSent && values?.type === 'email' && !values?.isEmailVerified)) && (
          <Button
            plain
            style={{ border: 'none', padding: '0px', alignSelf: 'end' }}
            label={
              <Text size="large" weight={600} color={LINK_BLUE_COLOR}>
                Send Code
              </Text>
            }
            onClick={() => {
              if (steps === 1 && values?.type === 'sms' && changePhoneLayer === 'SMS') updatePhoneChange(values);
              else if (
                (steps === 1 && !otpSent && values?.type === 'sms' && !values?.isPhoneVerified) ||
                (steps === 1 && values?.type === 'email' && !values?.isEmailVerified)
              )
                submitForm();
            }}
            disabled={
              (changePhoneLayer && steps === 1 && values?.type === 'sms' && !values?.password) ||
              isSubmitting ||
              (values?.type !== 'email' && !values?.phone?.number) ||
              duplicatePhoneExist
            }
          />
        )}

        {changePhoneLayer && (
          <>
            <CustomFormField
              name="otp"
              label={STRINGS.VERIFICATION_CODE_LABEL}
              error={touched.otp && errors.otp}
              pad={{ bottom: 'small' }}
            >
              <OtpInput
                name="otp"
                numInputs={6}
                onChange={token => setFieldValue('otp', token)}
                onBlur={handleBlur}
                value={values.otp}
                disabled={changePhoneLayer !== 'OTP'}
              />
            </CustomFormField>
          </>
        )}

        {(!values?.isPhoneVerified || !values.isEmailVerified) && otpSent && (
          <FormField
            name="token"
            label={STRINGS.ENTER_CODE_PROMPT}
            required
            error={touched.token && errors.token}
            noBorder
            normal
          >
            <OtpInput
              name="token"
              numInputs={6}
              onChange={otp => setFieldValue('token', otp)}
              onBlur={handleBlur}
              value={values.token}
            />
          </FormField>
        )}
      </Box>
      {duplicatePhoneExist && <Text color={textWarningColor}>{STRINGS.MULTIPLE_ACCOUNTS_PHONE_WARNING}</Text>}
    </Box>
  );
};

SelectFADetails.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  otpSent: PropTypes.any,
  initialValues: PropTypes.object,
  changePhoneLayer: PropTypes.any,
  setChangePhoneLayer: PropTypes.func,
  updatePhoneChange: PropTypes.func,
  steps: PropTypes.number,
  validateForm: PropTypes.func,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
  duplicatePhone: PropTypes.bool,
  textWarningColor: PropTypes.string,
};

export default SelectFADetails;
