import styled from 'styled-components';
import { Box } from 'grommet';

export const StyledPadLayerBox = styled(Box)`
  padding: ${({ theme }) => theme.global.edgeSize.small} 0;
  gap: ${({ theme }) => theme.global.edgeSize.small};
`;
export const dStyle = {
  width: '110px',
  padding: '0.25rem 0.75rem',
};

export const customTabs = {
  tab: {
    color: 'text',
    border: {
      hover: { color: 'secondary' },
    },
    active: {
      color: 'secondary',
    },
    pad: 'small',
    margin: { left: 'xsmall' },
    hover: {
      color: 'secondary',
    },
  },
};
export const msgBackgroundColor = '#86868629';
export const StyledMessageBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: ${props => props.theme.global.edgeSize.medium};
  padding-left: ${props => props.theme.global.edgeSize.medium};
  padding-right: ${props => props.theme.global.edgeSize.medium};
  padding-bottom: ${props => props.theme.global.edgeSize.small};
`;
export const avatarBackGroundColor = '#2D6E16';
export const avatarTextColor = '#fff';
export const formCloseColor = '#000';
export const companyDropStyle = {
  backgroundColor: '#fff',
  transition: 'all 0.5s ease 0s',
  borderRadius: '6px',
  boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 17px 6px',
};

export const recipientsColor = '#FFA500';
export const availableMsgColor = '#00A300';
export const byteSize = 134;
export const headingColor = '#100e0eb5';
export const grayColor = '#ddd';
export const messagingHubColor = '#118911';
