import React from 'react';
import Sidebar from 'granite-admin/core/components/Sidebar';
import TopNavBar from 'common/TopNavbar';
import { Box, Image } from 'grommet';
import { Calendar } from 'grommet-icons';
import styled from 'styled-components';

import SchoolLogoDropdown from 'common/SchoolLogoDropdown';
import LoginAdminForm from 'common/LoginAdminForm';
import SideContentForm from 'common/SideContentForm';
import loaderIcon from './assets/loaderIcon.svg';
import { backToBusiness } from 'common/TopNavbar/components/UserDropMenu';
import SelectButtonGroup from 'common/SelectButtonGroup2';

const StyledDatepickerBox = styled(Box)`
  & input {
    height: 34px;
  }
`;

const config = {
  sidebarSettings: {
    align: 'left',
    width: '256px',
    showUserDropMenu: true,
    showUserProfile: false,
    showReferGranite: true,
    SideBarComponent: Sidebar,
    layout: 'top_menu_bar', // leave empty to obey user pref otherwise use => "top_menu_bar/side_menu_bar"
    leftGroupVisibleItem: 6, // max num of item to show in left group for top navbar
    // getItemCount > a func by which project can decide item count to show as per screen size
    TopBarComponent: TopNavBar,
    TopNavCustomLeftComp: SchoolLogoDropdown,
    backToBoardClick: backToBusiness,
  },
  showVerifyAccount: false,
  toast: {
    duration: 5000,
    closeOnClick: false,
    showCloseBtn: true,
    maxCount: 5,
  },
  listOpenNewTab: true,
  showCreateOrganisation: true,
  websocketMappings: {
    notifications: {
      //socket action name
      showToast: false,
      autoRedirect: false,
      redirectUrl: '',
      volatileStorage: false, //to store onmessage's data in browser's storage if data is volatile
    },
    widgetDashboard: {
      showToast: true,
      toastMsg: 'Widget is refreshed with updated data',
      autoRedirect: true,
      redirectUrl: '/my-boards/:id/:name',
      volatileStorage: true,
    },
  },
  loginDetails: {
    ProjectLoginForm: LoginAdminForm,
  },
  sideContentSettings: {
    SideContent: SideContentForm,
  },
  subdomainRedirectEnabled: true,
  showSkeletonLoader: false,
  datePickerIcon: <Calendar size="18px" color="brand" />,
  DefaultLoaderComponent: () => <Image src={loaderIcon} width="50px" />,
  enableQueryDragDropUI: false,
  showOrgSwitchOption: false,
  overrideFilterInputs: {
    select: {
      component: SelectButtonGroup,
      routes: ['/my-boards/:id/:name', '/dashboards/:id'],
    },
    date: {
      component: StyledDatepickerBox,
      routes: ['/my-boards/:id/:name', '/dashboards/:id'],
    },
  },
};

export default config;
