export const STRING_URLS = {
  PARENT_FORGOT_PASSWORD: '/parent/forgot-password',
  PARENT_OTP_SUBMIT: '/parent/otp-submit',
};

export const STRINGS = {
  ERROR_FETCH_SSO_DETAILS: 'Unable to fetch SSO details',
  SIGN_IN_TO_ACCOUNT: 'Sign in to your RollCall Account',
  PARENT_PORTAL_LOGIN: 'Parent Portal Login',
};
