export const STRINGS = {
  PASSWORD_REQUIREMENTS: 'Your password must have the following:',
  CHARACTERS_IN_LENGTH: 'Characters in length',
  SPECIAL_CHARACTERS: 'such as ! # % @',
  NEW_PASSWORD: 'New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Sign in...',
  RESET_PASSWORD_LABEL: 'Reset Password',
  SET_PASSWORD_LABEL: 'Set Password',
};
