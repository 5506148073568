import React from 'react';
import { Box, Card, Heading } from 'grommet';

import Divider from 'granite-admin/core/components/Divider';
import EventEmitter from 'granite-admin/utils/event-emitter';

import LoginForm from './components/LoginForm';
import { STRINGS } from './strings';

const eventEmitter = new EventEmitter();

const ParentReset = () => {
  return (
    <Box>
      <Card pad="xxsmall" width="large" alignSelf="center" style={{ margin: '120px' }}>
        <Heading color="green" alignSelf="center" level={4}>
          {STRINGS.SET_PASSWORD}
        </Heading>
        <Divider color="black" />
        <LoginForm eventEmitter={eventEmitter} />
      </Card>
    </Box>
  );
};

export default ParentReset;
