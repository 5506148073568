import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .max(50, 'Too Long!')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
    .required('Password is required'),
});

export const initialValues = {
  password: '',
  confirm_password: '',
};
