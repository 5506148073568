// Lib Imports
import {
  format,
  isBefore,
  isAfter,
  isValid,
  parse,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  getHours,
  getMinutes,
  getYear,
  parseISO,
} from 'date-fns';

// const invalidFormats = ['dd/MM/yyyy', 'dd-MM-yyyy']; // new Date() returns invalid date if dd>12

export function convertToTimezone(date, timeZone) {
  const options = { timeZone: timeZone };
  const localTime = new Date(date);
  let utcTime;
  try {
    utcTime = localTime.toLocaleString('en-US', options);
  } catch (err) {
    utcTime = localTime.toLocaleString('en-US', { timeZone: 'Australia/Sydney' });
  }
  return new Date(utcTime);
}
// export const getDateInView = (date, bounds) => {
//   const current = new Date();
//   const [minDate, maxDate] = bounds;
//   if (date) {
//     return date;
//   } else {
//     if (minDate && isBefore(current, new Date(minDate))) {
//       return new Date(minDate);
//     } else if (maxDate && isAfter(current, new Date(maxDate))) {
//       return new Date(maxDate);
//     }
//   }
//   return current;
// };

export const getDateInView = (date, bounds, userProfileTimeZone) => {
  const [minDate, maxDate] = bounds;
  const current = new Date();
  const currentInUserProfileTimezone = convertToTimezone(current, userProfileTimeZone);
  if (date) {
    return date;
  } else {
    if (minDate && isBefore(currentInUserProfileTimezone, parseISO(minDate))) {
      return convertToTimezone(parseISO(minDate), userProfileTimeZone);
    } else if (maxDate && isAfter(currentInUserProfileTimezone, parseISO(maxDate))) {
      return convertToTimezone(parseISO(maxDate), userProfileTimeZone);
    }
  }
  return convertToTimezone(current, userProfileTimeZone);
};

export const setTime = (date, { hour = 0, minute = 0 }) => {
  return setHours(setMinutes(date, minute), hour);
};

export const isValidDate = (date, bounds) => {
  const [minDate, maxDate] = bounds;
  return isValid(date) && isBefore(date, new Date(maxDate)) && isAfter(date, new Date(minDate));
};

// from date object to date string in requested format
export const formatDate = (date, dateFormat) =>
  isValid(date) || isValid(new Date(date)) ? format(date?.length === 10 ? new Date(`${date} 00:00:00`) : new Date(date), dateFormat) : '';

// from date string of given format to date object
export const parseDate = (date, format, bounds) => {
  let parsedDate = parse(date, format, new Date());
  if (!isValidDate(parsedDate, bounds)) {
    // if (invalidFormats.includes(format) && isValid(parsedDate)) return null;
    format = format
      .match(/P+p+|./g)
      .map(substring => {
        let char = substring[0];
        if (char === 'P') {
          return 'MM/dd/yyyy';
        } else if (char === 'p') return 'hh:mm a';
        return substring;
      })
      .join('');
    if (date.length > 0) {
      parsedDate = parse(date?.length === 10 ? `${date} 00:00:00` : date, format.slice(0, date.length), new Date());
    }
    if (!isValidDate(parsedDate, bounds)) {
      parsedDate = new Date(date?.length === 10 ? `${date} 00:00:00` : date);
    }
  }
  return isValidDate(parsedDate, bounds) ? parsedDate : null;
};

export const convertBoundsToTimezone = (bounds, timeZone) => {
  const convertedBounds = bounds.map(dateValue => {
    const currentDate = new Date();
    const updatedDate = new Date(dateValue);
    updatedDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    return convertToTimezone(updatedDate, timeZone);
  });
  return convertedBounds;
};

export { setMonth, setYear, getHours, getMinutes, getYear };
