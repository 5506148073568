export const STRINGS = {
  ACCOUNT_ALREADY_ACTIVE:
    'Your account is already active. Would you like to login using your existing credentials or reset your password?',
  INVALID_LINK: 'Your link is invalid. Please contact your administrator to send it again.',
  RESET_PASSWORD: 'Reset Password',
  GO_TO_LOGIN: 'Go to Login',
  PASSWORD_RESET_SUCCESS: 'Password reset request success!',
  PASSWORD_RESET_LINK_SENT: 'Your password reset link has been sent successfully!',
  BACK_TO_LOGIN: 'Back to Login',
  EMAIL_LABEL: 'Email',
  EMAIL_ADDRESS_LABEL: 'Email Address*',
  EMAIL_PLACEHOLDER: 'john.doe@gmail.com',
  PHONE_LABEL: 'Phone number',
  OR_TEXT: 'Or',
  ORGANIZATION_LABEL: 'Select an Organization',
  SENDING_LINK: 'Sending Link...',
  SEND: 'Send',
  EXISTING_ACCOUNT_LOGIN: 'Log in to existing account',
  ENTER_NEW_PASSWORD: 'Enter New Password',
  CONFIRM_NEW_PASSWORD: 'Confirm New Password',
  SUBMIT_TEXT: 'Submit',
  SUBMITTING_TEXT: 'Submitting...',
  ENTER_OTP: 'Enter the OTP',
  RESEND_CODE: 'Resend code',
  FAILED_USER_STATUS: 'Failed to fetch user status',
  INVALID_CREDENTIALS: 'Please fill the values',
  RESET_SUCCESS: 'Mail sent successfully',
  RESET_FAILURE: 'Something went wrong, unable to sent mail',
  RESET_TOKEN_SUCCESS: 'OTP submitted successfully',
  RESET_TOKEN_FAILURE: 'Something went wrong, OTP could not be submitted',
  RESET_PASSWORD_SUCCESS: 'Your password has been updated successfully. Please proceed to login',
  RESET_PASSWORD_FAILURE: 'Reset password failed!',
};

export const STRING_URLS = {
  PARENT_HOME: '/parent',
  PARENT_FORGOT_PASSWORD: '/parent/forgot-password',
  FORGOT_PASSWORD: '/forgot-password',
  PARENT_LOGIN: '/parent/login',
  LOGIN: '/login',
};

export const STRING_EVENTS = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export const primaryContact = 'EMAIL';
export const allowAltRecovery = 'false';
