import { useEffect, useRef } from 'react';

const useLoadWebSocket = (userProfile, loadNativeWebsocket) => {
  const runOnce = useRef(true);

  useEffect(() => {
    if (userProfile?.username && runOnce.current) {
      loadNativeWebsocket();
      runOnce.current = false;
    }
  }, [userProfile, loadNativeWebsocket]);
};

export default useLoadWebSocket;
