/**
 * Generates a blob from the given data, creates a URL for the blob, and then
 * opens that URL in a new window. The data is expected to be a PDF.
 * @param {string|Blob} data - The PDF data to be downloaded
 */
export const generatePDFPreview = data => {
  const file = new Blob([data], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(file);
  window.open(url);
};
/**
 * Creates a link to download the given data as a file with the given filename
 * and type. The link is appended to the document body, clicked, and then
 * removed.
 * @param {string|Blob} data - The data to be downloaded
 * @param {string} fileName - The filename to be used for the download
 * @param {string} type - The MIME type of the file
 */
export const handleDownload = (data, fileName, type) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.style = 'display: none';
  const file = new Blob([data], { type });
  const url = window.URL.createObjectURL(file);
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
