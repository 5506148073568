import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import _ from 'lodash';

import theme from 'granite-admin/core/theme';
import { loadGrandEmitter, getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import NotFoundPage from 'granite-admin/core/components/NotFoundPage';
import { ToastProvider } from 'granite-admin/core/components/Toast/ToastProvider';
import { ConfigProvider } from 'granite-admin/core/components/ConfigProvider';
import configureStore from 'granite-admin/utils/ducks/configure-store';
import { loadAuthToken, loadOrganisation, loadMyOrganisations } from 'granite-admin/utils/auth-singleton';
import initIdleCallback from 'granite-admin/utils/initIdleCallback';
import coreRoutes from 'granite-admin/utils/routes';
import config from 'granite-admin/core/config';
import SidebarLayout from 'granite-admin/core/components/SidebarLayout';
import { deleteCookie } from 'granite-admin/utils/storage';

import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from './PublicOnlyRoute';
import PublicRoute from './PublicRoute';
import appRoutes from './routes';
import rollcallTheme from './rollcallTheme';
import rollcallConfig from './rollcallConfig';

import { mainDomain } from 'utils/miscellaneous';

const INACTIVITY_RELOAD_TIME = 60 * 1000; // in millisec - default is 60sec

let routes = _.unionBy(appRoutes, coreRoutes, 'path');
const routesWithNavbar = routes.filter(path => path.private && path.layout === 'DashboardLayout');

// Init Auth Singleton at load
loadAuthToken();
loadOrganisation();
loadMyOrganisations();
loadGrandEmitter();

// Create our Redux store.
const store = configureStore();
const customTheme = deepMerge(theme, rollcallTheme);
const customConfig = deepMerge(config, rollcallConfig);
// successToast
//check for browser support for requestIdlCallback
initIdleCallback();

const App = () => {
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  let timerRef = useRef(null);

  const checkFocusBlur = useCallback(() => {
    if (document.visibilityState === 'visible') {
      if (timerRef.current) clearTimeout(timerRef.current);
      grandEmitter.emit('RECREATE_SOCKET_CONNECTION');
    } else {
      timerRef.current = setTimeout(() => grandEmitter.emit('CLOSE_NATIVESOCKET_CONNECTION'), INACTIVITY_RELOAD_TIME);
    }
  }, [grandEmitter]);

  useEffect(() => {
    document.addEventListener('visibilitychange', checkFocusBlur);
    return () => {
      document.removeEventListener('visibilitychange', checkFocusBlur);
    };
  }, [checkFocusBlur]);
  useEffect(() => {
    const subscription = grandEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'AUTHENTICATION_ERROR':
          deleteCookie('preURL', mainDomain);
          deleteCookie('nodirectURL', mainDomain);
          grandEmitter.emit('SHOW_TOAST', {
            message: 'Your session has been expired, please login again' || event.data?.response?.data?.title,
            status: 'status-error',
            duration: 3000,
            autoClose: true,
          });
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
          break;

        default:
          break;
      }
    });
    return () => {
      subscription.unsubscribe();
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [grandEmitter]);

  return (
    <ReduxProvider store={store}>
      <Grommet theme={customTheme} full>
        <Router>
          <ConfigProvider config={customConfig}>
            <ToastProvider>
              <SidebarLayout routes={routesWithNavbar}>
                <Routes>
                  {routes.map(route => {
                    if (route.private)
                      return (
                        <Route
                          path={route.path}
                          key={route.path}
                          element={<PrivateRoute addTabBtn={false} {...route} />}
                        />
                      );
                    else if (route.publicOnly)
                      return <Route path={route.path} key={route.path} element={<PublicOnlyRoute {...route} />} />;
                    else return <Route path={route.path} key={route.path} element={<PublicRoute {...route} />} />;

                    // if (route.private) return <PrivateRoute key={route.path} addTabBtn={false} {...route} />;
                    // else if (route.publicOnly) return <PublicOnlyRoute key={route.path} {...route} />;
                    // else return <PublicRoute key={route.path} {...route} />;
                  })}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </SidebarLayout>
            </ToastProvider>
          </ConfigProvider>
        </Router>
      </Grommet>
    </ReduxProvider>
  );
};
export default App;
