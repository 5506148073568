export const ERROR_MESSAGES = {
  FAILED_TO_FETCH_EVENTGROUP: 'Unable to fetch the event groups',
};

export const PERMISSIONS = {
  DRIVER_MENU_READ: 'driver_menu_read',
};

export const DASHBOARD_EVENTS = {
  DEFAULT_DASHBOARD: 'DEFAULT_DASHBOARD',
};
