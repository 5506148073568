export const STRINGS = {
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  CONFIRM_MESSAGE: 'Confirm Message',
  VERIFY_ROUTES_MESSAGE: 'Please check you have selected the right routes for your message.',
  SMS_BALANCE_ERROR: 'Failed to get SMS Balance count',
  RECIPIENTS_COUNT_ERROR: 'Failed to get Recipients count',
  SEND_MESSAGE_ERROR: 'Unable to send message',
  EMAILS_TURNED_OFF: 'Emails are currently turned off for your school, please contact support to turn them on.',
  SELECT_DATE_FOR_ROUTES: 'Select date to choose routes scheduled for another day.',
  MESSAGE_BALANCE_WARNING: 'Your message balance is below the threshold, please top up to send SMS messages.',
  MESSAGE_SIZE_WARNING:
    'Attention: Your message exceeds the size limit and will incur additional charges if sent as is. Reduce the length to avoid additional charge.',
  SELECT_ROUTES_TO_MESSAGE: 'Select Routes to Message',
  AVAILABLE_MESSAGE: 'Available Message :',
  NUMBER_RECIPIENTS: 'Number of Recipients :',
  NAME: 'Name',
  EMAIL_PHONE: 'Email/Phone',
  MESSAGING_HUB: 'Messaging Hub',
  MESSAGING_HUB_DESCRIPTION:
    'The RollCall messaging hub is designed to make communicating with your students parents simple, from designing welcome emails, to messaging all the parents with students on certain buses.',
  NO_RECORDS_FOUND: 'No Records Found.',
  FROM_DATE: 'From Date',
  TO_DATE: 'To Date',
  TABLE_DATE_HEADER: 'Date',
  TABLE_TIME_HEADER: 'Time',
  TABLE_ROUTE_HEADER: 'Route',
  TABLE_SELECTED_ROUTE_DATE_HEADER: 'Selected Route Date',
  TABLE_AUTHOR_HEADER: 'Author',
  TABLE_SEND_OPTIONS_HEADER: 'Send options',
  TABLE_MESSAGE_HEADER: 'Message',
  TABLE_AFFECTED_STUDENTS_HEADER: 'No. of affected students',
  TABLE_RECIPIENTS_SENT_TO_HEADER: 'Recipients sent to',
  TABLE_SEE_ALL_RECIPIENTS_HEADER: 'See all email/phone recipients',
  SWITCH_LABEL: 'Switch',
  MESSAGE_REQUIRED_TEXT: 'Please enter the message',
  FAILED_ROUTE_EVENT_MESSAGE: 'Failed to get route events',
  MESSAGE_CONTENT_TEXT: 'Message Content',
  SEND_SMS_MESSAGE: 'SEND SMS MESSAGE',
  SEND_EMAIL_MESSAGE: 'SEND EMAIL MESSAGE',
  MESSAGE_TYPE_TEXT: 'Message Type',
  TOPUP_MESSAGE_BALANCE_TEXT: 'Click to top up message balance',
  SELECT_MESSAGE_OPTIONS_TEXT: 'Select Message Options',
  MESSAGE_ALL_ROUTES: 'Message all Routes',
  MARK_ALL_READ: 'Mark All Read',
  NOTIFICATION_TEXT: 'Notifications',
  DATE_LABEL: 'Date',
  RECIPIENTS_LABEL: 'Recipients',
  MESSAGE_LABEL: 'Message',
  MESSAGE_TOKEN_USED: 'Message Tokens Used',
  PARENTS_NOTIFIED_EMAIL: 'Parents to be Notified via Email',
  NOTIFICATION_NOT_FOUND: 'No Notification Found',
  REDIRECT_OLD_PLATFORM: 'Redirect to old platform',
  MESSAGE_TO_PARENTS_PLACEHOLDER: 'Message to Parents',
  SMS_TEXT: 'SMS',
  MESSAGE_ROUTE_TITLE: 'MESSAGE ROUTES',
  MESSAGE_LOGS_TITLE: 'MESSAGE LOGS',
  MORE_TEXT: 'More',
  RECIPIENTS_TEXT: 'Recipients',
  SMS_CREDIT_TOP_UP_URL: 'https://rollcallmarketplace.com.au/collections/sms-credit-top-up',
  FEEDBACK_WIDGET_URL:
    'https://rollcall.freshdesk.com/widgets/feedback_widget/new?&widgetType=popup&widgetView=no&submitTitle=Send+Report&submitThanks=Thank+you+for+your+report',
};

export const STRING_EVENTS = {
  FETCH_UNREAD_ONSCROLL: 'FETCH_UNREAD_ONSCROLL',
  FETCH_UNREAD_ONCE: 'FETCH_UNREAD_ONCE',
  FETCH_ALL_NOTIFICATION: 'FETCH_ALL_NOTIFICATION',
  FETCH_ALL: 'FETCH_ALL',
  NOTIFICATION: 'notification',
  MARKETPLACE: 'MARKETPLACE',
  SUPPORT: 'SUPPORT',
  MESSAGING: 'MESSAGING',
};

export const PERMISSIONS = {
  ORGANISATION_WRITE_PERMISSION: 'organisation_write',
  SCHOOL_ADMIN_READ_PERMISSION: 'school_admin_read',
};

export const ERROR_MESSAGES = {
  FAILED_TO_FETCH_LOGS: 'Unable to fetch the logs',
  FAILED_TO_FETCH_ROUTE_EVENTS: 'Unable to fetch the route events',
  FAILED_TO_FETCH_SMS_BALANCE_COUNT: 'Failed to get SMS Balance count',
  FAILED_TO_FETCH_RECIPIENTS_COUNT: 'Failed to get Recipents count',
  UANBLE_TO_SEND_MESSAGE: 'Unable to send message',
};

export const MESSAGE = {
  EMAILS_ARE_CURRENTLY: 'Emails are currently turned off for your school, please contact support to turn them on.',
  SELECT_DATES_TO_CHHOSE_ROUTES: 'Select date to choose routes scheduled for another day.',
  LOW_THRESHOLD_MESSAGE: 'Your message balance is below the threshold, please top up to send SMS messages.',
  EXCEED_MESSAGE: `Attention: Your message exceeds the size limit and will incur additional charges if sent as is. Reduce the length to avoid additional charge.`,
  MESSAGE_CONTENT: 'Message Content',
  DATE_CUSTOM_FORMAT: 'yyyy-MM-dd',
  ROLLCALL_MESSAGE_HUB: `The RollCall messaging hub is designed to make communicating with your students parents simple, from designing welcome emails, to messaging all the parents with students on certain buses.`,
  SEND_SMS_MESSAGE: 'SEND SMS MESSAGE',
  SEND_EMAIL_MESSAGE: 'SEND EMAIL MESSAGE',
  RIGHT_ROUTE_TEXT: 'Please check you have selected the right routes for your message.',
  MESSAGE_TOKEN_USED: 'Message Tokens Used',
  PARENT_TO_BE_NOTIFIED: 'Parents to be Notified via Email',
};

export const LINK = 'https://rollcallmarketplace.com.au/collections/sms-credit-top-up';

export const VALIDATION_MESSAGES = {
  PLEASE_ENTER_MESSAGE: 'Please enter the message',
};
