import styled from 'styled-components';
import { Box } from 'grommet';

export const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.global.colors['sidebar-icon']};
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 2rem;
  border: 1px solid ${({ theme }) => theme.global.colors.brand};
  padding: 0.4rem;
`;
export const dropPropsStyles = {
  backgroundColor: '#fff',
  transition: 'all 0.5s ease 0s',
  borderRadius: '3px',
  boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 17px 6px',
};
