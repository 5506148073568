// Lib Imports
import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// CORE Imports
import FormField from 'granite-admin/core/components/FormField';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import { resetPasswordValidationSchema } from 'pages/forgotpassword/helpers/constants';
import { STRINGS } from 'pages/forgotpassword/strings';

const ResetPasswordForm = ({ onSubmit, brandColor }) => {
  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      validationSchema={resetPasswordValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Text as="div" color="status-critical">
            {errors.title}
          </Text>
          <FormField
            name="password"
            label={STRINGS.ENTER_NEW_PASSWORD}
            required
            error={touched.password && errors.password}
          >
            <PasswordInput
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              iconColor={brandColor}
            />
          </FormField>
          <FormField
            name="confirm_password"
            label={STRINGS.CONFIRM_NEW_PASSWORD}
            required
            error={touched.confirm_password && errors.confirm_password}
          >
            <PasswordInput
              name="confirm_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              type="password"
              iconColor={brandColor}
            />
          </FormField>
          <Box fill="horizontal" direction="column" elevation="none" justify="between" pad="none">
            <Button
              type="submit"
              alignSelf="start"
              margin={{ vertical: 'small' }}
              label={isSubmitting ? STRINGS.SUBMITTING_TEXT : STRINGS.SUBMIT_TEXT}
              disabled={isSubmitting || !(isValid && dirty)}
              primary
              color={brandColor || 'secondary'}
            />
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  brandColor: PropTypes.string,
};

export default ResetPasswordForm;
