import React from 'react';
import { Box } from 'grommet';

const LinearLayout = ({ children }) => {
  return (
    <Box fill>
      {children}
    </Box>
  );
};

export default LinearLayout;