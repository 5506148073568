import { getCookie, setCookie } from 'granite-admin/utils/storage';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

export function setCookiesAndRedirect(organisation, bAOrganisation) {
  const host = window.location.host;
  const authToken = getAuthToken();
  const domain = host.substring(
    host.indexOf('.') + 1,
    host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  );

  setCookie('nodirectURL', true, 1, domain);
  setCookie('organisationData', JSON.stringify(organisation), 1, domain);
  setCookie('BAorigin', window.origin, 1, domain);
  setCookie('redirectDomain', 'yes', 1, domain);
  if (bAOrganisation?.length > 0) {
    window.location = `${window.location.protocol}//${organisation.domain}.${domain}${
      window.location.port ? `:${window.location.port}` : ''
    }/login?access_token=${authToken}&redirectDomain=yes`;
  } else {
    window.location = `${window.location.protocol}//${organisation.domain}.${domain}${
      window.location.port ? `:${window.location.port}` : ''
    }/login?access_token=${authToken}&redirectDomain=yes&tenantAccess=true`;
  }
  return { domain, authToken };
}
export const cookieElseSetter = () => {
  const URL = `${getCookie('BAorigin')}/login`;
  const host = window.location.host;
  const domain = host.substring(
    host.indexOf('.') + 1,
    host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
  );
  setCookie('nodirectURL', true, 1, domain);
  setCookie('organisationData', '', 1, domain);
  setCookie('BAorigin', '', 1, domain);
  setCookie('redirectDomain', '', 1, domain);
  window.location = URL;
};
