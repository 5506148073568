export const Campus = (color = "#ef1a35") => {
  return ` <svg xmlns="http://www.w3.org/2000/svg" width="36" height="28.171" viewBox="0 0 36 28.171">
  <g id="Group_1" data-name="Group 1" transform="translate(-618 -504.832)">
    <path id="Path_1" data-name="Path 1" d="M625.052,512.01V533H633.2s-.251-8.348,0-8.5a53.775,53.775,0,0,1,7.154,0c.112.167,0,8.5,0,8.5h6.956V512.01l-11.328-7.177Z" fill='${color}'/>
    <path id="Path_2" data-name="Path 2" d="M4.5,0A4.5,4.5,0,1,1,0,4.5,4.5,4.5,0,0,1,4.5,0Z" transform="translate(632 511)" fill="#fff"/>
    <path id="Path_3" data-name="Path 3" d="M635.1,512.189v3.259h3.05" transform="translate(1 1)" fill="none" stroke='${color}' stroke-width="1"/>
    <rect id="Rectangle_1" data-name="Rectangle 1" width="6" height="18" transform="translate(648 515)" fill='${color}'/>
    <rect id="Rectangle_2" data-name="Rectangle 2" width="6" height="18" transform="translate(618 515)" fill='${color}'/>
  </g>
</svg>`;
};