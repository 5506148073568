export const ACCOUNT_USER_URLS = {
  USER_PROFILE: '/user/profile/',
  PARENT_RECOVER_PASSWORD: '/parent/recover-password/',
  USER_RECOVER_PASSWORD: '/user/recover-password/',
  GET_ORG_PARAMS: '/get-org-params/',
  USER_LOGIN: '/user/login/',
  OTP_SUBMIT: 'user/login/otp/submit/',
  USER_NEW_PASSWORD: '/user/new-password/',
  USER_PREFERENCE: '/preference/user-preference/',
  UPDATE_PROFILE: '/user/me/',
  CHECK_USER_STATUS: '/check-user-status/',
  CHECK_DUPLICATE_PHONE: '/check-duplicate-phone/',
  RESEND_ACTIVATION: '/user/resend-activation/',
};
