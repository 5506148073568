import React, { useEffect } from 'react';
import { Box, Button, Text } from 'grommet';
import { Formik } from 'formik';

import FormField from 'granite-admin/core/components/FormField';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import { useToast } from 'granite-admin/core/components/Toast';

import { validationSchema } from 'pages/parentReset/helpers/constants';
import { STRINGS } from '../strings';
import { loginBtnColor } from 'pages/parentReset/styledComponents/colors';
import { LOGIN_EVENTS } from 'accounts/controllers/events';
import { resetPassWordStyle } from 'pages/parentReset/styledComponents/colors';

const LoginForm = (eventEmitter, loginClicked) => {
  const { errorToast } = useToast();

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    await loginClicked(eventEmitter, values);
    setSubmitting(false);
  };

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_FAILURE:
          errorToast(event.data?.title);
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast]);

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
            <form onSubmit={handleSubmit}>
              <div id="password_error_strength" className="alert fade in" style={resetPassWordStyle}>
                <p style={{ marginBottom: '0' }}>{STRINGS.YOUR_PASSWORD_FOLLOWING}</p>
                <ul style={{ paddingLeft: '18px' }}>
                  <li>
                    <strong>8</strong> {STRINGS.CHARACTERS_IN_LENGTH}
                  </li>
                  <li>
                    A mixture of <strong>Upper</strong> and <strong>Lower</strong> Case
                  </li>
                  <li>
                    At least 1 <strong>Number</strong>{' '}
                  </li>
                  <li>
                    At least 1 <strong>Character</strong> {STRINGS.SUCH_AS}
                  </li>
                </ul>
              </div>
              <FormField
                name="password"
                label={STRINGS.NEW_PASSWORD}
                required
                error={touched.password && errors.password}
              >
                <PasswordInput name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
              </FormField>
              <FormField
                name="confirm_password"
                label={STRINGS.CONFIRM_NEW_PASSWORD}
                required
                error={touched.confirm_password && errors.confirm_password}
              >
                <PasswordInput
                  name="confirm_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password}
                />
              </FormField>
              <Box>
                <Button
                  type="submit"
                  style={{ width: '50%', padding: '14px 0', fontSize: 'large' }}
                  alignSelf="center"
                  margin={{ top: 'medium' }}
                  label={isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : STRINGS.RESET_PASSWORD}
                  disabled={isSubmitting}
                  primary
                  color={loginBtnColor}
                />
              </Box>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};
export default LoginForm;
