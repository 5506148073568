import React from 'react';
import { Box } from 'grommet';
import { getMyProfile } from 'accounts/controllers/user';
import Loader from 'granite-admin/core/components/Loader';

const projectName = process.env.REACT_APP_PROJECT_NAME || 'RollCall';

/**
 * Fetches the user profile and updates the Redux store with the profile and organisation details.
 *
 * @param {function} dispatch - The Redux dispatch function to trigger actions.
 * @param {function} getSavedThemeConfigs - Function to retrieve saved theme configurations.
 * @param {object} defaultThemeConfigs - Default theme configurations to be used if no saved configurations are found.
 *
 * @returns {JSX.Element} A Box component that contains a Loader, displayed while the profile is being fetched.
 */
export function fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs) {
  getMyProfile().then(response => {
    dispatch({
      type: 'accounts/user/UPDATE_PROFILE',
      data: response,
    });
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: response.organisations,
    });
    setSelectedOrganisation(response.currentOrganisation, dispatch, getSavedThemeConfigs, defaultThemeConfigs);
  });
  return (
    <Box align="center" height="100%" justify="center">
      <Loader />
    </Box>
  );
}

/**
 * Sets the selected organisation in the Redux store and updates the theme configurations.
 *
 * @param {object} org - The organisation object containing details of the selected organisation.
 * @param {function} dispatch - The Redux dispatch function to trigger actions.
 * @param {function} getSavedThemeConfigs - Function to retrieve saved theme configurations.
 * @param {object} defaultThemeConfigs - Default theme configurations to be applied.
 */
export function setSelectedOrganisation(org, dispatch, getSavedThemeConfigs, defaultThemeConfigs) {
  if (org?.name) document.title = `${projectName} - ${org.name}`;
  dispatch({
    type: 'organisations/UPDATE_SELECTED_ORGANISATION',
    data: org,
  });
  dispatch({
    type: 'common/themeConfigs/UPDATE_THEME_CONFIGS',
    data: defaultThemeConfigs,
  });
  getSavedThemeConfigs(Object.keys(org)?.length < 1);
}
