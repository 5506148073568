import React, { useState, useContext } from 'react';
import { Box, Text, ThemeContext } from 'grommet';
import PropTypes from 'prop-types';

import TwoFALayer from 'common/TwoFALayer';
import { fetchUserProfile } from 'common/PrivateRoute/components/fetchUserProfile';
import { STRINGS } from 'common/PrivateRoute/strings';
import { LINK_BLUE_COLOR } from 'common/PrivateRoute/styledComponents/styles';
// import { LINK_BLUE_COLOR } from 'common/PrivateRoute/styles';

const TwoFABanner = ({ userProfile, dispatch, getSavedThemeConfigs, defaultThemeConfigs }) => {
  const [show2FALayer, setShow2FALayer] = useState(false);
  const theme = useContext(ThemeContext);

  return (
    <>
      <Box direction="row" justify="center" gap="small">
        <Text color={theme.global.colors['dark-orange']} weight="bold">
          {STRINGS.SECURITY_PRIORITY_MESSAGE}
        </Text>
        <Text
          weight="bold"
          onClick={() => setShow2FALayer(true)}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          color={LINK_BLUE_COLOR}
        >
          ({STRINGS.MFA_SETUP_LINK})
        </Text>
      </Box>
      {show2FALayer && (
        <TwoFALayer
          setShow2FALayer={setShow2FALayer}
          userProfile={userProfile}
          fetchUserProfile={fetchUserProfile}
          dispatch={dispatch}
          getSavedThemeConfigs={getSavedThemeConfigs}
          defaultThemeConfigs={defaultThemeConfigs}
        />
      )}
    </>
  );
};

export default TwoFABanner;

TwoFABanner.propTypes = {
  userProfile: PropTypes.object,
  fetchUserProfile: PropTypes.func,
  dispatch: PropTypes.func,
  getSavedThemeConfigs: PropTypes.func,
  defaultThemeConfigs: PropTypes.object,
};
