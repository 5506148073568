export const STRINGS = {
  EMAIL_TOO_SHORT: 'Email Address is too short',
  EMAIL_TOO_LONG: 'Email Address is too long',
  EMAIL_INVALID: 'Email Address is invalid',
  EMAIL_REQUIRED: 'Email Address is required',
  EMAIL_LABEL: 'Email Address*',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Sign in...',
  PLEASE_ENTER_EMAIL_TEXT: 'Please enter your email to reset password.',
  RESET_PASSWORD: 'Reset Password',
  ROLLCALL_IMG_SRC: 'https://school1.testing.rollcall.com.au/client/image/rollcall.png',
};
