// Core imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { setOrganisation } from 'granite-admin/utils/auth-singleton';
import { env } from 'granite-admin/env';
import { ACCOUNT_USER_URLS } from './endpoints';

const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ACCOUNTS_API;
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;

async function myProfile() {
  const { data } = await GraniteAPIClient(baseUrl).get(ACCOUNT_USER_URLS.USER_PROFILE);
  return data;
}

async function resetParentPassword(isParent, payload) {
  return await GraniteAPIClient(baseUrl).post(
    isParent ? ACCOUNT_USER_URLS.PARENT_RECOVER_PASSWORD : ACCOUNT_USER_URLS.USER_RECOVER_PASSWORD,
    payload,
  );
}

async function isSSOBtnVisible(subdomain) {
  return await GraniteAPIClient(baseUrl).get(
    `${ACCOUNT_USER_URLS.GET_ORG_PARAMS}?domain=${subdomain}&keys=forgerock_login_text,is_sso_enabled`,
  );
}

async function login(organisation_id, headers, payload) {
  const { data } = await GraniteAPIClient(baseUrl, headers).post(ACCOUNT_USER_URLS.USER_LOGIN, payload);
  if (organisation_id) setOrganisation(organisation_id);
  return data;
}

async function otpSubmit(payload) {
  const response = await GraniteAPIClient(url).post(ACCOUNT_USER_URLS.OTP_SUBMIT, payload);
  return response.data;
}

async function resetPassword(payload) {
  await GraniteAPIClient(baseUrl).post(ACCOUNT_USER_URLS.USER_NEW_PASSWORD, payload);
}
async function changeUserPreference(ID, value) {
  await GraniteAPIClient(url).put(`${ACCOUNT_USER_URLS.USER_PREFERENCE}${ID}/`, { value });
}

async function updateMyProfile(userData) {
  const { data } = await GraniteAPIClient(url).put(ACCOUNT_USER_URLS.UPDATE_PROFILE, userData);
  return data;
}

async function requestToken(isParent, payload) {
  await GraniteAPIClient(baseUrl).post(
    isParent ? ACCOUNT_USER_URLS.PARENT_RECOVER_PASSWORD : ACCOUNT_USER_URLS.USER_RECOVER_PASSWORD,
    payload,
  );
}

async function checkUserStatus(payload) {
  const { data } = await GraniteAPIClient(baseUrl).post(ACCOUNT_USER_URLS.CHECK_USER_STATUS, payload);
  return data;
}

async function checkDuplicatePhone(phone) {
  const payload = { phone: phone };
  const { data } = await GraniteAPIClient(baseUrl).post(ACCOUNT_USER_URLS.CHECK_DUPLICATE_PHONE, payload);
  return data;
}

async function resendOtp(payload) {
  const response = await GraniteAPIClient(baseUrl).post(ACCOUNT_USER_URLS.RESEND_ACTIVATION, payload);
  return response.data;
}

export default {
  myProfile,
  resetParentPassword,
  isSSOBtnVisible,
  login,
  otpSubmit,
  resetPassword,
  changeUserPreference,
  updateMyProfile,
  requestToken,
  checkUserStatus,
  checkDuplicatePhone,
  resendOtp,
};
