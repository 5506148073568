// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';

import ROUTE_URLS from 'common/TopNavbar/gateways/endpoints';

// make controller and try catch add docs
async function fetchNotifications(payload) {
  const { data } = await GraniteAPIClient().get(ROUTE_URLS.NOTIFICATIONS, payload);

  return data;
}

export default { fetchNotifications };
