const DASHLETS_EDIT_EVENTS = {
  SAVE_SUCCESS: 'SAVE_SUCCESS',
  SAVE_FAILURE: 'SAVE_FAILURE',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
  FETCH_EVENT_GROUP: 'FETCH_EVENT_GROUP',
  GET_ORG_SUCCESS: 'GET_ORG_SUCCESS',
  GET_ORG_FAILURE: 'GET_ORG_FAILURE',
};

export { DASHLETS_EDIT_EVENTS };
