// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

import { env } from 'granite-admin/env';

// Application Imports
import { URLS } from 'routeMessage/gateways/endpoints';
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;

/**
 * Fetch route message logs.
 *
 * @param {object} payload - The object with the following properties
 *   - school: The school for which route message logs are to be fetched
 *   - route_event_id: The route event id for which route message logs are to be fetched
 *   - from_date: The from date for which route message logs are to be fetched
 *   - to_date: The to date for which route message logs are to be fetched
 *
 * @returns {Promise<Object>} - The promise resolves with the response from the API
 */
async function getRouteMessageLogs(payload) {
  return await GraniteAPIClient(url).get(URLS.MESSAGING_LOGS, payload);
}

/**
 * Fetch the route events.
 *
 * @param {object} params - The parameters to be passed to the API
 *   - date: The date for which route events are to be fetched
 *   - event_group: The event group for which route events are to be fetched
 *   - page_size: The page size for which route events are to be fetched
 *   - ordering: The ordering for which route events are to be fetched
 *
 * @returns {json} - array of route events
 */
async function getTransportData(params) {
  return await GraniteAPIClient(url).get(URLS.ROUTE_EVENTS, params);
}

/**
 * Fetch the route SMS balance.
 *
 * @param {object} payload - The object with the following properties
 *   - on_hold: The on hold status
 *   - sms_balance: The sms balance
 *
 * @param {number} organisationId - The organisation id for which route SMS balance is to be fetched
 *
 * @returns {Promise<Object>} - The promise resolves with the response from the API
 */
async function getRouteSmsBalance(payload, organisationId = undefined) {
  return await GraniteAPIClient(url).post(
    URLS.ROUTE_SMS_BALANCE,
    payload,
    organisationId ? { headers: { Organisation: organisationId } } : {},
  );
}

/**
 * Post a message to all the routes of a given organisation.
 *
 * @param {object} payload - The object with the following properties
 *   - route_event_id: The route event id for which message is to be posted
 *   - message: The message body
 *   - children_who_have_boarded: The selection of children for whom message is to be posted
 *   - selectedMsgType: The type of message to be posted (SMS or EMAIL)
 *   - smsBalanceCount: The remaining SMS balance count
 *
 * @returns {Promise<void>} - The promise resolves when message is posted or fails
 */
async function postMessageAllRoutes(payload) {
  return await GraniteAPIClient(url).post(URLS.SEND_MESSAGE_TO_ROUTE, payload);
}

export default { getRouteMessageLogs, getTransportData, getRouteSmsBalance, postMessageAllRoutes };
