export const ROUTE_MESSAGE_EVENTS = {
  FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
  FETCH_LOGS_FAILURE: 'FETCH_LOGS_FAILURE',
  FETCH_ROUTE_SUCCESS: 'FETCH_ROUTE_SUCCESS',
  FETCH_ROUTE_FAILURE: 'FETCH_ROUTE_FAILURE',
  FETCH_SUCCESS_ROUTE_SMS_BALANCE: 'FETCH_SUCCESS_ROUTE_SMS_BALANCE',
  FETCH_FAILURE_ROUTE_SMS_BALANCE: 'FETCH_FAILURE_ROUTE_SMS_BALANCE',
  FETCH_SUCCESS_RECIPENTS_COUNT: 'FETCH_SUCCESS_RECIPENTS_COUNT',
  FETCH_FAILURE_RECIPENTS_COUNT: 'FETCH_FAILURE_RECIPENTS_COUNT',
};
