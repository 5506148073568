class SmartRouteStudentEntity {
  constructor(data) {
    this.pk = data.pk;
    this.firstName = data.first_name ? data.first_name : data.student?.first_name;
    this.lastName = data.last_name ? data.last_name : data.student?.last_name;
    this.fullName = data.first_name
      ? data.first_name + ' ' + data.last_name
      : data.student?.first_name + ' ' + data.student?.last_name;
    this.campus = data.campus__name || '';
    this.cardNumber = data.card_number;
    this.campusId = data.campus_id || undefined;
    this.campusStop = data.campus__stop__name || '';
    this.campusStopId = data.campus__stop__id || undefined;
    this.grade = data.current_admission_grade;
    this.address = data.address;
    this.bookingOption = data.booking_option;
    this.studentType = data.student_type;
    this.latitude = data?.address_lat_long?.coordinates?.[0];
    this.longitude = data?.address_lat_long?.coordinates?.[1];
    this.typeId = 'STUDENTMARKER';
  }
}

class SmartRouteStopEntity {
  constructor(data) {
    this.pk = data.pk ? data.pk : '';
    this.name = data.name;
    this.address = data.address;
    this.longitude = data?.longitude || data?.lat_long?.coordinates[1];
    this.latitude = data?.latitude || data?.lat_long?.coordinates[0];
    this.waypoint = data?.waypoint;
    this.typeId = 'STOPS';
    this.activeInSmartRoute = data.active_in_smart_route;
    this.homeCampusId = data.home_campus_pk || data?.stop?.home_campus_pk;
  }
}

export { SmartRouteStudentEntity, SmartRouteStopEntity };
