// Lib Imports
import React from 'react';
import { Box, Button } from 'grommet';
import { Formik } from 'formik';

// CORE Imports
import FormField from 'granite-admin/core/components/CustomFormField';
import OtpInput from 'granite-admin/core/components/OtpInput';
import { validationSchema } from 'pages/otpSubmit/helpers/constants';
import { STRINGS } from 'pages/otpSubmit/strings';
// Application Imports

const VerificationForm = ({ eventEmitter, otpSubmit, type, location, brandColor }) => {
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    let obj = {
      [type]: location.state?.email,
      otp: values.token,
    };
    await otpSubmit(eventEmitter, obj, location.state);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleBlur, setFieldValue, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <FormField name="token" label={STRINGS.OTP} error={touched.token && errors.token}>
            <OtpInput
              name="token"
              onChange={otp => setFieldValue('token', otp)}
              onBlur={handleBlur}
              value={values.token}
              numInputs={6}
            />
          </FormField>
          <Box fill="horizontal" direction="column" elevation="none" justify="between" pad="none">
            <Button
              type="submit"
              alignSelf="start"
              margin={{ vertical: 'small' }}
              label={isSubmitting ? STRINGS.VERIFYING : STRINGS.VERIFY}
              disabled={isSubmitting || !(isValid && dirty)}
              primary
              color={brandColor || 'secondary'}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default VerificationForm;
