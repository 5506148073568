// Lib imports
import React, { useEffect, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Image, Heading } from 'grommet';
import { StatusGood, Radial } from 'grommet-icons';

//Components Imports
import { useToast } from 'granite-admin/core/components/Toast';
import SplitLayout from 'granite-admin/core/components/SplitLayout';

// Core imports
import EventEmitter from 'granite-admin/utils/event-emitter';
import ONBOARDING_MESSAGES from 'granite-admin/messages/onBoarding';
import { getCompanyTypes } from 'granite-admin/common/controllers/common';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import { env } from 'granite-admin/env';

// Application imports
import { ONBOARDING_EVENTS } from 'onBoarding/controllers/events';
import OnBoardingForm from 'pages/onBoarding/components/OnBoardingForm';
import Onboarding from 'assets/onboarding-3.svg';
import { StyledBox } from 'pages/onBoarding/styledComponents/elements';
import { ERROR_MSG } from 'pages/onBoarding/strings';
import useWebSocket from 'common/useWebSocket';
import { onConnectionOpen, onConnectionClose } from 'common/CommonFunction.js';
import { STRINGS } from 'pages/onBoarding/strings';
import { handlePTA } from 'pages/onBoarding/helpers/onBoardingHelpers';

const eventEmitter = new EventEmitter();
const OnBoarding = ({ dispatch, navigate, userProfile, selectedOrganisation }) => {
  const { successToast, errorToast } = useToast();
  const [companyTypesList, setCompanyTypesList] = useState([]);
  const isSystemAdmin = userProfile.isSystemAdmin;
  const config = useContext(ConfigContext);

  const { websocketInstance: nativeWebsocketInstance } = useWebSocket({
    onConnectionClose,
    onConnectionOpen,
  });

  const msg = useMemo(() => {
    const projectName = env.REACT_APP_PROJECT_NAME || process.env.REACT_APP_PROJECT_NAME || STRINGS.ROLLCALL;
    return `Welcome to ${projectName}`;
  }, []);

  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter, goToDashboard
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case ONBOARDING_EVENTS.ONBOARDING_SUCCESS:
          let data = event?.data;
          dispatch({
            type: STRINGS.ORGANISATIONS_UPDATE_ORGANISATIONS,
            data: [data],
          });
          dispatch({
            type: STRINGS.ORGANISATIONS_UPDATE_SELECTED_ORGANISATIONS,
            data: data,
          });
          successToast(ONBOARDING_MESSAGES.ONBOARDING_SUCCESS);
          handlePTA(data, config, isSystemAdmin, selectedOrganisation, nativeWebsocketInstance);
          break;
        case ONBOARDING_EVENTS.ONBOARDING_FAILURE:
          errorToast(event?.data?.title || ONBOARDING_MESSAGES.ONBOARDING_FAILURE);
          break;
        case ONBOARDING_EVENTS.COMPANY_TYPES_FETCH_SUCCESS:
          setCompanyTypesList(event.data.possible_values);
          break;
        case ONBOARDING_EVENTS.COMPANY_TYPES_FETCH_FAILURE:
          errorToast(ERROR_MSG.FAILED_TO_FETCH_COMPANY_TYPES);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, dispatch, successToast, errorToast]);

  useEffect(() => {
    getCompanyTypes(eventEmitter);
  }, [eventEmitter]);

  return (
    <Box background="white">
      <SplitLayout
        mainContent={
          <Box width="large" flex="grow">
            <Heading level={3}>{STRINGS.TELL_US_ABOUT_YOUR_COMPANY}</Heading>
            <OnBoardingForm
              eventEmitter={eventEmitter}
              companyTypesList={companyTypesList}
              userProfile={userProfile}
              navigate={navigate}
              dispatch={dispatch}
              selectedOrganisation={selectedOrganisation}
            />
          </Box>
        }
        sideContent={
          <Box flex>
            <>
              <Image src={Onboarding} fit="contain" />
            </>
            <Box alignSelf="center" margin={{ top: 'xlarge' }}>
              <Box direction="row" gap="medium" flex={{ shrink: 0 }}>
                <StyledBox>
                  <StatusGood color="white" />
                </StyledBox>
                <Heading level={4} color="white">
                  {msg}
                </Heading>
              </Box>
              <Box direction="row" gap="medium">
                <Radial color="white" />
                <Heading level={4} color="white">
                  {STRINGS.TELL_US_ABOUT_YOUR_COMPANY}
                </Heading>
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

OnBoarding.propTypes = {
  dispatch: PropTypes.func,
  navigate: PropTypes.object,
  userProfile: PropTypes.object,
  selectedOrganisation: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
