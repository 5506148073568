import React from 'react';
import { Box, Text, TextInput, Paragraph, Button } from 'grommet';
import FormField from 'granite-admin/core/components/FormField';
import { Formik } from 'formik';
import { STRINGS } from 'pages/adminForget/strings';
import { validationSchema } from 'pages/adminForget/helpers/constants';

const LoginForm = (eventEmitter, loginClicked) => {
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    await loginClicked(eventEmitter, values);
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
            <form onSubmit={handleSubmit}>
              <Text as="div" color="status-critical">
                {errors.title}
              </Text>
              <Paragraph>{STRINGS.PLEASE_ENTER_EMAIL_TEXT}</Paragraph>
              <FormField name="email" label={STRINGS.EMAIL_LABEL} error={touched.email && errors.email}>
                <TextInput type="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
              </FormField>
              <Box>
                <Button
                  type="submit"
                  style={{ width: '100%', padding: '14px 0', fontSize: 'large' }}
                  alignSelf="start"
                  // size='xxlarge'
                  margin={{ top: 'medium' }}
                  label={isSubmitting ? STRINGS.SUBMIT_BUTTON_LABEL_SUBMITTING : STRINGS.RESET_PASSWORD}
                  disabled={isSubmitting}
                  primary
                  color="accent-1"
                />
              </Box>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};
export default LoginForm;
