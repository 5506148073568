/**
 * Redirects the user to the appropriate dashboard based on their current location.
 * - If the current path is '/parent/otp-submit', it navigates to the parent dashboard ('/parent').
 * - Otherwise, it navigates to the general dashboard ('/dashboard').
 *
 * @param {Function} navigate - The navigation function used to redirect the user.
 */
export const redirectToDashboard = navigate => {
  const currentPath = window.location.pathname;

  if (currentPath === '/parent/otp-submit') {
    navigate('/parent');
  } else {
    navigate('/dashboard');
  }
};
