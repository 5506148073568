// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import Organisation from 'granite-admin/organisations/entities/organisation';
import { env } from 'granite-admin/env';

import DashletsEntity from 'dashlets/entities/dashlets';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const organisationURL = env.REACT_APP_API_BASE_URL + env.REACT_APP_ORGANISATION_API;
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;
// const organisationURL = process.env.REACT_APP_ORGANISATION_BASE_URL;
const mapToSnakeCase = data => {
  return {
    dashlet_settings: {
      // school: false,
      wrong_stop_exception: data?.wrong_stop_exception,
      off_stop_exception: data?.off_stop_exception,
      in_progress_route: data?.in_progress_route,
      completed_route: data?.completed_route,
      available_seats: data?.available_seats,
      behaviour: data?.behaviour,
      not_allocated_pickup: data?.not_allocated_pickup,
      not_travelled_student: data?.not_travelled_student,
      capacity: data?.capacity,
      loadings: data?.loadings,
      casual_travellers: data?.casual_travellers,
      logged_on_route: data?.logged_on_route,
    },
  };
};

async function getDashlets(params) {
  try {
    let orgList = JSON.stringify(
      params?.orgsRef?.current?.length ? params?.orgsRef?.current?.map(i => i?.pk) : undefined,
    );
    const payload = { params: { ...params.data, organisation: orgList } };
    const { data } = await GraniteAPIClient(url).get('/organisation-dashlet/', payload);
    let list = data ? data.results?.map(item => new DashletsEntity(item)) : [];
    list = list?.sort((a, b) => a?.school?.localeCompare(b?.school));
    return { list, count: data?.count };
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function updateDashlets(params) {
  try {
    const payload = mapToSnakeCase(params);
    const headers = { organisation: params?.organisation_id };
    return await GraniteAPIClient(url, headers).post('/organisation-dashlet/', payload);
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function getAdminOrganisations(params) {
  try {
    const payload = { params: { ...params, ordering: params?.ordering === null ? 'name' : params?.ordering } };
    const { data } = await GraniteAPIClient(url).get('/business-admin/organisations/', payload);
    const organisations = data.results.map(organisation => new Organisation(organisation)) || [];
    return { list: organisations, count: data.count };
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function getParticularOrganisations(params) {
  try {
    const payload = { params: { ...params } };
    const { data } = await GraniteAPIClient(organisationURL, { organisation: undefined }).get(
      '/business/admin/access/organisations/',
      payload,
    );
    const organisations = data.results.map(organisation => new Organisation(organisation)) || [];
    return { list: organisations, count: data.count };
  } catch (error) {
    throw new GraniteError(error);
  }
}
async function getDashLetOrganisations(params) {
  const payload = { params: { ...params } };
  const { data } = await GraniteAPIClient(organisationURL, { organisation: undefined }).get(
    '/business/admin/access/organisations/',
    payload,
  );
  const organisations = data.results.map(organisation => new Organisation(organisation)) || [];
  return { list: organisations, count: data.count };
}

export default {
  getDashlets,
  updateDashlets,
  getAdminOrganisations,
  getParticularOrganisations,
  getDashLetOrganisations,
};
