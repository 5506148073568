const reservedByteSize = 56;
export const totalBtyeSize = 140;
export const byteSize = totalBtyeSize - reservedByteSize;

export const childrenBoardedOptions = [
  { pk: 'ALL', value: 'Send to all parents with students booked on this route' },
  { pk: 'ON', value: 'Only send to parents with students who have boarded' },
  { pk: 'ALLOCATED', value: 'Only send to parents with students who have not yet boarded' },
];

export const messageTypeOptions = [
  { pk: 'SMS', value: 'SMS' },
  { pk: 'EMAIL', value: 'EMAIL' },
];
