// Application imports
import getAllRoutesAPI from 'goToRoute/gateways/goToRoute-api';
import { GO_TO_ROUTE_EVENTS } from 'goToRoute/controllers/constant';

async function getAllRoutes(eventEmitter, domain) {
  try {
    const data = await getAllRoutesAPI(domain);
    eventEmitter.emit(GO_TO_ROUTE_EVENTS.FETCH_SUCCESS, data);
  } catch (error) {
    eventEmitter.emit(GO_TO_ROUTE_EVENTS.FETCH_FAILURE, error?.errors?.title);
  }
}
export default getAllRoutes;
