import { useEffect, useMemo, useCallback } from 'react';
import useUtility from 'granite-admin/utils/useUtility';
import { loadMenuItemsAndPermissions } from 'granite-admin/accounts/controllers/sidebar';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { TOPNAVBAR_EVENTS } from 'common/TopNavbar/controllers/events';
import { useToast } from 'granite-admin/core/components/Toast';

import { parseTopMenuItems } from 'common/TopNavbar/helpers/parseList';

function fetchSidemenuItems(dispatch) {
  loadMenuItemsAndPermissions()
    .then(response => {
      dispatch({
        type: 'accounts/sidebar/UPDATE_SIDEBAR',
        data: response,
      });
    })
    .catch(error => console.log('page error', error));
}

const useCase = ({ navigate, dispatch, sidebarItems, userPreferenceData, selectedOrganisation }) => {
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const { errorToast } = useToast();
  //move in strings.js
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case TOPNAVBAR_EVENTS.SEND_CUSTOM_EMAIL_FAILURE:
          if (event.data) errorToast(event.data || 'Redirected to old platform fail');
          break;

        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter]);
  //move in strings.js or events.js
  const grandListener = useCallback(
    event => {
      switch (event.type) {
        case 'UPDATE_SIDEBAR':
          fetchSidemenuItems(dispatch);
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  useUtility({ grandListener });

  useEffect(() => {
    if (sidebarItems.length === 0) fetchSidemenuItems(dispatch);
  }, [dispatch, sidebarItems]);

  const signOut = useCallback(() => {
    navigate('/logout');
  }, []);

  const navItems = useMemo(
    () => parseTopMenuItems(sidebarItems, userPreferenceData, selectedOrganisation),
    [sidebarItems, userPreferenceData, selectedOrganisation],
  );

  return {
    signOut,
    navItems,
    eventEmitter,
  };
};

export default useCase;
