//Lib imports
import * as Yup from 'yup';

import { setCookie, deleteCookie } from 'granite-admin/utils/storage';
import { getAuthToken, resetTenantAccess } from 'granite-admin/utils/auth-singleton';

//Application imports
import { STRINGS } from 'pages/onBoarding/strings';
import { REDIRECT_ROUTES } from 'common/strings';

/**
 * Creates a Yup validation schema for a form based on whether the user is a system admin or not.
 *
 * This schema is used to validate the form fields and provide error messages accordingly.
 * It dynamically includes additional fields (first name, last name, phone, and email) if the user is a system admin.
 *
 * @function validationSchema
 * @param {boolean} isSystemAdmin - A flag indicating if the user is a system admin.
 *                                  If true, extra fields will be validated, such as first name, last name, phone, and email.
 * @returns {object} - A Yup validation schema object.
 */
export const validationSchema = isSystemAdmin =>
  Yup.object().shape({
    name: Yup.string().trim().required(STRINGS.COMPANY_NAME_REQUIRED),
    industry: Yup.string().nullable().required(STRINGS.COMPANY_TYPE_REQUIRED),
    domain: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/, STRINGS.INVALID_DOMAIN_NAME),
    ...(isSystemAdmin && {
      firstName: Yup.string().trim().required(STRINGS.USER_FIRST_NAME_REQUIRED),
      lastName: Yup.string().trim().required(STRINGS.USER_LAST_NAME_REQUIRED),
      phone: Yup.object()
        .required(STRINGS.PHONE_NAME_REQUIRED)
        .test(value => {
          if (value?.error) {
            return new Yup.ValidationError(STRINGS.INVALID_PHONE_NUMBER, undefined, STRINGS.PHONE);
          } else {
            return true;
          }
        }),
      email: Yup.string()
        .trim()
        .min(2, STRINGS.EMAIL_TOO_SHORT)
        .max(50, STRINGS.EMAIL_TOO_LONG)
        .email(STRINGS.INVALID_EMAIL_ADDRESS)
        .required(STRINGS.USER_EMAIL_IS_REQUIRED),
    }),
  });

/**
 * Handles the subdomain redirect logic after company creation/update.
 *
 * The logic first checks if subdomain redirect is enabled in the config and if the current subdomain is different from the one
 * provided in the data. If both conditions are true, it will close the native websocket instance and reset the tenant access.
 * Then it will set cookies for the organisation data, origin and redirect domain and redirect the user to the new subdomain.
 *
 * If the subdomain redirect is enabled but the current subdomain is the same as the one provided in the data, it will simply
 * redirect the user to the dashboard.
 *
 * @param {object} data - The company data object
 * @param {object} config - The config object
 * @param {boolean} isSystemAdmin - A flag indicating if the user is a system admin
 * @param {object} selectedOrganisation - The selected organisation object
 * @param {object} nativeWebsocketInstance - The native websocket instance
 */
export const handlePTA = (data, config, isSystemAdmin, selectedOrganisation, nativeWebsocketInstance) => {
  const host = window.location.host;
  const currentSubdomain = host.split('.')[0];
  if (
    config?.subdomainRedirectEnabled &&
    data?.domain &&
    data?.domain !== currentSubdomain &&
    isSystemAdmin &&
    !selectedOrganisation.pk
  ) {
    if (nativeWebsocketInstance) nativeWebsocketInstance.close();
    resetTenantAccess();
    const authToken = getAuthToken();
    deleteCookie('organisationData');
    deleteCookie('BAorigin');
    deleteCookie('redirectDomain');
    const domain = host.substring(
      0,
      host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
    );
    setCookie('organisationData', JSON.stringify(data), 1, domain);
    setCookie('BAorigin', window.origin, 1, domain);
    setCookie('redirectDomain', 'yes', 1, domain);
    window.location = `${window.location.protocol}//${data.domain}.${domain}${
      window.location.port ? `:${window.location.port}` : ''
    }/login?access_token=${authToken}&handlePta=yes`;
  } else if (
    config?.subdomainRedirectEnabled &&
    data?.domain &&
    data?.domain !== currentSubdomain &&
    isSystemAdmin &&
    selectedOrganisation.pk
  ) {
    const authToken = getAuthToken();
    deleteCookie('organisationData');
    deleteCookie('BAorigin');
    deleteCookie('redirectDomain');
    const domain = host.substring(
      host.indexOf('.') + 1,
      host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
    );

    setCookie('organisationData', JSON.stringify(data), 1, domain);
    setCookie('BAorigin', window.origin, 1, domain);
    setCookie('redirectDomain', 'yes', 1, domain);
    window.location = `${window.location.protocol}//${data.domain}.${domain}${
      window.location.port ? `:${window.location.port}` : ''
    }/login?access_token=${authToken}&redirectDomain=yes`;
  } else window.location.href = REDIRECT_ROUTES.DASHBOARD;
};
