import React, { useMemo } from 'react';
import { Box, Text, Heading } from 'grommet';
import { MailOption, Chat } from 'grommet-icons';
import PropTypes from 'prop-types';
import { STRINGS } from 'common/TwoFALayer/strings';
import { StyledBox } from 'common/TwoFALayer/styledComponents/styles';
import { activeTypeColor, inactiveTypeColor, offWhiteColor } from 'common/TwoFALayer/styledComponents/common';

const SelectFAType = ({ values, setFieldValue, setOtpSent, setPhoneNotExist, phoneNotExist, textWarningColor }) => {
  const types = useMemo(
    () => [
      {
        type: 'email',
        heading: 'Use Email',
        subheading: STRINGS.SECURITY_CODES_EMAIL,
        onClick: () => {
          setFieldValue('type', 'email');
          setOtpSent(false);
          setPhoneNotExist(false);
        },
        icon: <MailOption size="large" />,
      },
      {
        type: 'sms',
        heading: 'Use SMS',
        subheading: STRINGS.SECURITY_CODES_PHONE,
        onClick: () => {
          setFieldValue('type', 'sms');
          setOtpSent(false);
        },
        icon: <Chat size="large" />,
      },
    ],
    [setFieldValue, setOtpSent],
  );

  return (
    <>
      <Box fill="horizontal" justify="center" align="center">
        <Heading level={5} weight={600} style={{ maxWidth: '100%' }}>
          {STRINGS.SECURITY_CODES_PREFERENCE_QUESTION}
        </Heading>
      </Box>
      <Box direction="row" gap="medium" fill="horizontal" justify="center">
        {types?.map(item => {
          return (
            <StyledBox
              // showBoxShadow={showBoxShadow}
              border={{
                color: values?.type === item?.type ? activeTypeColor : inactiveTypeColor,
                side: 'all',
                size: values?.type === item?.type ? 'medium' : 'small',
              }}
              key={item.type}
              width="medium"
              height="medium"
              round="small"
              // pad={{ vertical: 'medium' }}
              wrap={true}
              margin={{ bottom: 'small' }}
              onClick={item?.onClick}
              focusIndicator={false}
              background={values?.type === item?.type ? offWhiteColor : 'bgColor'}
              justify="center"
            >
              <Box align="center" justify="center">
                {item?.icon}
                <Box pad={{ vertical: 'medium', horizontal: 'medium' }} fill={true} align="center" justify="center">
                  <Heading level={5} weight={600}>
                    {item?.heading}
                  </Heading>
                  <Text textAlign="center" weight={500} size="large">
                    {item?.subheading}
                  </Text>
                </Box>
              </Box>
            </StyledBox>
          );
        })}
      </Box>
      {phoneNotExist && <Text color={textWarningColor}>{STRINGS.NO_PHONE_NUMBER_WARNING} </Text>}
    </>
  );
};

SelectFAType.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setOtpSent: PropTypes.func,
  setPhoneNotExist: PropTypes.func,
  phoneNotExist: PropTypes.bool,
  textWarningColor: PropTypes.string,
};

export default SelectFAType;
