// Lib imports
import React, { useContext } from 'react';
import { Box, TextInput, Button, Text, ThemeContext, Grid } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

// Core imports
import FormField from 'granite-admin/core/components/FormField';
import PhoneInput from 'granite-admin/core/components/PhoneInput/v2';
import Select from 'granite-admin/core/components/Select';
import ONBOARDING_MESSAGES from 'granite-admin/messages/onBoarding';
import useRouteChange from 'granite-admin/utils/useRouteChange';
import { env } from 'granite-admin/env';

// Application imports
import { createOrganizationOnListing, createOrganisation } from 'onBoarding/controllers/onBoading';
import { validationSchema } from 'pages/onBoarding/helpers/onBoardingHelpers';
import { StyledLinkBox } from 'pages/onBoarding/styledComponents/elements';
import { phoneDefaultValue } from 'common/helpers/constants';

const LoginForm = ({ eventEmitter, companyTypesList, userProfile }) => {
  const isSystemAdmin = userProfile.isSystemAdmin;

  const handleBackClick = () => window.history.back();
  const { prefillEmail = true } = useContext(ThemeContext) ?? {};
  useRouteChange({});

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    if (values.isSystemAdmin) createOrganizationOnListing({ eventEmitter, values });
    else createOrganisation({ eventEmitter, values });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: '',
        firstName: '',
        lastName: '',
        phone: phoneDefaultValue,
        email: prefillEmail ? userProfile.email : '',
        industry: '',
        domain: '',
        isSystemAdmin: isSystemAdmin,
      }}
      validationSchema={validationSchema(isSystemAdmin)}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        isValid,
        dirty,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {isSystemAdmin && (
              <>
                <Box margin={{ top: '20px' }}>
                  <Text size="large">Enter User Details</Text>
                </Box>
                <FormField name="name" label={ONBOARDING_MESSAGES.NAME_LABEL} error={touched.name && errors.name}>
                  <TextInput name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                </FormField>
                <Grid columns={['1/2', '1/2']} gap="small">
                  <FormField
                    name="firstName"
                    basis="90%"
                    label="First Name*"
                    error={touched.firstName && errors.firstName}
                  >
                    <TextInput name="firstName" onChange={handleChange} onBlur={handleBlur} value={values.firstName} />
                  </FormField>
                  <FormField name="lastName" basis="90%" label="Last Name*" error={touched.lastName && errors.lastName}>
                    <TextInput name="lastName" onChange={handleChange} onBlur={handleBlur} value={values.lastName} />
                  </FormField>
                </Grid>
                <Grid columns={['1/2', '1/2']} gap="small">
                  <FormField basis="90%" name="phone" label="Phone Number*" error={touched.phone && errors.phone}>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                      }}
                      onChange={phone => setFieldValue('phone', { ...phone })}
                      onBlur={handleBlur}
                      value={values.phone}
                      alwaysDefaultMask
                    />
                  </FormField>
                  <FormField basis="90%" name="email" label="Email Address*" error={touched.email && errors.email}>
                    <TextInput name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                  </FormField>
                </Grid>
                {(!env.REACT_APP_COMPANY_TYPE || !process.env.REACT_APP_COMPANY_TYPE) && (
                  <Grid columns={['1/2', '1/2']} gap="small">
                    <FormField
                      basis="90%"
                      name="industry"
                      label={ONBOARDING_MESSAGES.COMPANY_TYPE_LABEL}
                      error={touched.industry && errors.industry}
                    >
                      <Select
                        options={companyTypesList}
                        name="industry"
                        value={values.industry}
                        onChange={({ value }) => {
                          setFieldValue('industry', value);
                          if (!touched.industry) setFieldTouched('industry', true, false);
                        }}
                        onBlur={e => !touched.industry && setFieldTouched('industry', true)}
                      />
                    </FormField>
                    <FormField
                      basis="90%"
                      name="domain"
                      label={ONBOARDING_MESSAGES.DOMAIN_LABEL}
                      error={touched.domain && errors.domain}
                    >
                      <TextInput
                        name="domain"
                        onChange={e => {
                          setFieldValue('domain', e?.target?.value.replace(/-+/g, '-'));
                          setFieldTouched('domain', true);
                        }}
                        onBlur={() => !touched.domain && setFieldTouched('domain', true)}
                        value={values.domain}
                      />
                    </FormField>
                  </Grid>
                )}
              </>
            )}
            <Box direction="row" align="center" justify="between" pad={{ vertical: 'small', right: 'medium' }}>
              <Button
                type="submit"
                alignSelf="start"
                margin={{ vertical: 'medium' }}
                label={
                  isSubmitting
                    ? ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                    : ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting || !(isValid && dirty)}
                primary
                color="secondary"
              />
              <StyledLinkBox focusIndicator={false} onClick={handleBackClick} direction="row" gap="small">
                <LinkPrevious size="small" />
                <Text>Back</Text>
              </StyledLinkBox>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  eventEmitter: PropTypes.object,
  onSubmit: PropTypes.func,
  eventEmitter: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  userProfile: PropTypes.object,
  companyTypesList: PropTypes.array,
  dispatch: PropTypes.func,
};

export default LoginForm;
