import { checkUserAgent } from 'granite-admin/utils/functions';

const appleStore = process.env.REACT_APP_APPLE_STORE_URL || '';
const androidStore = process.env.REACT_APP_ANDROID_STORE_URL || '';

/**
 * Handles deep linking to the app based on the user's platform (iOS, Android, or other).
 * - For iOS, it attempts to open the app using a custom URL scheme and falls back to the App Store if the app isn't installed.
 * - For Android, it attempts to open the app using an Android intent and falls back to the Play Store if the app isn't installed.
 * - For other platforms, it navigates to the login page.
 *
 * @param {Function} navigate - The navigation function used to redirect users if they are not on iOS or Android.
 */
export const deepLinkApp = navigate => {
  const agent = checkUserAgent();

  if (agent === 'IOS') {
    window.location = `rollcallparent://parent/login`;

    setTimeout(function () {
      if (!document.hidden) {
        window.location.href = appleStore;
      }
    }, 2000);
  } else if (agent === 'ANDROID') {
    window.location = `intent://${window.location.host}/parent/login#Intent;scheme=https;package=com.rollcallparentapp;end`;

    setTimeout(function () {
      if (!document.hidden) {
        window.location.href = androidStore;
      }
    }, 2000);
  } else {
    navigate(window?.location?.pathname?.includes('/parent') ? '/parent/login' : '/login');
  }
};
