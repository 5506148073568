import React from 'react';
import { useDispatch } from 'react-redux';

import useQuery from 'granite-admin/utils/useQuery';
import { setAuthToken, setTenantAccess, setMyOrganisations } from 'granite-admin/utils/auth-singleton';
import { getCookie } from 'granite-admin/utils/storage';
// import { deleteAllCookies, mainDomain } from 'utils/miscellaneous';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { getMyProfile } from 'accounts/controllers/user';

const RedirecOperational = () => {
  const { query, navigate } = useQuery();
  const dispatch = useDispatch();
  const { defaultThemeConfigs, getSavedThemeConfigs } = useThemeConfigs();

  if (query.redirectedOperational && query.redirectedOperational === 'yes') {
    const org = JSON.parse(getCookie('organisationData'));
    // deleteAllCookies(mainDomain);
    setMyOrganisations(org);
    setAuthToken(query.access_token);
    if (query?.tenantAccess) setTenantAccess(query.tenantAccess);
    else setTenantAccess(false);
    dispatch({
      type: 'organisations/UPDATE_SELECTED_ORGANISATION',
      data: org,
    });
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: [org],
    });
    dispatch({
      type: 'accounts/sidebar/RESET_SIDEBAR',
      data: [],
    });
    setTimeout(() => {
      navigate('/operational-dashboard?dontMatch=yes');
    }, 0);
    // navigate('/operational-dashboard');
    // window.location.replace('/operational-dashboard');
  } else if (query.handlePta === 'yes') {
    const org = JSON.parse(getCookie('organisationData'));
    // deleteAllCookies(mainDomain);
    setMyOrganisations(org);
    setAuthToken(query.access_token);
    if (query?.tenantAccess) setTenantAccess(query.tenantAccess);
    else setTenantAccess(false);
    getMyProfile().then(response => {
      dispatch({
        type: 'accounts/user/UPDATE_PROFILE',
        data: response,
      });
    });

    dispatch({
      type: 'organisations/UPDATE_SELECTED_ORGANISATION',
      data: org,
    });
    dispatch({
      type: 'organisations/UPDATE_ORGANISATIONS',
      data: [org],
    });
    dispatch({
      type: 'accounts/sidebar/RESET_SIDEBAR',
      data: [],
    });
    dispatch({
      type: 'common/themeConfigs/UPDATE_THEME_CONFIGS',
      data: defaultThemeConfigs,
    });
    navigate('/operational-dashboard?dontMatch=yes');
    getSavedThemeConfigs();
  }
  return <></>;
};

export default RedirecOperational;
