import styled from 'styled-components';
import { Box } from 'grommet';

export const LoaderBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
