export const ERROR_MSG = {
  FAILED_TO_FETCH_COMPANY_TYPES: 'Failed to fetch company types',
};

export const VALIDATION_MESSAGES = {
  COMPANY_NAME_REQUIRED: 'Company Name is required',
  COMPANY_TYPE_REQUIRED: 'Company Type is required',
  INVALID_DOMAIN_NAME: 'Invalid domain name',
  USER_FIRST_NAME_REQUIRED: 'User First Name is require',
  USER_LAST_NAME_REQUIRED: 'User Last Name is require',
  PHONE_NAME_REQUIRED: 'Phone Number is required',
  INVALID_PHONE_NUMBER: 'Invalid phone number',
  PHONE: 'phone',
  EMAIL_TOO_SHORT: 'Email too short',
  EMAIL_TOO_LONG: 'Email too long',
  INVALID_EMAIL_ADDRESS: 'Invalid email address',
  USER_EMAIL_IS_REQUIRED: 'User Email is required',
};

export const STRINGS = {
  TELL_US_ABOUT_YOUR_COMPANY: 'Tell Us About Your Company',
  ROLLCALL: 'RollCall',
  ORGANISATIONS_UPDATE_ORGANISATIONS: 'organisations/UPDATE_ORGANISATIONS',
  ORGANISATIONS_UPDATE_SELECTED_ORGANISATIONS: 'organisations/UPDATE_SELECTED_ORGANISATION',
};
