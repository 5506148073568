// Lib imports
import React, { useState, useCallback } from 'react';
import { Box, Text, Layer } from 'grommet';
import PropTypes from 'prop-types';
import Divider from 'granite-admin/core/components/Divider';
import ButtonGroup from 'granite-admin/core/components/ButtonGroup';
import {
  setAuthToken,
  setTenantAccess,
  setOrganisation,
  resetAuthToken,
  resetSsoLogin,
} from 'granite-admin/utils/auth-singleton';
import { groupOptions, groupOptions2 } from 'pages/adminLogin/helpers/constants';
import { STRINGS } from 'pages/adminLogin/strings';
import { optionProps } from 'pages/adminLogin/styledComponents/styles';
// import { removeAuthToken } from 'granite-admin/accounts/controllers/sidebar';

const LoginAsLayer = ({ closeLayer, goToDashboard, loginAsLayer }) => {
  resetAuthToken(false);
  resetSsoLogin();
  const [value, setValue] = useState(null);

  const handleOnChange = useCallback(
    value => {
      setValue(value);

      if (value === 'BA' || value === 'RA') {
        setTenantAccess(false);
      } else {
        setTenantAccess(true);
      }

      if (value === 'RA') {
        setOrganisation(loginAsLayer.mappedOrgId);
      }

      if (loginAsLayer.URL && value !== 'BA') {
        let URL = `${loginAsLayer.URL}&tenantAccess=true`;
        // localStorage.clear();
        window.location = URL;
      } else {
        setAuthToken(loginAsLayer.authToken);
        goToDashboard();
      }
    },
    [loginAsLayer, setValue, setTenantAccess, setOrganisation, setAuthToken, goToDashboard],
  );

  return (
    <Layer position="center" background="full-screen-background-color" onClickOutside={closeLayer} onEsc={closeLayer}>
      <Box pad="small" flex={false}>
        <Text size="xlarge" weight="bold">
          {STRINGS.LOGIN_AS_TEXT}
        </Text>
      </Box>
      <Divider color="brand" />
      <Box flex={{ shrink: 0 }} pad="medium" width="100%" height={{ min: '60px' }} align="center">
        <ButtonGroup
          name="formName"
          options={loginAsLayer?.URL ? groupOptions : groupOptions2}
          value={value}
          onChange={handleOnChange}
          hoverBackground="brand"
          gap="small"
          optionProps={optionProps}
        />
      </Box>
    </Layer>
  );
};

LoginAsLayer.propTypes = {
  successToast: PropTypes.func,
  closeLayer: PropTypes.func,
  goToDashboard: PropTypes.func,
  loginAsLayer: PropTypes.object,
};

export default LoginAsLayer;
