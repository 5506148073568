import React from 'react';
import { Box, Button, Text } from 'grommet';
import FormField from 'granite-admin/core/components/FormField';
import { Formik } from 'formik';

import PasswordInput from 'granite-admin/core/components/PasswordInput';
import { STRINGS } from 'pages/adminReset/strings';
import { lightOrangeColor, skyBlueColor } from 'pages/adminReset/styledComponents/colors';
import { initialValues } from 'pages/adminReset/helpers/constants';
import { validationSchema } from 'pages/adminForget/helpers/constants';

const LoginForm = (eventEmitter, loginClicked) => {
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    await loginClicked(eventEmitter, values);
    setSubmitting(false);
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
            <form onSubmit={handleSubmit}>
              <Text as="div" color="status-critical">
                {errors.title}
              </Text>
              <div
                id="password_error_strength"
                className="alert fade in"
                style={{ backgroundColor: lightOrangeColor, color: 'black', fontSize: 'small', padding: '0 5px' }}
              >
                <p style={{ marginBottom: '0' }}>{STRINGS.PASSWORD_REQUIREMENTS}</p>
                <ul style={{ paddingLeft: '18px' }}>
                  <li>
                    <strong>8</strong> {STRINGS.CHARACTERS_IN_LENGTH}
                  </li>
                  <li>
                    A mixture of <strong>Upper</strong> and <strong>Lower</strong> Case
                  </li>
                  <li>
                    At least 1 <strong>Number</strong>{' '}
                  </li>
                  <li>
                    At least 1 <strong>Character</strong> {STRINGS.SPECIAL_CHARACTERS}
                  </li>
                </ul>
              </div>
              <FormField
                name="password"
                label={STRINGS.NEW_PASSWORD}
                required
                error={touched.password && errors.password}
              >
                <PasswordInput name="password" onChange={handleChange} onBlur={handleBlur} value={values.password} />
              </FormField>
              <FormField
                name="confirm_password"
                label={STRINGS.CONFIRM_NEW_PASSWORD}
                required
                error={touched.confirm_password && errors.confirm_password}
              >
                <PasswordInput
                  name="confirm_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password}
                />
              </FormField>
              <Box>
                <Button
                  type="submit"
                  style={{ width: '50%', padding: '14px 0', fontSize: 'large' }}
                  alignSelf="center"
                  // size='xxlarge'
                  margin={{ top: 'medium' }}
                  label={isSubmitting ? STRINGS.SUBMIT_BUTTON_LABEL_SUBMITTING : STRINGS.RESET_PASSWORD_LABEL}
                  disabled={isSubmitting}
                  primary
                  color={skyBlueColor}
                />
              </Box>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};
export default LoginForm;
