// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';
import queryString from 'query-string';

// Application Imports
import GoToRoute from '../entities/gotoRouteEntity';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;

async function getAllRoutes(domain) {
  const pURL = queryString.parseUrl(url + 'route-stop-iframe/');
  const URL = queryString.stringifyUrl({ url: pURL.url, query: { domain: domain, show_in_iframe: true } });
  const { data } = await GraniteAPIClient(URL).get();
  const AmRouteList = data ? data.AM?.map(item => new GoToRoute(item)) : [];
  const PmRouteList = data ? data.PM?.map(item => new GoToRoute(item)) : [];
  return [AmRouteList, PmRouteList];
}

export default getAllRoutes;
