// Lib imports
import { Box } from 'grommet';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  position: relative;
  &:after {
    content: '';
    background-color: ${props => props?.theme?.global?.colors?.whiteColor};
    position: absolute;
    width: 2px;
    height: 24px;
    left: 50%;
    top: calc(50% + 1px);
    transform: translateX(-50%);
    display: block;
  }
`;

export const StyledLinkBox = styled(Box)`
  align-items: center;
`;
