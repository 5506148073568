class GotoRoute {
  constructor(data) {
    this.capacity = data?.route?.capacity;
    this.name = data?.route?.name;
    this.organisationId = data?.route?.organisation_id;
    this.pk = data?.route?.pk;
    this.routeColor = data?.route?.route_color || 'black';
    this.routeGroup = data?.route?.route_group;
    this.displayStopTimes = data?.route?.display_stop_times_dynamically;
    this.stop = data?.stop?.map((x, index) => {
      return {
        activeInSmartRoute: x?.active_in_smart_route,
        address: x?.address,
        latitude: x?.lat,
        longitude: x?.long,
        name: x?.name,
        pk: x?.pk,
        geofenceCoordinates: x?.geofence_coordinates,
        geofenceSize: x?.geofence_size,
        routeName: data?.route.name,
        color: data?.route.route_color || 'black',
        sequence: x?.sequence,
        zone: x?.zone,
        homeCampusPk: x?.home_campus_pk,
        isCampus: x?.campus_exist === 'yes' ? true : false,
        pinHighlight: false,
        expectedArrivalTime: x?.expected_arrival_time !== null ? x?.expected_arrival_time : '',
        expectedDepartureTime: x?.expected_departure_time !== null ? x?.expected_departure_time : '',
      };
    });
  }
}

export default GotoRoute;
