export const borderStyles = [
  {
    side: 'horizontal',
    color: 'brand',
    size: 'large',
    style: 'inset',
  },
];

export const ssoLabelColor = '#1c2b36';
