// Lib imports
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'grommet';
import { useDispatch } from 'react-redux';

// Core imports
import { setAuthToken, setTenantAccess, getAuthToken } from 'granite-admin/utils/auth-singleton';
import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import SplitLayout from 'granite-admin/core/components/SplitLayout';

// Application imports
import { loginClicked } from 'granite-admin/accounts/controllers/user';
import LoginForm from 'granite-admin/pages/login/components/LoginForm';
import useQuery from 'granite-admin/utils/useQuery';
import { mainDomain } from 'utils/miscellaneous';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';
import { deleteCookie } from 'granite-admin/utils/storage';

import LoginAsLayer from './components/LoginLayer';
import { LOGIN_EVENTS } from 'accounts/controllers/events';

import customLogo from 'assets/logo.png';
import {
  deepLinkApp,
  handleBackToBoardRedirect,
  handleDomainRedirect,
  handlePtaRedirect,
} from './helpers/adminLoginHelpers';
import { projectAccountMsg } from './helpers/constants';
import { STRINGS, STRING_URLS } from './strings';
import { LoaderBox } from 'common/styledComponents/LoaderBox';

import LOGIN_MESSAGES from 'granite-admin/messages/login';
// import { checkMobileDevice } from 'utils/miscellaneous';

const Login = ({ sideContent, bannerPadding }) => {
  const { query, navigate } = useQuery();
  const { errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const [organisation, setOrganisation] = useState([]);
  const config = useContext(ConfigContext);
  const theme = useContext(ThemeContext);
  const { SideContent } = config?.sideContentSettings || '';
  const { sideProps, logoWidth, sideWidth, configMainPropsLogin } = theme?.sideContentSettings || '';
  const dispatch = useDispatch();
  const [loginAsLayer, setLoginAsLayer] = useState(false);
  const { defaultThemeConfigs, getSavedThemeConfigs } = useThemeConfigs();
  const loader = query.redirectDomain || query.tokenLogin || (query.access_token && query.access_token !== 'none');

  useEffect(() => {
    if (!query || (query.handlePta !== 'yes' && query.redirectDomain !== 'yes')) {
      deleteCookie('organisationData', mainDomain);
      deleteCookie('BAorigin', mainDomain);
      deleteCookie('redirectDomain', mainDomain);
      // deleteAllCookies(mainDomain);
    }

    if (query && query.tokenLogin === 'yes' && query.access_token) {
      setTenantAccess(query.tenantAccess || false);
      setAuthToken(query.access_token);
      navigate(STRING_URLS.DASHBOARD);
    } else if (query && query.redirectDomain === 'yes' && query.access_token) {
      handleDomainRedirect(query, dispatch, navigate, query.route);
    } else if (query && query.backToBoard === 'yes' && query.access_token) {
      handleBackToBoardRedirect(query, dispatch, navigate);
    } else if (query && query.handlePta === 'yes' && query.access_token) {
      handlePtaRedirect(query, dispatch, navigate, defaultThemeConfigs, getSavedThemeConfigs, query.route);
    } else if (query && query?.access_token?.toLowerCase() === 'none' && query?.error) {
      errorToast(query.error || STRINGS.LOGIN_FAILURE_MESSAGE);
    } else if (query && query.access_token) deepLinkApp(query.access_token, navigate, query.organisation);
  }, [defaultThemeConfigs, dispatch, errorToast, getSavedThemeConfigs, navigate, query]);

  const goToDashboard = useCallback(() => navigate(STRING_URLS.DASHBOARD), [navigate]);
  const goToOtp = useCallback(
    eventData =>
      navigate(STRING_URLS.OTP_SUBMIT, {
        state: eventData,
      }),
    [navigate],
  );

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          if (event.data) {
            goToOtp(event.data);
          } else {
            goToDashboard();
          }
          break;
        case LOGIN_EVENTS.LOGIN_FAILURE:
          setOrganisation(event?.data?.organisations);
          errorToast(event.data?.title || LOGIN_MESSAGES.LOGIN_FAILURE);
          break;
        case LOGIN_EVENTS.USER_NOT_EXIST:
          errorToast(LOGIN_MESSAGES.USER_NOT_EXIST);
          break;
        case LOGIN_EVENTS.ONLY_PARENT_LOGIN:
          errorToast(STRINGS.ONLY_PARENT_LOGIN_MESSAGE);
          break;
        case LOGIN_EVENTS.ONLY_BA_ACCESSING_WRONG_ORG:
          errorToast(STRINGS.USER_DOES_NOT_BELONG_MESSAGE);
          break;
        case LOGIN_EVENTS.BA_CAN_BE_EMPLOYEE: {
          const auth = getAuthToken();
          setLoginAsLayer({ authToken: auth, URL: event.data?.url, mappedOrgId: event.data?.orgId });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, goToDashboard, goToOtp, errorToast]);
  const closeLayer = useCallback(() => {
    setLoginAsLayer(false);
    removeAuthToken();
  }, [setLoginAsLayer, removeAuthToken]);

  return (
    <>
      {loader ? (
        <LoaderBox />
      ) : (
        <SplitLayout
          mainContent={
            <LoginForm
              loginClicked={loginClicked}
              organisation={organisation}
              setOrganisation={setOrganisation}
              eventEmitter={eventEmitter}
              config={config}
              msg={projectAccountMsg}
            />
          }
          sideContent={SideContent ? <SideContent /> : sideContent}
          bannerPadding={bannerPadding}
          sideProps={sideProps}
          logoWidth={logoWidth}
          // formPad={formPad}
          sideWidth={sideWidth}
          mainProps={configMainPropsLogin ?? { style: { marginTop: '3%' } }}
          customLogo={customLogo}
        />
      )}
      {loginAsLayer && (
        <LoginAsLayer closeLayer={closeLayer} goToDashboard={goToDashboard} loginAsLayer={loginAsLayer} />
      )}
    </>
  );
};

Login.defaultProps = {
  bannerPadding: 'large',
  sideContent: null,
};

Login.propTypes = {
  bannerPadding: PropTypes.string,
  sideContent: PropTypes.node,
};

export default Login;
