import * as Yup from 'yup';
import LOGIN_MESSAGES from 'granite-admin/messages/login';

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
    .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
    .email(LOGIN_MESSAGES.EMAIL_INVALID)
    .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
  password: Yup.string()
    .min(6, LOGIN_MESSAGES.PASS_TOO_SHORT)
    .max(50, LOGIN_MESSAGES.PASS_TOO_LONG)
    .required(LOGIN_MESSAGES.PASS_REQUIRED),
});

export const parentMainUrl = process.env.REACT_APP_PARENT_MAIN_URL;
export const konectSsoUrl = process.env.REACT_APP_KONECT_SSO_URL;
export const initialValues = {
  email: '',
  password: '',
  screen: 'parent',
};
