// Lib Imports
import React, { useCallback, useEffect } from 'react';
import { Anchor, Box, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LoaderBox } from 'common/styledComponents/LoaderBox';
import { STRINGS, STRING_URLS } from 'pages/forgotpassword/strings'; // Assuming the strings are imported from constants

const CheckUserStatus = ({ query, brandColor, setActiveStep, reqUserStatus, checkStatus, setLoader, loader }) => {
  const { token = '' } = query ?? {};

  const clickedToActiveStep = useCallback(() => setActiveStep(3), [setActiveStep]);

  useEffect(() => {
    if (token) {
      setLoader(true);
      reqUserStatus(token);
    }
  }, [setLoader, token]);

  return loader ? (
    <LoaderBox />
  ) : (
    <>
      <Paragraph size="16px" margin="0">
        {checkStatus?.isValid ? (
          <>{STRINGS.ACCOUNT_ALREADY_ACTIVE.replace('{email}', checkStatus?.user_email || '')}</>
        ) : (
          <>{STRINGS.INVALID_LINK}</>
        )}
      </Paragraph>
      {checkStatus?.isValid && (
        <Box
          fill="horizontal"
          direction="row-responsive"
          elevation="none"
          justify="between"
          align="center"
          pad="none"
          margin={{ top: 'small' }}
        >
          <Link
            to={
              window?.location?.pathname?.includes('/parent')
                ? `${STRING_URLS.PARENT_FORGOT_PASSWORD}?token=${token}`
                : `${STRING_URLS.FORGOT_PASSWORD}?token=${token}`
            }
            onClick={clickedToActiveStep}
          >
            <Anchor as="span" label={STRINGS.RESET_PASSWORD} size="medium" color={brandColor || 'secondary'} />
          </Link>
          <Link
            to={
              window?.location?.pathname?.includes(STRING_URLS.PARENT_HOME)
                ? STRING_URLS.PARENT_LOGIN
                : STRING_URLS.LOGIN
            }
          >
            <Anchor
              as="span"
              label={STRINGS.GO_TO_LOGIN}
              size="xlarge"
              margin={{ vertical: 'medium' }}
              color={brandColor || 'secondary'}
            />
          </Link>
        </Box>
      )}
    </>
  );
};

CheckUserStatus.propTypes = {
  query: PropTypes.any,
  brandColor: PropTypes.any,
  setActiveStep: PropTypes.func,
  reqUserStatus: PropTypes.func,
  checkStatus: PropTypes.object,
  setLoader: PropTypes.func,
  loader: PropTypes.string,
};

export default CheckUserStatus;
