import React from 'react';
import { Box, Text } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { FormDown } from 'grommet-icons';
import PropTypes from 'prop-types';
import 'common/TopNavbar/index.css';
import { StyledMenu } from 'common/TopNavbar/styledComponents/styles';

const LeftItems = ({ subItems, label, isActive, activeColor, theme }) => {
  const navigate = useNavigate();

  return (
    <Box justify="center">
      <StyledMenu
        plain
        dropProps={{
          align: { top: 'bottom', left: 'left' },
          elevation: 'xlarge',
        }}
        items={subItems.map(i => ({
          className: 'LeftNavItem',
          label: i.label,
          gap: 'small',
          align: 'center',
          onClick: () => navigate('/' + i.path),
        }))}
      >
        <Box
          direction="row"
          pad="0.5rem"
          border={isActive ? { side: 'bottom', color: activeColor } : null}
          flex={{ shrink: 0 }}
          align="center"
          gap="small"
        >
          <Text>{label}</Text>
          <FormDown size="small" color={theme.global.colors['brand']} />
        </Box>
      </StyledMenu>
    </Box>
  );
};

LeftItems.propTypes = {
  subItems: PropTypes.array,
  label: PropTypes.string,
  icon: PropTypes.any,
  isActive: PropTypes.bool,
  activeColor: PropTypes.string,
  theme: PropTypes.object,
};

export default LeftItems;
