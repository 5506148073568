import React from 'react';
import { Card, Heading } from 'grommet';
import LoginForm from './components/loginForm';
import Divider from 'granite-admin/core/components/Divider';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { STRINGS } from './strings';

const eventEmitter = new EventEmitter();

const AdminReset = () => {
  return (
    <Card pad="xxsmall" width="large" alignSelf="center" style={{ margin: '120px' }}>
      <Heading color="green" alignSelf="center" level={4}>
        {STRINGS.SET_PASSWORD_LABEL}
      </Heading>
      <Divider color="black" />
      <LoginForm eventEmitter={eventEmitter} />
    </Card>
  );
};

export default AdminReset;
