import { deleteCookie } from 'granite-admin/utils/storage';
import { getCookie } from 'granite-admin/utils/storage';
import { mainDomain } from 'utils/miscellaneous';
import { env } from 'granite-admin/env';
import { getMyProfile } from 'accounts/controllers/user';

import {
  setAuthToken,
  setSsoLogin,
  setMyOrganisations,
  getAuthToken,
  setTenantAccess,
  // resetAuthToken,
  resetMyOrganisations,
  setOrganisation,
  getTenantAccess,
} from 'granite-admin/utils/auth-singleton';
import { checkUserAgent } from 'granite-admin/utils/functions';

const sso_ios = env.REACT_APP_SSO_IOS_URL || process.env.REACT_APP_SSO_IOS_URL || '';
const appleStore = env.REACT_APP_APPLE_STORE_URL || process.env.REACT_APP_APPLE_STORE_URL || '';
const sso_android = env.REACT_APP_SSO_ANDROID_URL || process.env.REACT_APP_SSO_ANDROID_URL || '';
const android_package = env.REACT_APP_ANDROID_PACKAGE || process.env.REACT_APP_ANDROID_PACKAGE || '';
/**
 * Handles domain redirection by processing organisation data from cookies,
 * setting the auth token, updating the organisation state, and navigating
 * to a specified route or defaulting to the dashboard.
 *
 * @param {Object} query - Contains access_token and tenantAccess.
 * @param {Function} dispatch - Dispatches actions to the store.
 * @param {Function} navigate - Navigates to different routes.
 * @param {string} route - Optional route to navigate to.
 */
export const handleDomainRedirect = (query, dispatch, navigate, route) => {
  const org = JSON.parse(getCookie('organisationData'));
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  setMyOrganisations(org);
  setTenantAccess(query.tenantAccess || false);
  dispatch({ type: 'organisations/UPDATE_SELECTED_ORGANISATION', data: org });
  dispatch({ type: 'organisations/UPDATE_ORGANISATIONS', data: [org] });
  dispatch({ type: 'accounts/sidebar/RESET_SIDEBAR', data: [] });

  setTimeout(() => {
    navigate(route ? '/' + route : '/dashboard');
  }, 0);
};

/**
 * Handles deep linking based on the user's device:
 * - iOS: Redirects to an SSO URL and opens the App Store if not redirected.
 * - Android: Redirects to an SSO URL with token and package.
 * - Other: Sets auth token and navigates to the dashboard.
 *
 * @param {string} token - The authentication token.
 * @param {Function} navigate - Navigates to different routes.
 * @param {string} orgId - Optional organisation ID.
 */
export const deepLinkApp = (token, navigate, orgId) => {
  const agent = checkUserAgent();
  if (agent === 'IOS') {
    window.location.href = `${sso_ios}${token}`;
    setTimeout(() => {
      if (!document.hidden) window.location.href = appleStore;
    }, 5000);
  } else if (agent === 'ANDROID') {
    window.location.href = `${sso_android}${token}${android_package}`;
  } else {
    setAuthToken(token);
    setSsoLogin();
    if (orgId) {
      setOrganisation(orgId);
      setTenantAccess(true);
    }
    navigate('/dashboard');
  }
};

/**
 * Handles redirection back to the board by clearing specific cookies,
 * setting the auth token, managing tenant access, and dispatching
 * actions to reset workspace and organisation state.
 *
 * @param {Object} query - Contains access_token and tenantAccess.
 * @param {Function} dispatch - Dispatches actions to the store.
 * @param {Function} navigate - Navigates to different routes.
 */
export const handleBackToBoardRedirect = (query, dispatch, navigate) => {
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  setTenantAccess(query.tenantAccess || false);

  dispatch({ type: 'accounts/workspace/RESET_WORKSPACE' });
  dispatch({ type: 'organisations/UPDATE_SELECTED_ORGANISATION', data: {} });
  dispatch({ type: 'organisations/UPDATE_ORGANISATIONS', data: null });
  dispatch({ type: 'accounts/sidebar/RESET_SIDEBAR', data: [] });

  resetMyOrganisations();
  if (getTenantAccess()) setTenantAccess(false);

  setTimeout(() => navigate('/dashboard'), 0);
};

/**
 * Handles redirection for PTA by processing organisation data,
 * clearing cookies, setting the auth token, managing tenant access,
 * updating the user profile, and navigating to a specified route
 * or defaulting to the dashboard.
 *
 * @param {Object} query - Contains access_token and tenantAccess.
 * @param {Function} dispatch - Dispatches actions to the store.
 * @param {Function} navigate - Navigates to different routes.
 * @param {Object} defaultThemeConfigs - Default theme configurations.
 * @param {Function} getSavedThemeConfigs - Retrieves saved theme configurations.
 * @param {string} route - Optional route to navigate to.
 */
export const handlePtaRedirect = (query, dispatch, navigate, defaultThemeConfigs, getSavedThemeConfigs, route) => {
  const org = JSON.parse(getCookie('organisationData'));
  deleteCookie('organisationData', mainDomain);
  deleteCookie('BAorigin', mainDomain);
  deleteCookie('redirectDomain', mainDomain);
  // deleteAllCookies(mainDomain);
  setAuthToken(query.access_token);
  setMyOrganisations(org);
  setTenantAccess(query.tenantAccess || false);

  getMyProfile()
    .then(response => {
      dispatch({ type: 'accounts/user/UPDATE_PROFILE', data: response });
    })
    .catch(e => {});

  dispatch({ type: 'organisations/UPDATE_SELECTED_ORGANISATION', data: org });
  dispatch({ type: 'organisations/UPDATE_ORGANISATIONS', data: [org] });
  dispatch({ type: 'accounts/sidebar/RESET_SIDEBAR', data: [] });
  dispatch({ type: 'common/themeConfigs/UPDATE_THEME_CONFIGS', data: defaultThemeConfigs });

  navigate(route ? '/' + route : '/dashboard');
  getSavedThemeConfigs();
};
