import GraniteError from 'granite-admin/utils/granite-error';

/**
 * Handles API calls and emits events based on the result.
 *
 * @param {Function} callback - The asynchronous API function to be called.
 * @param {EventEmitter} eventEmitter - The event emitter for notifying success or failure.
 * @param {string} errorEvent - The event type to emit on failure.
 * @returns {Promise<*>} - The API response if successful, or `undefined` if an error occurs.
 *
 * @throws {GraniteError} - If the API call fails, a `GraniteError` is instantiated and its `errors` property is emitted.
 */
export async function handleApiCall(callback, eventEmitter, errorEvent) {
  try {
    return await callback();
  } catch (error) {
    const err = new GraniteError(error);
    if (eventEmitter?.emit) eventEmitter.emit(errorEvent, err?.errors);
  }
}
