import { useEffect } from 'react';

const useFreshDeskWidget = (authToken, location) => {
  useEffect(() => {
    const script0 = document.createElement('script');
    if (
      authToken &&
      !window.location.pathname?.includes('logout') &&
      (location.pathname === '/parents' || !location.pathname.includes('/parent'))
    ) {
      if (document.getElementById('freshDeskId')) {
        window.showWidget();
      } else {
        const script1 = document.createElement('script');
        const script2 = document.createElement('script');
        script0.id = 'freshDeskId';
        script0.text = `function hideWidget() {
            FreshworksWidget('hide');
            }
            function showWidget() {
              FreshworksWidget('show');
            }
            window.fwSettings={
            'widget_id':51000003880
            };
            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
        script1.src = 'https://aus-widget.freshworks.com/widgets/51000003880.js';
        script1.defer = true;
        script1.type = 'text/javascript';
        script2.setAttribute('type', 'text/javascript');
        document.head.appendChild(script0);
        document.head.appendChild(script1);
        document.head.appendChild(script2);
      }
    } else {
      window.hideWidget && window.hideWidget();
    }
  }, [authToken, location.pathname]);
};

export default useFreshDeskWidget;
