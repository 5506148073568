// Lib Imports
import React from 'react';
import { Box, Heading, Image } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { LinkPrevious } from 'grommet-icons';

// Application Imports
import Activated from 'assets/Activated.svg';
import { STRINGS, STRING_URLS } from 'pages/forgotpassword/strings';

const RequestSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box align="center">
      <Box margin={{ top: 'medium', bottom: 'xlarge' }}>
        <Image src={Activated} />
      </Box>
      <Heading level={3} margin="none">
        {STRINGS.PASSWORD_RESET_SUCCESS}
      </Heading>
      <Heading level={5} color="dark-2">
        {STRINGS.PASSWORD_RESET_LINK_SENT}
      </Heading>
      <Heading level={6}>
        <Box
          focusIndicator={false}
          onClick={() =>
            navigate(
              window?.location?.pathname?.includes(STRING_URLS.PARENT_HOME)
                ? STRING_URLS.PARENT_LOGIN
                : STRING_URLS.LOGIN,
            )
          }
          direction="row"
          gap="small"
          style={{ alignItems: 'center' }}
        >
          <LinkPrevious size="small" /> {STRINGS.BACK_TO_LOGIN}
        </Box>
      </Heading>
    </Box>
  );
};

export default RequestSuccess;
