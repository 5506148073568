export const LOGIN_EVENTS = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  USER_NOT_EXIST: 'USER_NOT_EXIST',
  SSO_SUCCESS: 'SSO_SUCCESS',
  SSO_FAILURE: 'SSO_FAILURE',
  ONLY_PARENT_LOGIN: 'ONLY_PARENT_LOGIN',
  ONLY_BA_ACCESSING_WRONG_ORG: 'ONLY_BA_ACCESSING_WRONG_ORG',
  BA_CAN_BE_EMPLOYEE: 'BA_CAN_BE_EMPLOYEE',
  OTP_FAILURE: 'OTP_FAILURE',
};

export const RESET_EVENTS = {
  RESET_START: 'RESET_START',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',
  RESET_TOKEN_SUCCESS: 'RESET_TOKEN_SUCCESS',
  RESET_TOKEN_FAILURE: 'RESET_TOKEN_FAILURE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  FETCH_USER_STATUS: 'FETCH_USER_STATUS',
  FAILURE_USER_STATUS: 'FAILURE_USER_STATUS',
  FETCH_DUPLICATE_PHONE_STATUS: 'FETCH_DUPLICATE_PHONE_STATUS',
  FAILURE_DUPLICATE_PHONE_STATUS: 'FAILURE_DUPLICATE_PHONE_STATUS',
};

export const USER_PREFERENCES_EVENTS = {
  GET_USER_PREFERENCES_SUCCESS: 'GET_USER_PREFERENCES_SUCCESS',
  GET_USER_PREFERENCES_FAILURE: 'GET_USER_PREFERENCES_FAILURE',
  CHANGE_USER_PREFERENCES_SUCCESS: 'CHANGE_USER_PREFERENCES_SUCCESS',
  CHANGE_USER_PREFERENCES_FAILURE: 'CHANGE_USER_PREFERENCES_FAILURE',
};

export const PROFILE_EVENTS = {
  PROFILE_START: 'PROFILE_START',
  PROFILE_SUCCESS: 'PROFILE_SUCCESS',
  PROFILE_FAILURE: 'PROFILE_FAILURE',
  PROFILE_POST_SUCCESS: 'PROFILE_POST_SUCCESS',
  PROFILE_POST_FAILURE: 'PROFILE_POST_FAILURE',
  IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',
};
