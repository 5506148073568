//Lib imports
import { Table, TableBody, TableCell, TableHeader, AccordionPanel, List, Heading, Text, Box } from 'grommet';
import styled from 'styled-components';

export const customTabs = {
  tab: {
    color: 'text',
    border: {
      hover: { color: 'secondary' },
    },
    active: {
      color: 'secondary',
    },
    pad: 'small',
    margin: { left: 'xsmall' },
    hover: {
      color: 'secondary',
    },
  },
};

export const StyledTable = styled(Table)`
  border: 1px solid ${props => props?.theme?.global?.colors?.borderGreyColor};
`;
export const StyledTableHeader = styled(TableHeader)`
  font-size: small;
  padding: 6px;
  color: ${props => props?.theme?.global?.colors?.blackColor};
`;
export const StyledTableBody = styled(TableBody)`
  font-size: small;
  border-color: ${props => props?.theme?.global?.colors?.borderGreyColor};
`;
export const StyledTableCell = styled(TableCell)`
  padding: 8px 5px;
  border: 1px solid ${props => props?.theme?.global?.colors?.borderGreyColor};
  min-width: ${({ width }) => width || '50px'}; // Use a template literal
`;

export const StyledAccordionPanel = styled(AccordionPanel)`
  background: white;
  & h5 {
    font-size: 14px;
  }
  & svg {
    height: 15px;
    color: ${props => props?.theme?.global?.colors?.labelColor};
  }
`;

export const commonStyle = {
  direction: 'row',
  gap: 'small',
  align: 'center',
  background: '#ff00004d',
  pad: '6px',
};

export const recipientsColor = '#FFA500';
export const availableMsgColor = '#00A300';
export const greyColor = '#86868629';
export const textColor = '#100e0eb5';

export const StyledList = styled(List)`
  & li {
    border: none;
    padding-left: 0;
    padding-right: 0;
    & span {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${textColor};
`;

export const StyledText = styled(Text)`
  font-weight: 600;
  color: ${textColor};
`;

export const StyledRecipientsBox = styled(Box)`
  max-height: 80px;
`;
