export const STRINGS = {
  LOGIN_AS_TEXT: 'Login As',
  LOGIN_FAILURE_MESSAGE: 'Unable to Sign in, please try again!',
  USER_DOES_NOT_BELONG_MESSAGE: 'User not belongs to this organization',
  ONLY_PARENT_LOGIN_MESSAGE:
    'The credentials being used do not belong to an admin, please try logging in to the Parent Portal below',
};

export const STRING_URLS = {
  DASHBOARD: '/dashboard',
  OTP_SUBMIT: '/otp-submit',
};
