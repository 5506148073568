// Lib imports
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, TextInput, Anchor, Button, Text, Heading, Spinner } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Core imports
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import FormField from 'granite-admin/core/components/FormField';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import Select from 'granite-admin/core/components/Select';
import { loginClicked } from 'accounts/controllers/user';
import { useToast } from 'granite-admin/core/components/Toast';
import useQuery from 'granite-admin/utils/useQuery';

import { isSSOBtnVisible } from 'accounts/controllers/user';
import { LOGIN_EVENTS } from 'accounts/controllers/events';

const showPrivacyPolicy = process.env.REACT_APP_SHOW_LOGIN_PRIVACY_POLICY === 'true' ? true : false;
const privacyPolicyLink = process.env.REACT_APP_LOGIN_PRIVACY_POLICY_LINK;
// const registerDisabled = process.env.REACT_APP_REGISTER_DISABLED || false;
const mainUrl = process.env.REACT_APP_MAIN_URL;
const konectSsoUrl = process.env.REACT_APP_KONECT_SSO_URL;
const currentDomain = window.location;
const subdomain = currentDomain.host.split('.')[0] ? currentDomain.host.split('.')[0] : '';
const ssoRedirectUrl = `${konectSsoUrl}?current_url=${currentDomain.origin}/login/&school=${subdomain}`;
const domain = currentDomain.host.substring(
  0,
  currentDomain.host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
);

const getEmailQuery = async inputRef => {
  let value = inputRef?.current?.value;
  if (!value) return '';
  let emailValidity = Yup.string().email();
  let isEmailValid = await emailValidity.isValid(value);
  if (isEmailValid) return `email=${value}`;
  return '';
};

const LoginAdminForm = ({ eventEmitter, organisation, setOrganisation, ssoUrl, config }) => {
  const { errorToast } = useToast();
  const { query } = useQuery();
  const [ssoVisible, setSsoVisible] = useState('');
  const [ssoLabel, setSsoLabel] = useState('SSO Login');
  const [loading, setLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
      .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
      .email(LOGIN_MESSAGES.EMAIL_INVALID)
      .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
    password: Yup.string().required(LOGIN_MESSAGES.PASS_REQUIRED),
    ...(organisation?.length && { organisation_id: Yup.number().required(LOGIN_MESSAGES.ORGANIZATION_ID_REQUIRED) }),
  });

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.SSO_SUCCESS:
          if (event.data?.results?.length) {
            event.data.results.forEach(i => {
              if (i['is_sso_enabled']) setSsoVisible(i['is_sso_enabled']?.value);
              if (i['forgerock_login_text']) setSsoLabel(i['forgerock_login_text']?.value || 'SSO Login');
            });
          }
          setLoading(false);
          break;
        case LOGIN_EVENTS.SSO_FAILURE:
          setSsoVisible('');
          setLoading(false);
          errorToast(event?.data || 'Unable to fetch SSO details');
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast]);

  const fetch = useCallback(async () => {
    if (subdomain && currentDomain.origin !== mainUrl) {
      setSsoVisible(false); // run the spinner until api is in pending state
      await isSSOBtnVisible(eventEmitter, subdomain);
    } else setLoading(false);
  }, [subdomain, currentDomain, eventEmitter, mainUrl]);

  useEffect(() => {
    if (query?.error) errorToast(query?.error.split('/?')[0]);
  }, [query, errorToast]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      await loginClicked(eventEmitter, values, config);
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      }
    }
    setSubmitting(false);
  };

  const inputRef = useRef();
  const navigate = useNavigate();
  const handleForgotPassword = async () => {
    const query = await getEmailQuery(inputRef);
    navigate(`/forgot-password?${query}`);
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        organisation_id: '',
        screen: 'admin',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <>
          <Heading level={3}>Sign In</Heading>
          <Heading level={5} size="large" color="dark-2">
            Sign in to your RollCall Admin Account
          </Heading>
          <form onSubmit={handleSubmit}>
            {/* <input autoComplete="new-password" name="hidden" type="password" style={{ display: 'none' }} /> */}
            <Text as="div" color="status-critical">
              {errors.title}
            </Text>
            {/** FIXME: Move strings to messages */}
            <FormField
              name="email"
              label={LOGIN_MESSAGES.EMAIL_LABEL}
              error={touched.email && errors.email}
              normal={true}
            >
              <TextInput
                ref={inputRef}
                type="email"
                name="email"
                onChange={e => {
                  handleChange(e);
                  setOrganisation([]);
                  // setFieldValue('organisation_id', '');
                }}
                // autoComplete="new-password"
                onBlur={handleBlur}
                value={values.email}
                style={{ marginTop: '0px' }}
              />
            </FormField>
            <FormField
              name="password"
              label={LOGIN_MESSAGES.PASS_LABEL}
              required
              error={touched.password && errors.password}
              normal={true}
            >
              <PasswordInput
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type="password"
                // autocomplete='new-password'
                style={{ marginTop: '0px' }}
              />
            </FormField>

            {organisation?.length > 0 ? (
              <FormField
                name="organisation_id"
                label={LOGIN_MESSAGES.ORGANIZATION_LABEL}
                error={touched.organisation_id && errors.organisation_id}
                normal={true}
              >
                <Select
                  name="organisation_id"
                  options={organisation}
                  labelKey="name"
                  value={values.organisation_id}
                  valueKey={{ key: 'pk', reduce: true }}
                  onChange={({ value }) => {
                    setFieldValue('organisation_id', value);
                  }}
                />
              </FormField>
            ) : (
              ''
            )}
            <Box direction="row" gap="small" margin={{ top: 'medium' }}>
              <Button
                type="submit"
                alignSelf="start"
                label={
                  isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting}
                primary
                color="secondary"
                style={{ borderRadius: '4px' }}
              />
              {loading && (
                <Box
                  align="center"
                  style={{ display: ssoVisible ? 'none' : '' }}
                  direction="row"
                  margin={{ left: 'medium' }}
                >
                  <Spinner
                    border={[
                      {
                        side: 'horizontal',
                        color: 'brand',
                        size: 'large',
                        style: 'inset',
                      },
                    ]}
                  />
                </Box>
              )}
              <Button
                alignSelf="end"
                label={ssoLabel}
                disabled={isSubmitting}
                primary
                style={{ display: !ssoVisible ? 'none' : '', borderRadius: '4px' }}
                color="#1c2b36"
                onClick={() => window.location.replace(ssoRedirectUrl)}
              />
            </Box>
            {privacyPolicyLink && showPrivacyPolicy === true && (
              <Box align="start" margin={{ vertical: 'medium' }}>
                <Text color="#707070" as="span">
                  By clicking {LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL.toLowerCase()}, you are indicating that you have read
                  and agree to the
                  <Anchor
                    href={privacyPolicyLink}
                    target="_blank"
                    style={{ fontSize: '12px', lineHeight: '16px', marginLeft: 5 }}
                  >
                    Privacy Policy
                  </Anchor>
                  .
                </Text>
              </Box>
            )}

            <Box align="start" margin={{ vertical: 'small' }}>
              <Anchor
                onClick={handleForgotPassword}
                as="span"
                label={LOGIN_MESSAGES.FORGOT_PASS_LINK_LABEL}
                style={{ fontSize: '12px' }}
              />
            </Box>
            {mainUrl !== `${window.location.protocol}//${domain}` && (
              <>
                <Box align="center" justify="center" direction="row" pad={{ bottom: 'small' }}>
                  <hr style={{ width: '99%', border: 'none', borderTop: '1px solid #D3D3D3' }} />
                  <Text weight="bold" size="large" margin="10px">
                    or
                  </Text>
                  <hr style={{ width: '99%', border: 'none', borderTop: '1px solid #D3D3D3' }} />
                </Box>
                <Box direction="column" align="center" justify="center" gap="medium">
                  <Text weight="bold" size="large">
                    Are you trying to login as parent? We have a different spot for that!
                  </Text>
                  <Button
                    label="Parent Portal Login"
                    primary
                    size="large"
                    margin={{ bottom: 'large' }}
                    color="accent-1"
                    style={{ borderRadius: '20px', width: '100%' }}
                    onClick={() => navigate('/parent/login')}
                  />
                </Box>
              </>
            )}
          </form>
        </>
      )}
    </Formik>
  );
};

LoginAdminForm.propTypes = {
  eventEmitter: PropTypes.object,
  msg: PropTypes.string,
  config: PropTypes.object,
  loginClicked: PropTypes.func,
  organisation: PropTypes.array,
  setOrganisation: PropTypes.func,
  ssoUrl: PropTypes.any,
};

export default LoginAdminForm;
