const phoneCountry = {
  australia: {
    dialCode: '61',
    number: '61',
    phone: '',
    countryCode: 'au',
  },
  'new zealand': {
    dialCode: '64',
    number: '64',
    phone: '',
    countryCode: 'nz',
  },
  singapore: {
    dialCode: '65',
    number: '65',
    phone: '',
    countryCode: 'sgp',
  },
  'united states': {
    dialCode: '1',
    number: '1',
    phone: '',
    countryCode: 'us',
  },
  canada: {
    dialCode: '1',
    number: '1',
    phone: '',
    countryCode: 'ca',
  },
  uae: {
    dialCode: '971',
    number: '971',
    phone: '',
    countryCode: 'ae',
  },
};
export const possiblePhoneCountry = userProfile => {
  return userProfile?.country ? phoneCountry[userProfile.country] : phoneCountry['australia'];
};

/**
 * Fetches the current time period (AM/PM) based on the user's timezone.
 *
 * @param {Object} userPreferenceData - User preference data containing timezone info.
 * @returns {string} - 'AM' or 'PM' based on the current time in the user's timezone.
 */
export const fetchCurrGrp = userPreferenceData => {
  let e = new Date().toLocaleString('en-US', { timeZone: userPreferenceData?.userTimezone || 'Australia/Melbourne' });
  if (e?.includes('AM')) return 'AM';
  else return 'PM';
};

/**
 * Retrieves a group from a list based on the current time period (AM/PM).
 *
 * @param {Array<Object>} list - List of groups with group codes and names.
 * @param {Object} userPreferenceData - User preference data containing timezone info.
 * @returns {Object} - The selected group or the first item in the list if none is found.
 */
export const fetchSelectedGroup = (list, userPreferenceData) => {
  let filteredByGroupCode = list?.filter(i => i?.groupCode === fetchCurrGrp(userPreferenceData));
  let selectedGrp =
    filteredByGroupCode?.find(i => i?.groupName === fetchCurrGrp(userPreferenceData)) || filteredByGroupCode[0];
  return selectedGrp ? selectedGrp : list[0];
};
