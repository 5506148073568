// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application Imports
import dashletsAPI from 'dashlets/gateways/dashlets-api';
import { DASHLETS_EDIT_EVENTS } from './constants';

async function updateDashletsClicked(eventEmitter, values) {
  if (_.isNil(values)) {
    throw new GraniteError('User data is invalid');
  }
  // try {
  const { data } = await dashletsAPI.updateDashlets(values);
  eventEmitter.emit(DASHLETS_EDIT_EVENTS.SAVE_SUCCESS, data);
  // } catch (error) {
  //   eventEmitter.emit(DASHLETS_EDIT_EVENTS.SAVE_FAILURE);
  //   throw error;
  // }
}
async function getParticularOrganisations(eventEmitter, params) {
  try {
    const result = await dashletsAPI.getDashLetOrganisations({
      ...params,
    });
    eventEmitter.emit(DASHLETS_EDIT_EVENTS.GET_ORG_SUCCESS, result?.list);
  } catch (error) {
    const err = new GraniteError(error);
    eventEmitter.emit(DASHLETS_EDIT_EVENTS.GET_ORG_FAILURE, err?.errors?.title);
  }
}

export { updateDashletsClicked, getParticularOrganisations };
