import * as Yup from 'yup';

export const getValidationSchema = ({ changePhoneLayer, otpSent }) => {
  return Yup.object().shape({
    phone: Yup.object().when('type', {
      is: val => val === 'sms',
      then: Yup.object().test(value => {
        if (!value || value?.error) {
          return new Yup.ValidationError('Invalid phone number', undefined, 'phone');
        } else {
          return true;
        }
      }),
    }),
    email: Yup.string().when('type', {
      is: val => val === 'email',
      then: Yup.string().trim().email().required('New Email is required'),
    }),
    ...(changePhoneLayer === 'SMS'
      ? {
          password: Yup.string()
            .max(24, 'Password is too long')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
              'Password must contain at least 6 characters, one capital and one number',
            )
            .required('Current Password is required'),
        }
      : changePhoneLayer === 'OTP'
      ? { otp: Yup.string().max(6, 'Code is too Long').required('Code is required') }
      : {}),
    ...(otpSent ? { token: Yup.string().max(6, 'Code is too Long').required('Code is required') } : {}),
  });
};
