// Lib Imports
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Box, Text, Image } from 'grommet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Core Imports
import { useToast } from 'granite-admin/core/components/Toast';
import GMap from 'granite-admin/core/components/GMap';
import ButtonGroup from 'granite-admin/core/components/ButtonGroup';
import EventEmitter from 'granite-admin/utils/event-emitter';
import Loader from 'granite-admin/core/components/Loader';

// Application Imports
import { pinStop } from 'assets/svgTemplates';
import { Campus } from 'assets/Campus';
import getAllRoutes from 'goToRoute/controllers/goToRoute';
import { GO_TO_ROUTE_EVENTS } from '../../goToRoute/controllers/constant';
import RouteComponent from './components/Routes';
import rollcallLogo from 'assets/logo.png';

const StyledButtonGroup = styled(ButtonGroup)`
  & label {
    align-items: center !important;
    display: block !important;
    width: 50% !important;
  }
  & div {
    justify-content: center !important;
    height: 44px;
  }
`;
const GoToRoute = () => {
  const [amRouteList, setAmList] = useState([]);
  const [pmRouteList, setPmList] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [amAndPmFlag, setFlag] = useState('AM');
  const [stopColor, setStopColor] = useState('');
  const [bGColor, setBG] = useState('');
  const [loader, setLoader] = useState(true);
  const colorRef = useRef(true);
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const { errorToast } = useToast();
  const host = window.location.host;
  const domainName = host.split('.')[0];
  let stoplist = [];

  useEffect(() => {
    getAllRoutes(eventEmitter, domainName);
  }, [eventEmitter, domainName]);

  useEffect(() => {
    const observable = eventEmitter.getObservable();
    const subscription = observable.subscribe(event => {
      switch (event.type) {
        case GO_TO_ROUTE_EVENTS.FETCH_SUCCESS:
          setAmList(event.data[0]);
          setPmList(event.data[1]);
          event.data[0].map(i => {
            stoplist.push(...i['stop']);
          });
          setMarkers(stoplist);
          setLoader(false);
          break;
        case GO_TO_ROUTE_EVENTS.FETCH_FAILURE:
          errorToast(event.data || 'Failed to fetch records.');
          setLoader(false);
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, markers, stoplist]);

  const toggleBusStopView = flag => {
    (flag === 'AM' ? amRouteList : pmRouteList).map(i => {
      stoplist.push(...i['stop']);
    });
    setFlag(flag);
    setStopColor('');
    setBG('');
    setMarkers(stoplist);
    colorRef.current = true;
  };

  const stopClicked = (routeId, id) => {
    const route = (amAndPmFlag === 'AM' ? amRouteList : pmRouteList).find(x => x.pk === routeId);
    route.stop = [...route.stop];
    route.stop.forEach(element => {
      element.pinHighlight = element.pk === id ? true : false;
    });
    const newStopList = [...route.stop];
    setMarkers([...newStopList]);
  };
  const createMarker = useCallback(marker => {
    let markerColor = colorRef.current ? marker.color : marker.pinHighlight ? marker.color : '#FF0000';
    return new window.google.maps.Marker({
      ...marker,
      ...(!marker.isCampus
        ? {
            icon: {
              url:
                'data:image/svg+xml;utf-8,' +
                encodeURIComponent(pinStop.replaceAll('#b09c19', markerColor).replaceAll('#e9cf20', markerColor)),
              labelOrigin: new window.google.maps.Point(15, 13),
            },
            label: marker?.sequence?.toString(),
          }
        : {
            icon: {
              url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(Campus(markerColor)),
            },
          }),
      position: { lat: marker?.latitude, lng: marker?.longitude },
    });
  }, []);

  return (
    <Box direction="row" width="100%" height="100%" gap="medium" pad="medium">
      {loader ? (
        <Box align="center" justify="center" width="100%">
          <Loader />
        </Box>
      ) : (
        <>
          <Box pad={{ horizontal: 'small' }} width="100%" overflow={{ vertical: 'auto' }} height="100%" flex>
            <Box fill="horizontal" pad="xsmall" margin={{ bottom: 'small' }}>
              <StyledButtonGroup
                options={[
                  { label: 'AM', value: 'AM' },
                  { label: 'PM', value: 'PM' },
                ]}
                value={'AM'}
                hoverBackground="brand"
                onChange={toggleBusStopView}
              />
            </Box>
            <Box width="100%" height="100%" overflow="auto">
              <RouteComponent
                routes={amAndPmFlag === 'AM' ? amRouteList : pmRouteList}
                setMarkers={setMarkers}
                stopClicked={stopClicked}
                stopColor={stopColor}
                setStopColor={setStopColor}
                bGColor={bGColor}
                setBG={setBG}
                colorRef={colorRef}
              />
            </Box>
          </Box>
          <Box
            width={{ width: '70%', min: '70%', max: '70%' }}
            direction="column"
            flex
            pad="small"
            background="white"
            elevation="small"
            round="xsmall"
          >
            <Box
              style={{
                position: 'absolute',
                zIndex: 10,
                color: '#ffffff',
                bottom: '0%',
                left: '30%',
                backgroundColor: 'white',
                padding: '0.6%',
                width: '150px',
              }}
              round="small"
            >
              <Link target={'_blank'} to="https://rollcall.com.au/" rel="noreferrer">
                <Image src={rollcallLogo} width="100%" class="img_icon" />
              </Link>
            </Box>
            <GMap
              id="routeMap"
              searchable={false}
              showAutofit={false}
              showMyLocation={false}
              markers={markers}
              markerInfoWindow={marker => (
                <Box pad="xsmall" gap="xsmall">
                  <Text>
                    <b>{marker.name}</b>
                  </Text>
                  <span>{marker.address}</span>
                </Box>
              )}
              showOnlyShapes={true}
              createMarker={createMarker}
              clustering={colorRef.current ? true : false}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default GoToRoute;
