import React, { useMemo } from 'react';
import { Box, Card, Image } from 'grommet';
import LoginForm from './components/loginform';
import EventEmitter from 'granite-admin/utils/event-emitter';
import { STRINGS } from './strings';
const eventEmitter = new EventEmitter();

const AdminForget = () => {
  return (
    <Card pad="xxsmall" width="16%" alignSelf="center" style={{ margin: '100px' }}>
      <Box width="72%" alignSelf="center" margin={{ vertical: 'medium' }} flex={{ shrink: 0 }}>
        <Image src={STRINGS.ROLLCALL_IMG_SRC} fit="contain" />
      </Box>
      <LoginForm eventEmitter={eventEmitter} />
    </Card>
  );
};
export default AdminForget;
