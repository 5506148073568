import React from 'react';
import { Box, Text, Heading } from 'grommet';
import { Validate } from 'grommet-icons';
import { STRINGS } from 'common/TwoFALayer/strings';
import { verificationEmailColor, successColor } from 'common/TwoFALayer/styledComponents/common';
const SuccessScreen = () => {
  return (
    <Box fill={true} justify="center" align="center" pad="large">
      <Validate size="large" color={successColor} />
      <Heading textAlign="center" level={5} weight={600} style={{ marginTop: '25px' }}>
        {STRINGS.TWO_FACTOR_AUTH_ENABLED}
      </Heading>
      <Text textAlign="center" size="large" color={verificationEmailColor} weight={600}>
        {STRINGS.TWO_FACTOR_AUTH_DESCRIPTION}
      </Text>
    </Box>
  );
};

export default SuccessScreen;
