import React from 'react';
import { Box, Button, Heading, Paragraph } from 'grommet';
import styled from 'styled-components';
import clipArt from 'granite-admin/core/components/DashboardLayout/components/clipart.png';
import { useSignOut } from 'common/useSignOut';
const BigHeading = styled(Heading)`
  font-size: 8rem;
  margin-top: 2rem;
`;

const UnauthorizedAccess = () => {
  const logout = useSignOut();
  return (
    <Box
      justify="start"
      background={{
        image: `url(${clipArt})`,
      }}
      height="100vh"
      width="100%"
      pad="large"
    >
      <BigHeading level={1} weight="bold">
        404 !!
      </BigHeading>
      <Paragraph size="xlarge">You do not belong to this organization</Paragraph>
      <Box align="start">
        <Button label="Logout" onClick={logout} primary color="text" />
      </Box>
    </Box>
  );
};

export default UnauthorizedAccess;
