import React from 'react';
// import { ROOM_NAMES } from 'common/miscellaneous';
import { checkUserAgent } from 'granite-admin/utils/functions';
import moment from 'moment-timezone';
import { format } from 'date-fns';

const LANDSCAPE_TABLET_WIDTH = 1024;

export const heatMapDate = formatDataYear => {
  let allDates = [];
  formatDataYear.map(e => {
    let from = new Date(e + '-' + '01' + '-' + '01');
    let to = new Date(e + '-' + '12' + '-31');
    for (let j = from; j <= to; j.setDate(j.getDate() + 1)) {
      allDates.push(j.toISOString().slice(0, 10));
    }
  });
  return allDates;
};
export const getWeekDays = (daySelect = false, selectedDate = [], days = [], excluded = true) => {
  let daysArr = [];
  selectedDate.forEach(date => {
    let d = date ? new Date(`${date} 00:00:00`) : new Date();
    // let d = date ? new Date(date) : new Date();
    let month = d.getMonth();
    // Get all the other month except daysArr in the month
    if (!daySelect) {
      while (d.getMonth() === month) {
        let condition = excluded
          ? days.length
            ? d.getDay() !== days[0] && d.getDay() !== days[1]
            : d.getDay() !== 1
          : days.length
          ? d.getDay() === days[0] || d.getDay() === days[1]
          : d.getDay() === 1;
        if (condition) {
          daysArr.push(`${format(new Date(d), 'yyyy-MM-dd')} 00:00:00`);
          // daysArr.push(format(new Date(d), 'yyyy-MM-dd'));
          // daysArr.push(new Date(d.getTime()).toISOString().slice(0, 10));
        }
        d.setDate(d.getDate() + 1);
      }
    } else {
      while (d.getMonth() === month) {
        let condition = excluded
          ? days.length
            ? d.getDate() !== days[0] && d.getDate() !== days[1]
            : d.getDate() !== 1
          : days.length
          ? d.getDate() === days[0] || d.getDate() === days[1]
          : d.getDate() === 1;
        if (condition) {
          daysArr.push(`${format(new Date(d), 'yyyy-MM-dd')} 00:00:00`);
          // daysArr.push(format(new Date(d), 'yyyy-MM-dd'));
          // daysArr.push(new Date(d.getTime()).toISOString().slice(0, 10));
        }
        d.setDate(d.getDate() + 1);
      }
    }
    // }
  });

  //included edge cases dates for month select
  // TODO: make it generic
  // const startDate = new Date(new Date(daysArr[0]).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
  // const endDate = new Date(new Date(daysArr.at(-1)).getTime() + 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);
  // daysArr.push(startDate);
  // daysArr.push(endDate);

  return daysArr;
};

export const getMonday = d => {
  const today = d ? new Date(`${d} 00:00:00`) : new Date();
  // const today = d ? new Date(d) : new Date();
  const first = today.getDate() - today.getDay() + 1;

  const monday = format(new Date(today.setDate(first)), 'yyyy-MM-dd');
  // format(new Date(d), 'yyyy-MM-dd')
  // const monday = new Date(today.setDate(first)).toISOString().slice(0, 10);
  return monday;
};

export const dateRange = (startDate, endDate, steps) => {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];
  if (steps === 1) {
    const dateArray = [];
    let currentDate = new Date(startDate);
    while (currentDate < new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().slice(0, 10));
      // Use UTC date to prevent problems with time zones and DST
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  } else {
    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth, '01'].join('-'));
      }
    }
    return dates;
  }
};

export const getFormatedTime = (time, timeZone) => {
  let formatedTime = new Date(time).toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: timeZone || 'Australia/Melbourne',
  });
  return formatedTime;
};

//convert all format date into yyyy-mm-dd
export const getFormatedDate = (time, timeZone) => {
  let formatedDate = new Date(time)
    .toLocaleDateString('en-GB', { timeZone: timeZone || 'Australia/Melbourne' })
    .split('/')
    .reverse()
    .join('-');
  return formatedDate;
};

const timeToMinutes = time => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const checkTimeBetween = (startTime, endTime, checkTime) => {
  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);
  const checkMinutes = timeToMinutes(checkTime);

  return startMinutes <= checkMinutes && checkMinutes <= endMinutes;
};

export const isTimeBetween = currentTime => {
  const startTime1 = '07:00';
  const endTime1 = '09:00';
  const startTime2 = '15:00';
  const endTime2 = '17:00';
  return checkTimeBetween(startTime1, endTime1, currentTime) || checkTimeBetween(startTime2, endTime2, currentTime);
};

//convert dd-MM-yyyy to MM-dd-yyyy - used for grommet calendar
export const converToMMDDYYYY = (date = '') => {
  try {
    let seperator = date?.includes('-') ? '-' : '/';
    let datearray = date.split(seperator);
    const newDate = datearray[1] + seperator + datearray[0] + seperator + datearray[2];
    return newDate || date;
  } catch (e) {
    console.log('err ', e);
  }
};

export const convertDateToUTC = (dateString = '', timeZone = 'Australia/Melbourne') => {
  const inputDate = moment.tz(dateString, timeZone);
  const utcDate = inputDate.utc();
  return utcDate?.format();
};

export const viewDateFormat = 'dd/MM/yyyy';
export const customDateFormat = 'yyyy-MM-dd';
export const dateStyles = {
  hideAllFieldTooltip: true,
};

//TODO: use redux to save & remove connection id
export const onConnectionOpen = event => {
  // sessionStorage.setItem("WSConnectionId",event.connection_id);
};
// export const onGetMessage = eventData => {
// if (eventData.event === 'pong') {
//   sessionStorage.setItem('WSConnectionId', eventData.connection_id);
// }
// };

export const onConnectionClose = event => {
  sessionStorage.removeItem('WSConnectionId');
};

export const getConnectionId = () => {
  return sessionStorage.getItem('WSConnectionId');
};

export const addOneDay = (date = '', ddMMyyyy = true) => {
  const newDate = date ? new Date(date) : new Date();
  newDate.setDate(newDate.getDate() + 2);
  return ddMMyyyy ? newDate.toISOString().slice(0, 10) : newDate;
};

const numDeltas = 50;
const delay = 10; //milliseconds
let i = 0;
let deltaLat;
let deltaLng;
// const position = [changedLoc?.lat_long?.lat,changedLoc?.lat_long?.long]

export const transition = (result, position, businstacnes) => {
  i = 0;
  deltaLat = (result[0] - position[0]) / numDeltas;
  deltaLng = (result[1] - position[1]) / numDeltas;
  return moveMarker(businstacnes, position);
};

function moveMarker(businstacnes, position) {
  if (businstacnes) {
    // {businstacne}=businstacne
    position[0] += deltaLat;
    position[1] += deltaLng;
    var latlng = new window.google.maps.LatLng(position[0], position[1]);
    // businstacne.setTitle("Latitude:"+position[0]+" | Longitude:"+position[1]);
    businstacnes.setPosition(latlng);
    if (i !== numDeltas - 1) {
      i++;
      //  moveMarker(businstacnes,position)
      setTimeout(() => moveMarker(businstacnes, position), delay);
    }
    return businstacnes;
  }
}

export const fetchGeoLocation = async latLng => {
  try {
    console.log('latLng is --- ', latLng);
    const geocoder = new window.google.maps.Geocoder();
    const resp = await geocoder.geocode({ location: { lat: latLng.lat(), lng: latLng.lng() } });
    return resp.results[0].formatted_address;
  } catch (e) {
    console.log('error ', e);
    return '';
  }
};

// calculate distance between students[A,B] & stops[C,D] array - {A to C A to D B to C B to D}
export const calculateDistance = (start = [], end = []) => {
  const service = new window.google.maps.DistanceMatrixService();
  return service.getDistanceMatrix(
    {
      origins: start,
      destinations: end,
      travelMode: window.google.maps.TravelMode.DRIVING,
      // transitOptions: {  // use it for public bus/train transport
      //   modes: ['BUS'],
      // },
      // unitSystem: google.maps.UnitSystem.IMPERIAL, // miles and feet.
      unitSystem: window.google.maps.UnitSystem.metric, // kilometers and meters.
      avoidHighways: false,
      avoidTolls: false,
    },
    callback,
  );
};
// distance & time response
function callback(response, status) {
  if (status != window.google.maps.DistanceMatrixStatus.OK) {
    return null; // Error
  } else {
    return response;
  }
}
export const checkMobileDevice = () => {
  const agent = checkUserAgent();
  if ((agent === 'IOS' || agent === 'ANDROID') && window.innerWidth < LANDSCAPE_TABLET_WIDTH) {
    return true;
  }
  return false;
};

export const createMarker = marker => {
  return new window.google.maps.Marker({
    ...marker,
    draggable: true,
    position: { lat: marker?.latitude, lng: marker?.longitude },
  });
};

export const getBoundsInfo = (location, geofenceSize) => {
  const google = window['google'];
  const centerPoint = new google.maps.LatLng(location.lat, location.lng);
  const bounds = new google.maps.Circle({
    center: centerPoint,
    radius: geofenceSize / 2,
  }).getBounds();
  const jsonBounds = bounds.toJSON();
  return jsonBounds;
};

export const defaultBounds = {
  topRight: [-25.274061131768455, 133.77476347082438],
  topLeft: [-25.274061131768455, 133.77550852917562],
  bottomRight: [-25.274734868231548, 133.77476347082438],
  bottomLeft: [-25.274734868231548, 133.77550852917562],
};

export const fetchDateTime = date => {
  try {
    const newDate = new Date(date).getTime();
    return isNaN(newDate) ? '' : newDate;
  } catch (e) {
    return '';
  }
};

export const replaceStrIfIncludes = (str, replaceBy = 'Student', includeStr = 'user') => {
  if (str.includes(includeStr)) {
    str = str.replace(includeStr, replaceBy);
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const tableStyles = {
  global: {
    colors: {
      hover: undefined,
    },
  },
  graniteTable: {
    row: {
      hoverBackground: undefined,
    },
  },
};

const textCellColor = '#ff0000';

const fetchColor = row => {
  return row?.original?.transactionStatus?.toLowerCase() === 'failed' ? textCellColor : '';
};

export const withCommonCellStyle = WrappedComponent => {
  return ({ cell: { value, row }, ...rest }) => {
    const style = { color: fetchColor(row) };
    return <WrappedComponent {...rest} style={style} data={value} />;
  };
};

export const isSameYear = (date1, date2) => {
  return date1 && date2 && new Date(date1).getFullYear() === new Date(date2).getFullYear();
};
