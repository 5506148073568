export const STRINGS = {
  ENTER_OTP: 'Enter the One Time Password (OTP) sent to your registered',
  OTP: 'One Time Password',
  VERIFYING: 'Verifying ...',
  VERIFY: 'Verify',
  INVALID_OTP: 'Please enter the correct OTP',
  NON_ADMIN_CREDENTIALS:
    'The credentials being used do not belong to an admin, please try logging in to the Parent Portal below',
  WRONG_ORG_USER: 'User not belongs to this organization',
  VERIFICATION_SUCCESSFUL: 'Verification successful',
  TWO_FACTOR_LOGIN_TEXT: 'Two Factor Login Verification',
};
