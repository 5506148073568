// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import { env } from 'granite-admin/env';
import ROUTE_URLS from 'common/TopNavbar/gateways/endpoints';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ORGANISATION_API;

async function getOrgSettingAllKey(payload) {
  const { data } = await GraniteAPIClient(baseUrl).get(ROUTE_URLS.ORG_SETTINGS, {
    params: payload,
  });
  return data?.results;
}

async function sendCustomEmail(payload) {
  return await GraniteAPIClient(url).post(ROUTE_URLS.SEND_CUSTOM_EMAIL, payload);
}

export default { sendCustomEmail, getOrgSettingAllKey };
