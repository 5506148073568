// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
// Application Imports
import { TOPNAVBAR_EVENTS } from 'common/TopNavbar/controllers/events';
import notificationsAPI from 'common/TopNavbar/gateways/notifications-api';
import NotificationEntity from 'granite-admin/common/entities/notification';
import { handleApiCall } from 'utils/handleApiCallController';
import routeMessageAPI from 'common/TopNavbar/gateways/routeMessage-api';

async function sendCustomEmail(eventEmitter, values) {
  try {
    const payload = {
      email: values.email,
      message: values.message,
    };
    await routeMessageAPI.sendCustomEmail(payload);
    eventEmitter.emit(TOPNAVBAR_EVENTS.SEND_CUSTOM_EMAIL_SUCCESS, values.url);
  } catch (e) {
    const err = new GraniteError(e);
    eventEmitter.emit(TOPNAVBAR_EVENTS.SEND_CUSTOM_EMAIL_FAILURE, err?.errors?.title);
  }
}
async function fetchNotifications(eventEmitter, { page, is_read }) {
  handleApiCall(
    async () => {
      const payload = { params: { page, is_read } };
      const data = await notificationsAPI.fetchNotifications(payload);
      let list = [];
      const currentTimestamp = Date.now();
      data.results.forEach(item => {
        if (item.extra_data?.expires_timestamp && item.extra_data?.expires_timestamp * 1000 > currentTimestamp)
          list.push({ ...new NotificationEntity(item), isExpired: false });
        else list.push({ ...new NotificationEntity(item), isExpired: true });
      });
      eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_NOTIFICATIONS_SUCCESS, { list, nextPage: data.next_page });
    },
    eventEmitter,
    TOPNAVBAR_EVENTS.FETCH_NOTIFICATIONS_FAILURE,
  );
}

export { sendCustomEmail, fetchNotifications };
