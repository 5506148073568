import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Card, Image, Button, Paragraph } from 'grommet';
import styled from 'styled-components';

import { useToast } from 'granite-admin/core/components/Toast';

import ConfirmPopupLayer from 'common/ConfirmPopupLayer';
import RollOverApi from 'rollover/gateways/rollover-api';

const logo = 'https://school1.testing.rollcall.com.au/client/image/rollcall.png';

const StyledParagraph = styled(Paragraph)`
  font-size: 13px;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;
const currentYear = new Date().getFullYear();

const commonStyle = {
  style: { width: '100%', padding: '14px 0', fontSize: 'large' },
  alignSelf: 'start',
  margin: { top: 'medium' },
  primary: 'primary',
};

const AlertMsg = ({ dateYear }) => (
  <Box align="center">
    <Text size="large">Rollover of students and routes to the year {dateYear?.year} has been completed.</Text>
    <Text size="large"> Click 'OK' to view the transport screen.</Text>
  </Box>
);

const RollOver = () => {
  const { errorToast } = useToast();
  const navigate = useNavigate();
  const [dateYear, setDateYear] = useState({ date: '', year: '' });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const parseDateFromRefString = refDate => {
    return {
      year: refDate?.substr(0, 4),
      month: refDate?.substr(4, 2),
      day: refDate?.substr(6, 2),
    };
  };

  const isValidDate = dateString => {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    let d = new Date(dateString);
    let dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  };

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.includes('admin/rollover')) {
      let yearDate = pathName.replace('/admin/rollover/', '').split('/');
      let d = parseDateFromRefString(yearDate[1]);
      setDateYear({ year: yearDate?.[0], date: `${d.day}/${d.month}/${d.year}` });
    }
  }, []);

  const onSubmit = async () => {
    let values = {
      route_date: dateYear?.date,
      next_year: parseInt(dateYear?.year),
    };
    try {
      setLoading(true);
      let refDateIsoFormat = values?.route_date?.split('/')?.reverse()?.join('-');
      if (!isValidDate(refDateIsoFormat)) {
        errorToast('Wrong reference date format, It should be YYYYMMDD!');
        setLoading(false);
        return false;
      }
      values = { ...values, route_date: refDateIsoFormat };
      await RollOverApi.postRollOverData(values);
      setLoading(false);
      setShowAlert(true);
    } catch (e) {
      errorToast(e?.errors?.title || 'Failed to create RollOver data');
      setLoading(false);
    }
  };

  const navidateToLogin = useCallback(() => navigate('/login'), []);
  const navidateToTransport = useCallback(() => navigate('/admin/transport'), []);

  return (
    <Card pad="xxsmall" width="20rem" alignSelf="center" style={{ margin: '100px' }}>
      <Box width="72%" alignSelf="center" margin={{ vertical: 'medium' }} flex={{ shrink: 0 }}>
        <Image src={logo} fit="contain" />
      </Box>

      <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
        <StyledParagraph>
          <b> WARNING: </b> You are about to create a new year in the RollCall system for{' '}
          <strong>{dateYear?.year}</strong> based on the current year <strong> {currentYear}</strong>. The routes and
          students will be based on the date <b>{dateYear?.date}</b>. This cannot be undone - are you sure you want to
          continue?
        </StyledParagraph>
        <Button disabled={loading} label="Cancel" color="cancel" onClick={navidateToLogin} {...commonStyle} />
        <Button
          disabled={loading}
          label={loading ? 'Processing...' : 'Continue'}
          color="accent-1"
          onClick={onSubmit}
          {...commonStyle}
        />
      </Box>
      {showAlert && (
        <ConfirmPopupLayer
          togglePopup={showAlert}
          isVisible
          okBtnColor="brand"
          showCancelBtn={false}
          labelOK="OK"
          onOK={navidateToTransport}
          width="35rem"
          text={<AlertMsg dateYear={dateYear} />}
        />
      )}
    </Card>
  );
};

export default RollOver;
