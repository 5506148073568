/**
 * Downloads a blob of data as a file with the given filename and type
 *
 * @param {Blob} data - The blob of data to be downloaded
 * @param {string} fileName - The filename of the downloaded file
 * @param {string} type - The MIME type of the downloaded file
 */
export const handleDownload = (data, fileName, type) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.style = 'display: none';
  const file = new Blob([data], { type });
  const url = window.URL.createObjectURL(file);
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

/**
 * Capitalizes the first letter of each word in a given string
 *
 * @param {string} str - The string to be capitalized
 * @returns {string} The capitalized string
 */
export const capitalizeString = (str = '') => {
  const arr = str?.split(' ');
  for (let i = 0; i < arr?.length; i++) {
    arr[i] = arr?.[i]?.charAt(0)?.toUpperCase() + arr?.[i]?.slice(1);
  }
  return arr.join(' ');
};

export const boundsStartDate = '2001-01-01 00:00:00';
export const boundsEndDate = '2099-12-31 00:00:00';

export const safeJsonParse = value => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
};
