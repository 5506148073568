import { createSelector } from 'reselect';

import createReducer from 'granite-admin/utils/ducks/create-reducer';

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const UPDATE_ROUTES_DATES = 'heatmap/UPDATE_ROUTES_DATES';
const UPDATE_STUDENTS_DATES = 'heatmap/UPDATE_STUDENTS_DATES';

// Action creator name: <VERB><NOUN>
// Export actions

export function updateRoutesDate(routeData) {
  return {
    type: UPDATE_ROUTES_DATES,
    data: routeData,
  };
}

export function updateStudentsDate(studentData) {
  return {
    type: UPDATE_STUDENTS_DATES,
    data: studentData,
  };
}

// state name - <NOUN>
const defaultState = {
  route: {
    dates: {
      startDate: '',
      endDate: '',
    },
  },
  student: {
    dates: {
      startDate: '',
      endDate: '',
    },
  },
};

// Reducers - <NOUN>_<VERB>_REDUCER
function UPDATE_ROUTES_DATES_REDUCER(state, action) {
  return Object.assign({}, state, {
    route: action.data,
  });
}

function UPDATE_STUDENTS_DATES_REDUCER(state, action) {
  return Object.assign({}, state, {
    student: action.data,
  });
}

const handlers = {
  [UPDATE_ROUTES_DATES]: UPDATE_ROUTES_DATES_REDUCER,
  [UPDATE_STUDENTS_DATES]: UPDATE_STUDENTS_DATES_REDUCER,
};

// Selectors
const heatmapSelector = state => state.rollcall.heatmap;

export const routes = createSelector(heatmapSelector, heatmap => heatmap.route);
export const students = createSelector(heatmapSelector, heatmap => heatmap.student);

export default createReducer(defaultState, handlers);
