import { useCallback, useMemo } from 'react';
import useUtility from 'granite-admin/utils/useUtility';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import userApi from 'accounts/gateways/user_api';

const SOCKET_SCREEN_ENDPOINTS = [
  '/route-mapping',
  '/operational-dashboard',
  '/global-dashboard',
  '/parent/mapbus',
  '/admin/transport',
];

const useGrandListener = (closeConnection, loadNativeWebsocket, websocketInstance) => {
  const grandEmitter = useMemo(() => getGrandEmitter(), []);

  const grandListner = useCallback(
    event => {
      switch (event.type) {
        case 'CLOSE_NATIVESOCKET_CONNECTION':
          closeConnection(true);
          break;
        case 'RECREATE_SOCKET_CONNECTION':
          {
            const pathname = window.location.pathname;
            if (websocketInstance?.readyState === WebSocket.CLOSED) {
              if (SOCKET_SCREEN_ENDPOINTS.includes(pathname) || pathname.includes('/my-boards/')) {
                window.location.reload();
              } else {
                userApi.myProfile();
                loadNativeWebsocket();
              }
              grandEmitter.emit('FETCH_NOTIFICATION_COUNT');
            }
          }
          break;
        default:
          break;
      }
    },
    [closeConnection, grandEmitter, loadNativeWebsocket, websocketInstance],
  );
  useUtility({ grandListner });
};

export default useGrandListener;
