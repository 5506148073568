export const STRINGS = {
  MULTIPLE_ACCOUNTS_PHONE_WARNING:
    'The phone number you’ve provided is associated with multiple accounts. For added security, please set up multi-factor authentication via email.',
  NO_PHONE_NUMBER_WARNING:
    'Your profile does not have a phone number. Please set up multi-factor authentication via email.',
  SECURITY_CODES_EMAIL: 'Security codes will be sent to your email',
  SECURITY_CODES_PHONE: 'Security codes will be sent to your phone',
  SECURITY_CODES_PREFERENCE_QUESTION: 'How would you like to receive your security codes?',
  TWO_FACTOR_AUTH_ENABLED: 'Two-factor authentication enabled!',
  TWO_FACTOR_AUTH_DESCRIPTION:
    "From now on when you sign in to your RollCall account, you'll need to enter both your password and a security code from your device.",
  MFA_UPDATE_FAILED: 'Unable to update MFA medium',
  GENERIC_ERROR: 'Something went wrong!',
  MFA_ENABLE_FAILED: 'Unable to enable MFA',
  OTP_SENT_SUCCESS: 'OTP sent successfully',
  OTP_SEND_FAILED: 'Unable to send OTP',
  OTP_VERIFY_FAILED: 'Unable to verify OTP',
  FETCH_ORGANIZATIONS_FAILED: 'Unable to fetch organizations',
  NO_SCHOOLS_FOUND: 'No Schools',
  EMAIL_SETUP_PROMPT: 'Enter your email to set up',
  EMAIL_ALREADY_SETUP: 'Your email is already set up',
  PHONE_SETUP_PROMPT: 'Enter your phone number to set up',
  PHONE_ALREADY_SETUP: 'Your phone number is already set up',
  VERIFICATION_CODE_PROMPT: "We'll send you a verification code to confirm your",
  CURRENT_PASSWORD_LABEL: 'Current Password *',
  VERIFICATION_CODE_LABEL: 'Verification Code *',
  ENTER_CODE_PROMPT: 'Enter the Code',
  MFA_SETUP: 'Multi-Factor Authentication Setup',
  TWO_FA_OPTIONS: 'TWO_FA_OPTIONS',
};
