import React, { useState } from 'react';
import { Box, Text, Layer, Button } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import Divider from 'granite-admin/core/components/Divider';
// import { getMasterPreferences } from 'granite-admin/accounts/controllers/user.js';

import SelectFAType from './components/SelectFAType';
import SelectFADetails from './components/SelectFADetails';
import SuccessScreen from './components/SuccessScreen';
import { checkDuplicatePhone } from 'accounts/controllers/user';
// import tokenAPI from 'accounts/gateways/user_api'
import useCase from 'common/TwoFALayer/hooks/useCase';
import { Form, StyledClose } from 'common/TwoFALayer/styledComponents/styles';
import { STRINGS } from 'common/TwoFALayer/strings';

const textWarningColor = '#ff0000';

const TwoFALayer = ({
  setShow2FALayer,
  userProfile,
  fetchUserProfile,
  dispatch,
  getSavedThemeConfigs,
  defaultThemeConfigs,
}) => {
  const [phoneNotExist, setPhoneNotExist] = useState(false);

  const {
    formikRef,
    preferences,
    steps,
    changePhoneLayer,
    otpSent,
    duplicatePhone,
    setChangePhoneLayer,
    setOtpSent,
    updateFAOptionType,
    updatePhoneChange,
    verifyPhoneOTPChange,
    handleCancel,
    onSubmit,
    validationSchema,
    eventEmitter,
  } = useCase({
    dispatch,
    getSavedThemeConfigs,
    defaultThemeConfigs,
    fetchUserProfile,
    setShow2FALayer,
  });

  return (
    <Layer position="center" onEsc={handleCancel}>
      <Formik
        initialValues={{
          type: preferences?.find(p => p.name === 'TWO_FA_OPTIONS')?.value?.toLowerCase() || 'email',
          email: userProfile?.email || '',
          phone: userProfile?.phone?.number ? userProfile?.phone : { dialCode: '61', countryCode: 'au' },
          isPhoneVerified: userProfile?.isPhoneVerified,
          isEmailVerified: userProfile?.isEmailVerified,
          twoFA: userProfile?.twoFA,
          token: '',
          password: '',
          otp: '',
          verifyingUpdatedPhone: false,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={formikRef?.current ? validationSchema : {}}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          submitForm,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          initialValues,
          isValid,
          isSubmitting,
          validateForm,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Box flex={{ grow: 1, shrink: 0 }} width="500px" height={{ min: '30vh' }}>
              <>
                <Box pad={{ vertical: 'small', horizontal: 'medium' }} direction="row" justify="between" align="center">
                  <Text weight="bold" size="xlarge">
                    {STRINGS.MFA_SETUP}
                  </Text>

                  <Box align="end" pad={{ top: 'xsmall' }}>
                    <StyledClose onClick={() => setShow2FALayer(false)} size="20px" />
                  </Box>
                </Box>

                <Divider />
              </>
              <Box flex pad={{ vertical: 'small', horizontal: 'medium' }}>
                {steps === 0 ? (
                  <SelectFAType
                    values={values}
                    setFieldValue={setFieldValue}
                    setOtpSent={setOtpSent}
                    setPhoneNotExist={setPhoneNotExist}
                    phoneNotExist={phoneNotExist}
                    textWarningColor={textWarningColor}
                  />
                ) : steps === 1 ? (
                  <SelectFADetails
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    otpSent={otpSent}
                    initialValues={initialValues}
                    changePhoneLayer={changePhoneLayer}
                    setChangePhoneLayer={setChangePhoneLayer}
                    updatePhoneChange={updatePhoneChange}
                    steps={steps}
                    validateForm={validateForm}
                    submitForm={submitForm}
                    isSubmitting={isSubmitting}
                    duplicatePhone={duplicatePhone}
                    textWarningColor={textWarningColor}
                  />
                ) : steps === 2 ? (
                  <SuccessScreen />
                ) : (
                  <></>
                )}
              </Box>
              <Box as="footer" gap="small" pad="small">
                <Divider />
                <Box fill="horizontal" justify="between" direction="row">
                  {steps !== 2 ? (
                    <Button label={steps === 1 ? 'Back' : 'Cancel'} primary onClick={handleCancel} />
                  ) : (
                    <Box></Box>
                  )}

                  <Button
                    label={
                      steps === 2
                        ? 'Continue'
                        : steps === 1 && (otpSent || (values?.type === 'sms' && changePhoneLayer === 'OTP'))
                        ? 'Verify Code'
                        : 'Next'
                    }
                    primary
                    onClick={() => {
                      const { type, phone } = values;
                      if (steps === 0) {
                        if (type === 'sms') {
                          if (!phone?.number) {
                            setPhoneNotExist(true);
                          } else {
                            checkDuplicatePhone(eventEmitter, phone?.phone);
                          }
                        } else if (type === 'email') {
                          updateFAOptionType(values);
                        }
                      } else if (steps === 1) {
                        if (changePhoneLayer === 'OTP') {
                          verifyPhoneOTPChange(values);
                        } else {
                          submitForm();
                        }
                      } else if (steps === 2) {
                        setShow2FALayer(false);
                      }
                    }}
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      phoneNotExist ||
                      (steps === 0 && values?.type !== 'sms' && values?.type !== 'email') ||
                      (steps === 1 && changePhoneLayer === 'SMS') ||
                      (steps === 1 &&
                        !otpSent &&
                        !values?.verifyingUpdatedPhone &&
                        values?.type === 'sms' &&
                        !values?.isPhoneVerified) ||
                      (steps === 1 && !otpSent && values?.type === 'email' && !values?.isEmailVerified)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Layer>
  );
};

TwoFALayer.propTypes = {
  setShow2FALayer: PropTypes.func,
  userProfile: PropTypes.object,
  fetchUserProfile: PropTypes.func,
  dispatch: PropTypes.func,
  getSavedThemeConfigs: PropTypes.func,
  defaultThemeConfigs: PropTypes.object,
};

export default TwoFALayer;
