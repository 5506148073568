// Lib Imports
import React from 'react';
import { Anchor, Box, TextInput, Button, Text } from 'grommet';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CORE Imports
import FormField from 'granite-admin/core/components/FormField';
import PhoneInput from 'granite-admin/core/components/PhoneInput/v2';
import Select from 'granite-admin/core/components/Select';
import { getValidationSchema } from 'pages/forgotpassword/helpers/constants';
import { STRINGS, STRING_EVENTS, STRING_URLS, allowAltRecovery, primaryContact } from 'pages/forgotpassword/strings';

// const primaryContact = process.env.REACT_APP_PRIMARY_CONTACT || STRING_EVENTS.EMAIL;
// const allowAltRecovery = String(process.env.REACT_APP_ALLOW_ALTERNATE_RECOVERY) || 'false';

const RequestTokenForm = ({ onSubmit, query, organisation, setOrganisation, brandColor }) => {
  const { email = '' } = query ?? {};

  return (
    <Formik
      initialValues={{
        email,
        organisation_id: '',
      }}
      validationSchema={getValidationSchema(organisation)}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
        <form onSubmit={handleSubmit}>
          {primaryContact === STRING_EVENTS.EMAIL && allowAltRecovery === 'false' ? (
            <FormField name="email" label={STRINGS.EMAIL_LABEL} required error={touched.email && errors.email}>
              <TextInput
                type="email"
                name="email"
                placeholder={STRINGS.EMAIL_PLACEHOLDER} // Replaced placeholder
                onChange={e => {
                  handleChange(e);
                  setOrganisation([]);
                  setFieldValue('organisation_id', '');
                }}
                onBlur={handleBlur}
                value={values.email}
              />
            </FormField>
          ) : (primaryContact === STRING_EVENTS.EMAIL || primaryContact === STRING_EVENTS.PHONE) &&
            allowAltRecovery === 'true' ? (
            <Box>
              <FormField
                name="email"
                label={STRINGS.EMAIL_ADDRESS_LABEL}
                required
                error={touched.email && errors.email}
              >
                <TextInput
                  type="email"
                  name="email"
                  placeholder={STRINGS.EMAIL_PLACEHOLDER} // Replaced placeholder
                  onChange={e => {
                    handleChange(e);
                    setOrganisation([]);
                    setFieldValue('organisation_id', '');
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormField>
              <Box flex justify="end" align="center">
                <Text size="medium">{STRINGS.OR_TEXT}</Text>
              </Box>
              <FormField name="phone" label={STRINGS.PHONE_LABEL} error={touched.phone && errors.phone}>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                  }}
                  onChange={phone => {
                    setFieldValue('phone', { ...phone });
                    setOrganisation([]);
                    setFieldValue('organisation_id', '');
                  }}
                  onBlur={handleBlur}
                  value={values.phone}
                  alwaysDefaultMask
                />
              </FormField>
            </Box>
          ) : primaryContact === STRING_EVENTS.PHONE && allowAltRecovery === 'false' ? (
            <FormField
              name="phone"
              label={STRINGS.PHONE_LABEL}
              error={touched.phone && errors.phone && errors.phone.number}
            >
              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                onChange={phone => {
                  setFieldValue('phone', { ...phone });
                  setOrganisation([]);
                  setFieldValue('organisation_id', '');
                }}
                onBlur={handleBlur}
                value={values.phone}
                alwaysDefaultMask
              />
            </FormField>
          ) : null}
          {organisation?.length > 0 && (
            <FormField
              name="organisation_id"
              label={STRINGS.ORGANIZATION_LABEL} // Replaced label
              error={touched.organisation_id && errors.organisation_id}
            >
              <Select
                name="organisation_id"
                options={organisation}
                labelKey="name"
                value={values.organisation_id}
                valueKey={{ key: 'pk', reduce: true }}
                onChange={({ value }) => {
                  setFieldValue('organisation_id', value);
                }}
              />
            </FormField>
          )}
          <Box
            fill="horizontal"
            direction="row-responsive"
            elevation="none"
            justify="between"
            align="center"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{ top: 'small' }}
              label={isSubmitting ? STRINGS.SENDING_LINK : STRINGS.SEND} // Replaced button labels
              disabled={isSubmitting || !isValid}
              primary
              color={brandColor || 'secondary'}
            />
            <Link
              to={
                window?.location?.pathname?.includes(STRING_URLS.PARENT_HOME)
                  ? STRING_URLS.PARENT_LOGIN
                  : STRING_URLS.LOGIN
              }
            >
              <Anchor
                as="span"
                label={STRINGS.EXISTING_ACCOUNT_LOGIN} // Replaced label for login anchor
                size="small"
                margin={{ vertical: 'small' }}
                color={brandColor || 'secondary'}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  );
};

RequestTokenForm.propTypes = {
  onSubmit: PropTypes.func,
  query: PropTypes.any,
  organisation: PropTypes.object,
  setOrganisation: PropTypes.any,
  brandColor: PropTypes.any,
};

export default RequestTokenForm;
