// Application Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';

import URLS from 'rollover/gateways/endpoints.js';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const konnectPreviewUrl = env.REACT_APP_KONNECT_BULK_UPLOAD_BASE_URL;
const token = env.REACT_APP_BEARER_TOKEN_KONNECT;
const rolloverUrl = env.REACT_APP_ROLLOVER_BASE_URL;

/**
 * Fetches rollover data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request. Defaults to `undefined` if not provided.
 * @returns {Promise<Object>} - A promise that resolves to the rollover data from the API.
 */
async function getRollOver(params) {
  return (await GraniteAPIClient(url).get(URLS.ROLLOVER, params ? { params } : undefined))?.data;
}

/**
 * Submits rollover data to the API. Uses PATCH if a primary key (`pk`) is provided; otherwise, uses POST.
 *
 * @param {Object} param - The rollover data to submit. If a `pk` is provided, the data will be updated via PATCH.
 * @param {number} [param.pk] - The primary key of the rollover data to update (for PATCH).
 * @returns {Promise<Object>} - A promise that resolves to the updated or newly created rollover data.
 */
async function postRollOver(param) {
  const { pk, ...payload } = param;
  const method = pk ? 'patch' : 'post';
  const endpoint = pk ? `${URLS.ROLLOVER}${pk}/` : URLS.ROLLOVER;
  return (await GraniteAPIClient(url)[method](endpoint, payload))?.data;
}

/**
 * Fetches a list of route groups from the API.
 *
 * @param {Object} [params] - Optional query parameters to filter the route groups.
 * @returns {Promise<Object>} - A promise that resolves to the route group list data.
 */
async function getRouteGroupList(params) {
  return (await GraniteAPIClient(url).get(URLS.ROUTE, { params }))?.data;
}

/**
 * Fetches route event data from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A promise that resolves to the route event data.
 */
async function getRouteEvent(params) {
  return (await GraniteAPIClient(url).get(URLS.ROUTE_EVENT, params))?.data;
}

/**
 * Starts the rollover process for an organization.
 *
 * @param {Object} payload - The rollover payload data.
 * @param {number} org - The organization ID.
 * @returns {Promise<Object>} - A promise that resolves when the rollover process is started.
 */
async function startRollover(payload, org) {
  const headers = { organisation: org };
  return (await GraniteAPIClient(rolloverUrl, headers).post('', payload))?.data;
}

/**
 * Fetches student data for the rollover process from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A promise that resolves to the student data.
 */
async function getRolloverStudentData(params) {
  return (await GraniteAPIClient(url).get(URLS.STUDENT_DATA, params ? { params } : undefined))?.data;
}

/**
 * Fetches the active plugin data from the API.
 *
 * @returns {Promise<Object>} - A promise that resolves to the active plugin data.
 */
async function getActivePlugin() {
  return (await GraniteAPIClient(url).get(URLS.PLUGIN_ACTIVE))?.data;
}

/**
 * Previews student data before performing a bulk upload.
 *
 * @param {Object} payload - The student data to preview.
 * @returns {Promise<Object>} - A promise that resolves to the preview data.
 */
async function previewStudents(payload) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return (await GraniteAPIClient(konnectPreviewUrl, headers).post(URLS.PREVIEW_URL, payload))?.data;
}

/**
 * Fetches CSV statistics from the API.
 *
 * @param {Object} [params] - Optional query parameters to include in the request.
 * @returns {Promise<Object>} - A promise that resolves to the CSV statistics data.
 */
async function CsvStats(params) {
  return (await GraniteAPIClient(url).get(URLS.CSV_STATS, params ? { params } : undefined))?.data;
}

export default {
  getRollOver,
  postRollOver,
  getRouteGroupList,
  getRouteEvent,
  startRollover,
  getRolloverStudentData,
  getActivePlugin,
  previewStudents,
  CsvStats,
};
