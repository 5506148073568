// Application Imports
import routeMessageAPI from 'routeMessage/gateways/routeMessage-api';
import { ROUTE_MESSAGE_EVENTS } from 'routeMessage/controllers/events';
import {
  RouteMessageLogsEntity,
  RouteEventsEntity,
  mapToSnakeCaseForMessageAllRoutes,
} from 'routeMessage/entities/routeMessage';
import { handleApiCall } from 'utils/handleApiCallController';

/**
 * Fetch Route Message Logs
 *
 * @param {object} eventEmitter - The event emitter for emitting success and failure events
 * @param {object} params - The object with date and event group
 *
 * @returns {Promise<void>} - The promise resolves when data is fetched or fails
 */
async function fetchRouteMessageLogs(eventEmitter, params) {
  return await handleApiCall(
    async () => {
      const payload = { params: { ...params, page_size: 100000 } };
      const { data } = await routeMessageAPI.getRouteMessageLogs(payload);
      let res = [];
      data.results.map(item => {
        let { data } = new RouteMessageLogsEntity(item);
        res = [...res, ...data];
      });
      eventEmitter.emit(ROUTE_MESSAGE_EVENTS.FETCH_LOGS_SUCCESS, res);
    },
    eventEmitter,
    ROUTE_MESSAGE_EVENTS.FETCH_LOGS_FAILURE,
  );
}

/**
 * Fetch route Message and route Mapping data for a given date and group
 *
 * @param {object} eventEmitter - The event emitter for emitting success and failure events
 * @param {string} currentDate - The date for which transport data is to be fetched
 * @param {number} currentGroup - The group ID for which transport data is to be fetched
 *
 * @returns {Promise<void>} - The promise resolves when data is fetched or fails
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */
async function fetchTransportData(eventEmitter, currentDate, currentGroup) {
  let str = JSON.stringify([currentDate]);
  let params = {
    route_date: str,
    route_group_id: currentGroup,
    ordering: 'route__name',
    page_size: 100000,
  };
  return await handleApiCall(
    async () => {
      const { data } = await routeMessageAPI.getTransportData({ params });
      const transportData = data ? data?.results?.map(item => new RouteEventsEntity(item)) : [];
      eventEmitter.emit(ROUTE_MESSAGE_EVENTS.FETCH_ROUTE_SUCCESS, transportData);
    },
    eventEmitter,
    ROUTE_MESSAGE_EVENTS.FETCH_ROUTE_FAILURE,
  );
}

/**
 * Fetch route SMS balance for a given organisation
 *
 * @param {object} values - The object with eventEmitter and organisationId
 * @param {object} eventEmitter - The event emitter for emitting success and failure events
 * @param {number} organisationId - The organisation ID for which SMS balance is to be fetched
 *
 * @returns {Promise<void>} - The promise resolves when data is fetched or fails
 * @returns {eventEmitter} - Emits a failure event if failed to fetch
 */
async function getRouteSmsBalance(values) {
  const { eventEmitter, organisationId } = values;
  return await handleApiCall(
    async () => {
      const payload = { on_hold: 0, sms_balance: 0 };
      const { data } = await routeMessageAPI.getRouteSmsBalance(payload, organisationId);
      eventEmitter.emit(ROUTE_MESSAGE_EVENTS.FETCH_SUCCESS_ROUTE_SMS_BALANCE, data);
    },
    eventEmitter,
    ROUTE_MESSAGE_EVENTS.FETCH_FAILURE_ROUTE_SMS_BALANCE,
  );
}

/**
 * Post message to all routes for a given organisation
 *
 * @param {object} values - The object with eventEmitter, organisationId, route_event_id, message_type,
 *                           message, selectedBoardedChildren, selectedEventId, selectedMsgType, smsBalanceCount
 * @param {object} eventEmitter - The event emitter for emitting success and failure events
 * @param {number} organisationId - The organisation ID for which message is to be posted
 * @param {number} route_event_id - The route event id for which message is to be posted
 * @param {string} message_type - The type of message to be posted
 * @param {string} message - The message body
 * @param {string} selectedBoardedChildren - The selection of children for whom message is to be posted
 * @param {number} selectedEventId - The event id for which message is to be posted
 * @param {string} selectedMsgType - The type of message to be posted (SMS or EMAIL)
 * @param {number} smsBalanceCount - The remaining SMS balance count
 *
 * @returns {Promise<void>} - The promise resolves when message is posted or fails
 * @returns {eventEmitter} - Emits a failure event if failed to post message
 */
async function messageAllRoutes(values) {
  const { eventEmitter, ...rest } = values;
  return await handleApiCall(
    async () => {
      const payload = mapToSnakeCaseForMessageAllRoutes(rest);
      const { data } = await routeMessageAPI.postMessageAllRoutes(payload);
      eventEmitter.emit(ROUTE_MESSAGE_EVENTS.FETCH_SUCCESS_RECIPENTS_COUNT, data);
    },
    eventEmitter,
    ROUTE_MESSAGE_EVENTS.FETCH_FAILURE_RECIPENTS_COUNT,
  );
}

export { fetchRouteMessageLogs, fetchTransportData, getRouteSmsBalance, messageAllRoutes };
