class RouteMessageLogsEntity {
  constructor(data) {
    this.data = data?.route?.map(i => ({
      messageDatetime: data?.message_datetime,
      message: data?.message,
      sentBy: data?.sent_by,
      route: i?.name,
      routeId: i?.pk,
      recipients: data?.recipients || [],
      affectedStudents: data?.affected_students || 0,
      sendOptions: data?.send_options,
      routeName: i?.route?.name,
      busDate: data?.bus_date,
    }));
  }
}

class RouteEventsEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.date = data?.route_date;
    this.route_name = data?.route_name;
    this.eventId = data?.route?.pk;
    this.eventColor = data?.route?.route_color;
    this.eventName = data?.route?.name;
    this.groupName = data?.route?.route_group?.group_name;
    this.groupId = data?.route?.route_group?.pk;
    this.routeType = data?.route?.route_type;
    this.schoolCheck = data?.school_check;
    this.supervisorCheck = data?.supervisor_check;
    this.busCheck = data?.transport_medium_check;
    this.supervisor_check_datetime = data?.supervisor_check_datetime;
    this.supervisor_check_off_datetime = data?.supervisor_check_off_datetime;
    this.transport_medium_check_datetime = data?.transport_medium_check_datetime;
    this.school_check_datetime = data?.school_check_datetime;
    this.school_check_off_datetime = data?.school_check_off_datetime;
    this.driver_login_datetime = data?.driver_login_datetime;
    this.teacher = data?.teacher;
    this.driver = data?.driver;
    this.supervisor = data?.supervisor;
  }
}

const mapToSnakeCaseForMessageAllRoutes = data => {
  const { confirmData, selectedEventId, ...rest } = data;
  return {
    route_event_id: selectedEventId,
    message: confirmData?.message,
    children_who_have_boarded: confirmData?.children_who_have_boarded,
    route_message_option: confirmData?.message_type,
    ...rest,
  };
};

export { RouteMessageLogsEntity, RouteEventsEntity, mapToSnakeCaseForMessageAllRoutes };
