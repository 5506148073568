import React from 'react';
import { Box, Accordion, AccordionPanel, Text } from 'grommet';

const Routes = ({ routes, setMarkers, stopClicked, setStopColor, setBG, stopColor, bGColor, colorRef }) => {
  return routes?.length
    ? routes.map(route => (
        <Box key={route.pk} gap="xsmall" pad="xsmall" flex={{ shrink: '0' }}>
          <Accordion multiple="false" style={{ borderRadius: '7px', position: 'relative' }}>
            <Box width="10px" height="45px" style={{ position: 'absolute' }} background={route.routeColor}></Box>
            <AccordionPanel
              border="1px solid"
              background={bGColor === route.pk ? route.routeColor : 'white'}
              onClick={() => {
                setBG(route.pk);
                setMarkers(route.stop);
                colorRef.current = false;
              }}
              label={
                <Box
                  alignSelf="center"
                  margin={{ left: 'medium', top: 'small' }}
                  height="35px"
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {route.name}
                </Box>
              }
            >
              <Box background="white" gap="small" pad="small" round="xsmall" direction="column">
                {route?.stop
                  ?.sort((a, b) => a?.sequence - b?.sequence)
                  ?.map(stop => (
                    <Box
                      border="1px solid"
                      pad="small"
                      key={stop.pk}
                      onClick={() => {
                        stopClicked(route.pk, stop.pk);
                        setStopColor(stop.pk);
                      }}
                      background={stopColor === stop.pk ? route.routeColor : 'light-2'}
                      style={{ opacity: stopColor === stop.pk ? 0.6 : 1, borderRadius: '4px' }}
                    >
                      <Text>
                        {stop.name +
                          (route?.displayStopTimes && stop.expectedArrivalTime ? ' - ' + stop.expectedArrivalTime : '')}
                      </Text>
                      <Text fontSize="bold">{stop.address}</Text>
                    </Box>
                  ))}
              </Box>
            </AccordionPanel>
          </Accordion>
        </Box>
      ))
    : null;
};

export default Routes;
